import { InvoiceItem } from "@/types";

export const feesFilter = (item: InvoiceItem) => item.unit_price > 0;
export const salesFilter = (item: InvoiceItem) => item.unit_price < 0;
export const calculateTotalAmountFromInvoiceItemsWithVAT = (
  invoiceItems: InvoiceItem[],
  filter?: (item: InvoiceItem) => boolean | undefined
): number => {
  return invoiceItems.reduce((total, item) => {
    if (filter && !filter(item)) {
      return total;
    }
    const itemTotal = item.amount * -item.unit_price;
    const vatMultiplier = item.vat_fraction ? 1 + item.vat_fraction : 1;
    // Round to two decimal places each cycle, because the fee PDFs are
    // generated with two decimal places. This makes the resulting numbers match
    // the PDFs exactly.
    const rounded = Math.round(itemTotal * vatMultiplier * 100) / 100;
    return total + rounded;
  }, 0);
};

export const calculateTotalAmountFromInvoiceItems = (
  invoiceItems: InvoiceItem[],
  filter?: (item: InvoiceItem) => boolean | undefined
): number => {
  return invoiceItems.reduce((total, item) => {
    if (filter && !filter(item)) {
      return total;
    }
    const itemTotal = item.amount * -item.unit_price;
    // Round to two decimal places each cycle, because the fee PDFs are
    // generated with two decimal places. This makes the resulting numbers match
    // the PDFs exactly.
    const rounded = Math.round(itemTotal * 100) / 100;
    return total + rounded;
  }, 0);
};
