import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cb876fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart-wrapper" }
const _hoisted_2 = ["height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("canvas", {
      ref: "chartRef",
      height: _ctx.height
    }, null, 8, _hoisted_2)
  ]))
}