import { goSolidApi } from "@/composables/useGoSolidApi";
import { Account } from "@/types";
import { inject } from "vue";

export function accountUpdater(
  accountData: Account,
  handleUpdateAccountData: (updatedAccountData: Account) => void
) {
  const globalErrorHandler = inject("globalErrorHandler") as (
    error: any
  ) => void;

  const postAccountData = async (
    url: string,
    onSuccess?: (
      accountData: Account,
      company_id: number,
      response?: any
    ) => void,
    companyID?: number
  ) => {
    if (accountData === null) {
      return;
    }

    const companiesToUpdate = selectCompaniesToUpdate(companyID);

    try {
      const response = await goSolidApi.post(url, {
        accountData: { ...accountData, companies: companiesToUpdate },
        company_id: companyID,
      });
      if (response.status === 200) {
        handleUpdateAccountData(response.data.accountData);
        if (onSuccess) {
          // onSuccess functions have reroutings to other pages, which might need await
          await onSuccess(
            response.data.accountData,
            response.data.company_id,
            response
          );
        }
      } else {
        throw new Error("Error occurred while saving the data.");
      }
    } catch (error: any) {
      if (error.response) {
        globalErrorHandler(error.response.data);
      }

      throw error;
    }
  };

  function selectCompaniesToUpdate(companyID?: number) {
    let relevantCompanies = accountData.companies;

    if (companyID !== undefined) {
      const specificCompany = accountData.companies.find(
        (c) => c.id === companyID
      );

      if (specificCompany) {
        relevantCompanies = [specificCompany];
      } else {
        const missingIDCompany = accountData.companies.find((c) => !c.id);
        if (missingIDCompany) {
          relevantCompanies = [missingIDCompany];
        } else {
          throw new Error(
            "Specified company ID does not exist and no placeholder company available."
          );
        }
      }
    }
    return relevantCompanies;
  }
  return { postAccountData };
}
