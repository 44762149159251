interface HelpCrunchUser {
  email: any;
  name: any;
  user_id: any;
  phone: any;
  locale: string;
  company: any;
}

export function useHelpCrunch() {
  const setHelpCrunchUser = (user: HelpCrunchUser) => {
    if (window.HelpCrunch) {
      window.HelpCrunch("updateUser", user);
    }
  };

  const openHelpCrunch = () => {
    if (window.HelpCrunch) {
      window.HelpCrunch("openChat");
    }
  };

  const closeHelpCrunch = () => {
    if (window.HelpCrunch) {
      window.HelpCrunch("closeChat");
    }
  };

  return {
    setHelpCrunchUser,
    openHelpCrunch,
    closeHelpCrunch,
  };
}
