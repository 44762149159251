<!-- client/src/components/dashboard/SetPriceTermsComponent.vue -->

<template>
  <div class="price-terms">
    <h4 class="fs-7 fw-bold mb-3">
      {{ $t("setPriceTerms.modifyStrategyTitle") }}
    </h4>
    <form @submit.prevent="setPrice" class="mt-3">
      <label class="form-label fs-8 p-0 text-body mb-2">
        {{ $t("setPriceTerms.selectStrategyLabel") }}
      </label>
      <div class="mb-4">
        <div class="form-check mb-2">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            :value="false"
            v-model="hasMinimumPrice"
          />
          <label class="form-check-label fs-8" for="flexRadioDefault1">
            {{ $t("setPriceTerms.automaticHighBid") }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault2"
            :value="true"
            v-model="hasMinimumPrice"
          />
          <label class="form-check-label fs-8" for="flexRadioDefault2">
            {{ $t("setPriceTerms.freshProdAboveMinPrice") }}
          </label>
        </div>
      </div>

      <div class="row mb-4" v-if="hasMinimumPrice">
        <div class="mb-2 mb-md-0 col-12 col-md-6">
          <label for="minimumPrice" class="form-label fs-8 p-0 text-body">
            {{ $t("setPriceTerms.minimumPrice6Months") }}
          </label>
          <div class="input-group">
            <input
              type="number"
              class="form-control"
              id="minimumPrice"
              v-model.number="minimumPrice"
              placeholder="{{ $t('setPriceTerms.enterPrice') }}"
              min="0"
              step="0.01"
              required
            />
            <span class="input-group-text"> € / MWh </span>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <label for="minimumPrice" class="form-label fs-8 p-0 text-body">
            {{ $t("setPriceTerms.last6MonthsStrategy") }}
          </label>
          <input
            type="text"
            class="form-control"
            :value="$t('setPriceTerms.automaticHighBid')"
            disabled
            readonly
          />
        </div>
      </div>
      <!-- Disabled if price has not changed or if minimumPrice is None (not 0)-->
      <button
        type="submit"
        class="btn btn-primary w-100 mb-3"
        :disabled="
          initialPrice === minimumPrice || (!minimumPrice && minimumPrice !== 0)
        "
      >
        {{ $t("setPriceTerms.saveChanges") }}
      </button>
    </form>
    <BasicInfoCardComponent
      :buttonText="$t('setPriceTerms.readMore')"
      :titleText="$t('setPriceTerms.howMinimumPriceWorks')"
    >
      <p class="mt-2 mb-1">
        {{ $t("setPriceTerms.contentParagraph1") }}
      </p>
      <p class="mt-2 mb-1">
        {{ $t("setPriceTerms.contentParagraph2") }}
      </p>
      <p class="mt-2 mb-1">
        {{ $t("setPriceTerms.contentParagraph3") }}
      </p>
    </BasicInfoCardComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, toRefs } from "vue";
import BasicInfoCardComponent from "@/components/common/BasicInfoCardComponent.vue";

export default defineComponent({
  name: "SetPriceTermsComponent",
  components: {
    BasicInfoCardComponent,
  },
  props: {
    initialPrice: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update-min-price"],
  setup(props, { emit }) {
    const { initialPrice } = toRefs(props);
    const minimumPrice = ref(initialPrice.value);
    const hasMinimumPrice = ref(initialPrice.value === 0 ? false : true);

    const setPrice = () => {
      emit("update-min-price", minimumPrice.value);
    };

    // Watch for external changes to initialPrice
    watch(initialPrice, (newValue) => {
      minimumPrice.value = newValue;
    });

    watch(hasMinimumPrice, (newSetting) => {
      if (!newSetting) {
        minimumPrice.value = 0;
      } else {
        minimumPrice.value = initialPrice.value;
      }
    });

    return {
      minimumPrice,
      setPrice,
      hasMinimumPrice,
    };
  },
});
</script>

<style scoped>
form {
  .form-label {
    text-transform: none;
  }
}
</style>
