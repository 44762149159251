// client/src/logger.ts

import { Logtail } from "@logtail/browser";

const sourceToken = "buJvoxwm6AWsfpde5UXqBc9f";

export const logtail = new Logtail(sourceToken);

interface Logger {
  info: (message: string, ...args: any[]) => void;
  warn: (message: string, ...args: any[]) => void;
  error: (message: string, ...args: any[]) => void;
}

const logger: Logger = {
  info: (message: string, ...args: any[]) => {
    console.log(message, ...args);
    if (process.env.NODE_ENV === "production") {
      logtail.info(message, { args });
    }
  },

  warn: (message: string, ...args: any[]) => {
    console.warn(message, ...args);
    if (process.env.NODE_ENV === "production") {
      logtail.warn(message, { args });
    }
  },

  error: (message: string, ...args: any[]) => {
    console.error(message, ...args);
    if (process.env.NODE_ENV === "production") {
      logtail.error(message, { args });
    }
  },
};

export default logger;
