<template>
  <InfoCardComponent :buttonText="buttonText" @setForceToggle="setForceToggle">
    <template #title>
      <div class="avatar avatar-x1 me-2">
        <font-awesome-icon :icon="icon" class="text-secondary-emphasis" />
      </div>
      <div class="flex-1">{{ titleText }}</div>
    </template>
    <template #content>
      <slot></slot>
    </template>
  </InfoCardComponent>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InfoCardComponent from "./InfoCardComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "BasicInfoCardComponent",
  components: {
    InfoCardComponent,
    FontAwesomeIcon,
  },
  props: {
    icon: {
      type: String,
      default: "circle-info",
    },
    titleText: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const setForceToggle = (method: () => void) => {
      emit("setForceToggle", method);
    };
    return { setForceToggle };
  },
});
</script>
