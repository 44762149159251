import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoice-history-component" }
const _hoisted_2 = { class: "fs-7 fw-bold mb-3" }
const _hoisted_3 = { class: "d-flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlaceholderCardComponent = _resolveComponent("PlaceholderCardComponent")!
  const _component_InvoiceDisplayComponent = _resolveComponent("InvoiceDisplayComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("invoiceHistory.title")), 1),
    (_ctx.invoices.length === 0)
      ? (_openBlock(), _createBlock(_component_PlaceholderCardComponent, {
          key: 0,
          title: _ctx.$t('invoiceHistory.noInvoices'),
          subtitle: _ctx.$t('invoiceHistory.notificationInfo')
        }, null, 8, ["title", "subtitle"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices, (invoice) => {
        return (_openBlock(), _createBlock(_component_InvoiceDisplayComponent, {
          key: invoice.id,
          invoice: invoice,
          is_admin: _ctx.is_admin
        }, null, 8, ["invoice", "is_admin"]))
      }), 128))
    ])
  ]))
}