<!-- client/src/components/common/RegistryDevicesCardComponent.vue -->

<template>
  <div v-if="registryDevices.length > 0">
    <div v-for="registryDevice in registryDevices" :key="registryDevice.id">
      <div class="card rounded-0 border-start-0 border-end-0">
        <div class="card-body">
          <div>
            <h2 class="accordion-header" :id="'heading' + registryDevice.id">
              <a
                href="#"
                class="collapsed d-flex flex-row align-items-center"
                type="accordion-button button"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + registryDevice.id"
                aria-expanded="false"
                :aria-controls="'collapse' + registryDevice.id"
              >
                <div class="me-3 text-secondary-emphasis">
                  <TechnologyIcon :technology="registryDevice.technology" />
                </div>
                <div>
                  <div class="card-title mb-1 fs-7 fw-bold">
                    {{ registryDevice.device_name }}
                  </div>
                  <h6 class="text-body-tertiary">
                    <font-awesome-icon icon="square-bolt" />
                    {{ registryDevice.device_power }} kW
                    <font-awesome-icon icon="hashtag" class="ms-1" />
                    {{ registryDevice.device_eic }}
                  </h6>
                </div>
              </a>
            </h2>
            <div
              :id="'collapse' + registryDevice.id"
              class="accordion-collapse collapse"
              :aria-labelledby="'heading' + registryDevice.id"
            >
              <div class="card-text">
                <div class="accordion border rounded p-2">
                  <div class="accordion-item">
                    <div class="accordion-body">
                      <div class="registryDevice-segment">
                        <div class="registryDevice-property">
                          {{ $t("registry_device.device_code") }}:
                        </div>
                        <div class="registryDevice-value">
                          {{ registryDevice.device_eic }}
                        </div>
                      </div>
                      <div class="registryDevice-segment">
                        <div class="registryDevice-property">
                          {{ $t("registry_device.device_power") }}:
                        </div>
                        <div class="registryDevice-value">
                          {{ registryDevice.device_power }}
                        </div>
                      </div>
                      <div class="registryDevice-segment">
                        <div class="registryDevice-property">
                          {{ $t("registry_device.technology_code") }}:
                        </div>
                        <div class="registryDevice-value">
                          {{ registryDevice.technology_code }}
                        </div>
                      </div>
                      <div class="registryDevice-segment">
                        <div class="registryDevice-property">
                          {{ $t("registry_device.supported_device") }}?
                        </div>
                        <div class="registryDevice-value">
                          {{
                            registryDevice.support
                              ? $t("common.yes")
                              : $t("common.no")
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { RegistryDevice } from "../../types";
import TechnologyIcon from "./TechnologyIcon.vue";

export default defineComponent({
  name: "RegistryDevicesCardComponent",
  components: {
    TechnologyIcon,
  },
  props: {
    company_id: {
      type: Number,
      required: true,
    },
    registryDevices: {
      type: Object as () => Array<RegistryDevice>,
      required: true,
    },
    is_admin: {
      type: Boolean,
      required: true,
    },
  },
});
</script>
