import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a9e47b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-view" }
const _hoisted_2 = { class: "container pt-3 pt-xl-5" }
const _hoisted_3 = {
  class: "card p-4 mb-3",
  novalidate: ""
}
const _hoisted_4 = { class: "mb-1 mb-sm-4" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = { class: "fw-bold" }
const _hoisted_8 = {
  for: "selectLanguage",
  class: "col-sm-12 pb-0 col-form-label"
}
const _hoisted_9 = {
  value: "",
  disabled: ""
}
const _hoisted_10 = { value: "en" }
const _hoisted_11 = { value: "et" }
const _hoisted_12 = { value: "lv" }
const _hoisted_13 = { value: "lt" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "d-flex justify-content-between w-100" }
const _hoisted_16 = { class: "btn btn-secondary me-2" }
const _hoisted_17 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOverlayComponent = _resolveComponent("LoadingOverlayComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("settings.title")), 1),
        (_ctx.isAccountDataReady)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.accountData.name), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.accountData.email), 1),
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("settings.select_language")), 1),
              _withDirectives(_createElementVNode("select", {
                class: "form-select",
                role: "button",
                id: "selectLanguage",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localLanguage) = $event))
              }, [
                _createElementVNode("option", _hoisted_9, _toDisplayString(_ctx.$t("settings.select_language")), 1),
                _createElementVNode("option", _hoisted_10, _toDisplayString(_ctx.$t("language.en")), 1),
                _createElementVNode("option", _hoisted_11, _toDisplayString(_ctx.$t("language.et")), 1),
                _createElementVNode("option", _hoisted_12, _toDisplayString(_ctx.$t("language.lv")), 1),
                _createElementVNode("option", _hoisted_13, _toDisplayString(_ctx.$t("language.lt")), 1)
              ], 512), [
                [_vModelSelect, _ctx.localLanguage]
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_LoadingOverlayComponent)
            ]))
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("button", _hoisted_16, _toDisplayString(_ctx.$t("common.back")), 1),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveAndRedirect && _ctx.saveAndRedirect(...args))),
          class: "btn btn-primary",
          disabled: _ctx.localLanguage == _ctx.accountData.language
        }, _toDisplayString(_ctx.$t("common.save")), 9, _hoisted_17)
      ])
    ])
  ]))
}