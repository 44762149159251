<!-- client/src/views/SettingsView.vue -->

<template>
  <div class="settings-view">
    <div class="container pt-3 pt-xl-5">
      <div class="card p-4 mb-3" novalidate>
        <h3 class="mb-1 mb-sm-4">
          {{ $t("settings.title") }}
        </h3>
        <div v-if="isAccountDataReady">
          <p class="mb-0">{{ accountData.name }}</p>
          <p class="fw-bold">{{ accountData.email }}</p>
          <label for="selectLanguage" class="col-sm-12 pb-0 col-form-label">
            {{ $t("settings.select_language") }}
          </label>
          <select
            class="form-select"
            role="button"
            id="selectLanguage"
            v-model="localLanguage"
          >
            <option value="" disabled>
              {{ $t("settings.select_language") }}
            </option>
            <option value="en">{{ $t("language.en") }}</option>
            <option value="et">{{ $t("language.et") }}</option>
            <option value="lv">{{ $t("language.lv") }}</option>
            <option value="lt">{{ $t("language.lt") }}</option>
          </select>
        </div>
        <div v-else>
          <LoadingOverlayComponent />
        </div>
      </div>
      <div class="d-flex justify-content-between w-100">
        <button class="btn btn-secondary me-2">
          {{ $t("common.back") }}
        </button>
        <button
          @click="saveAndRedirect"
          class="btn btn-primary"
          :disabled="localLanguage == accountData.language"
        >
          {{ $t("common.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { fetchAccount } from "@/composables/account/fetchAccount";
import { watchEffectOnceAsync } from "@/composables/watchEffect";
import { accountUpdater } from "@/composables/account/accountUpdater";
import router from "@/router";

export default defineComponent({
  components: {},
  setup() {
    const { accountData } = fetchAccount();
    const isAccountDataReady = computed(() => {
      return accountData && accountData.email; // or any other field that indicates data is loaded
    });
    const redirectToPreviousPage = () => {
      const previousRoute = router.options.history.state.back;
      if (previousRoute) {
        router.go(-1);
      } else {
        router.push("/");
      }
    };
    const { postAccountData } = accountUpdater(
      accountData,
      redirectToPreviousPage
    );

    const localLanguage = ref();

    const saveAndRedirect = async () => {
      // Save the language to the accountData object
      // Redirect to the previous page
      accountData.language = localLanguage.value;
      await postAccountData("/api/account/update");
    };

    onMounted(async () => {
      if (!isAccountDataReady.value) {
        await watchEffectOnceAsync(() => isAccountDataReady.value);
        localLanguage.value = accountData.language;
      }
    });

    return {
      localLanguage,
      accountData,
      isAccountDataReady,
      redirectToPreviousPage,
      saveAndRedirect,
    };
  },
});
</script>

<style scoped lang="scss">
.settings-view {
  overflow-y: auto;
  // Custom container styles
  .container {
    max-width: 800px;
  }
}
</style>
