<template>
  <font-awesome-icon :icon="iconType" :class="iconClass" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "TechnologyIcon",
  props: {
    technology: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    function getIconType(technology: string) {
      switch (technology) {
        case "SOLAR":
          return "solar-panel";
        case "WIND":
          return "wind-turbine";
        case "HYDRO":
          return "bridge-water";
        case "MARINE":
          return "water";
        case "BIOMASS":
          return "fire";
        case "NUCLEAR":
          return "radiation";
        default:
          return "bolt";
      }
    }
    const iconType = getIconType(props.technology);
    return { iconType };
  },
});
</script>
