import { inject, reactive } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";

export function useManageAccount() {
  const globalErrorHandler = inject("globalErrorHandler") as (
    error: any
  ) => void;
  const managingAccount = reactive({
    email: "",
    id: "",
  });

  const resetManagingAccount = async (onSuccess: any) => {
    manageAccount(0, onSuccess);
  };

  const updateManagingAccount = (onSuccess: any) => {
    if (managingAccount.id) {
      const accountId = parseInt(managingAccount.id, 10); // Convert string to a number
      if (!isNaN(accountId)) {
        manageAccount(accountId, onSuccess);
      }
    } else {
      manageAccount(0, onSuccess);
    }
  };

  const manageAccount = async (id: number, onSuccess: any) => {
    try {
      const response = await goSolidApi.get("/api/admin/accounts/manage/" + id);

      if (response.status === 200) {
        managingAccount.email = response.data?.managing_account;
        managingAccount.id = response.data?.managing_account_id;
        onSuccess(managingAccount);
      } else {
        throw new Error("Error managing account");
      }
    } catch (error: any) {
      globalErrorHandler(error.response.data);
    }
  };

  return {
    managingAccount,
    manageAccount,
    updateManagingAccount,
    resetManagingAccount,
  };
}
