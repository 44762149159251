// client/src/composables/useForwardSaleUtils.ts

import { computed, Ref } from "vue";
import { ForwardSale } from "@/types";

export function useForwardSaleUtils(forwardSales: Ref<ForwardSale[]>) {
  const getQuarterlySalesForYear = (year: number) => {
    return computed(() => {
      const yearSales = forwardSales.value.filter(
        (sale) => new Date(sale.productionStart).getFullYear() === year
      );

      const quarters = [0, 0, 0, 0];

      yearSales.forEach((sale) => {
        const startQuarter = Math.floor(
          new Date(sale.productionStart).getMonth() / 3
        );
        const endQuarter = Math.floor(
          new Date(sale.productionEnd).getMonth() / 3
        );

        for (let q = startQuarter; q <= endQuarter; q++) {
          quarters[q] += sale.committedMWh;
        }
      });

      return quarters;
    });
  };

  return {
    getQuarterlySalesForYear,
  };
}
