<!-- src/components/common/InvoiceItemDisplayComponent.vue -->

<template>
  <div class="row mb-2 invoice-item-display px-1 g-0">
    <div class="col-12 col-lg-4 px-1">
      <p class="mb-0 fw-bold cropped-until-hover">
        {{ invoiceItem.description }}
      </p>
    </div>
    <div class="col-12 col-lg-8 row g-0 text-start text-lg-end m-0">
      <div class="col-6 col-lg-3 px-1 py-1 py-lg-0">
        <p class="mb-0 d-lg-none">{{ $t("invoiceItem.quantity") }}</p>
        <p class="mb-0">{{ invoiceItem.amount }}</p>
      </div>
      <div class="col-6 col-lg-3 px-1 py-1 py-lg-0">
        <p class="mb-0 d-lg-none">{{ $t("invoiceItem.unit_price") }}</p>
        <p class="mb-0">{{ formatMoney(-invoiceItem.unit_price) }} €</p>
      </div>
      <div class="col-6 col-lg-3 px-1 py-1 py-lg-0">
        <p class="mb-0 d-lg-none">{{ $t("invoiceItem.total_excl_vat") }}</p>
        <p class="mb-0">
          {{ formatMoney(-invoiceItem.unit_price * invoiceItem.amount) }} €
        </p>
      </div>
      <div class="col-6 col-lg-3 px-1 py-1 py-lg-0">
        <p class="mb-0 d-lg-none">{{ $t("invoiceItem.vat") }}</p>
        <p class="mb-0">
          {{
            formatMoney(
              -invoiceItem.unit_price *
                invoiceItem.amount *
                (invoiceItem.vat_fraction || 0)
            )
          }}
          €
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { InvoiceItem } from "@/types";
import { formatMoney } from "@/composables/formatUtils";

export default defineComponent({
  name: "InvoiceItemDisplayComponent",
  props: {
    invoiceItem: {
      type: Object as PropType<InvoiceItem>,
      required: true,
    },
  },
  setup() {
    return {
      formatMoney,
    };
  },
});
</script>

<style scoped>
.cropped-until-hover {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.3s ease; /* Smooth transition for expanding effect */
  cursor: pointer;
}

@media (min-width: 992px) {
  .cropped-until-hover:hover {
    position: absolute; /* Make the hovered content position absolute to allow overflow */
    z-index: 10; /* Ensure it's above other content */
    background-color: var(
      --phoenix-body-highlight-bg
    ); /* Change according to your design for masking */
    overflow: visible;
    white-space: normal;
    word-break: break-word;
    width: max-content; /* Ensure width is based on content size */
  }
}
</style>
