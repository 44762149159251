<!-- client/src/views/admin/FinancesView.vue -->

<template>
  <div id="AdminFinances">
    <div class="container p-2">
      <h2 class="mb-4">Finances</h2>
      <h3 class="mb-2">Payment requested</h3>
      <div class="card mb-2 p-3">
        <div
          class="row align-items-center"
          v-for="invoice in unpaidInvoices"
          :key="invoice.id"
        >
          <div class="col-auto">
            <input
              type="checkbox"
              class="form-check-input"
              v-model="selectedInvoices"
              :value="invoice.id"
            />
          </div>
          <div class="col">
            <InvoiceDisplayComponent :invoice="invoice" :is_admin="true">
              <template #extra-columns>
                <span class="col-auto">
                  {{ companies[invoice.company_id].name }}
                </span>
                <span class="col-auto">
                  {{ companies[invoice.company_id].registry_code }}
                </span>
              </template>
            </InvoiceDisplayComponent>
          </div>
        </div>
      </div>
      <div class="mb-6">
        <span class="fw-bold"> {{ selectedInvoices.size }} selected </span>
        <btn
          class="btn btn-sm btn-subtle-primary btn-payment"
          :disabled="selectedInvoices.size === 0"
          :class="{ disabled: selectedInvoices.size === 0 }"
          @click="markInvoicesAsPaid"
        >
          <font-awesome-icon icon="check" />
          Mark as paid
        </btn>
        <btn class="btn btn-sm btn-subtle-secondary btn-payment disabled">
          <font-awesome-icon icon="file-export" />
          Export invoices
        </btn>
        <btn
          class="btn btn-sm btn-subtle-danger btn-payment"
          :disabled="selectedInvoices.size === 0"
          :class="{ disabled: selectedInvoices.size === 0 }"
          @click="markInvoicesAsCanceled"
        >
          <font-awesome-icon icon="times" />
          Cancel invoices
        </btn>
      </div>
      <h3 class="mb-2">Paid invoices</h3>
      <div class="card mb-2 p-3">
        <div
          class="row align-items-center"
          v-for="invoice in paidSalesInvoices"
          :key="invoice.id"
        >
          <div class="col">
            <InvoiceDisplayComponent :invoice="invoice" :is_admin="true">
              <template #extra-columns>
                <span class="col-auto">
                  {{ companies[invoice.company_id].name }}
                </span>
                <span class="col-auto">
                  {{ companies[invoice.company_id].registry_code }}
                </span>
              </template>
            </InvoiceDisplayComponent>
          </div>
        </div>
      </div>
      <h4 class="mb-2">
        Pending payouts (
        {{
          formatMoney(
            pendingPayouts.reduce((acc, payout) => acc + payout.totalAmount, 0)
          )
        }}
        €)
      </h4>
      <div class="card">
        <div class="card-body">
          <div
            class="mb-2"
            v-for="payout in pendingPayouts"
            :key="payout.company_id"
          >
            <h5>
              Company {{ payout.company_id }} ({{
                formatMoney(payout.totalAmount)
              }}
              €)
              <button
                @click="togglePendingCollapse(payout.company_id)"
                class="btn btn-sm btn-link me-2"
              >
                <font-awesome-icon
                  :icon="
                    isPendingCollapsed(payout.company_id) ? 'eye' : 'eye-slash'
                  "
                />
              </button>
            </h5>
            <div
              class="card p-2 pb-0"
              v-if="!isPendingCollapsed(payout.company_id)"
            >
              <InvoiceItemDisplayComponent
                v-for="item in payout.items"
                :key="item.id"
                :invoiceItem="item"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref, onMounted, computed } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { Invoice, InvoiceItem, Company } from "@/types";
import InvoiceDisplayComponent from "@/components/common/InvoiceDisplayComponent.vue";
import InvoiceItemDisplayComponent from "@/components/common/InvoiceItemDisplayComponent.vue";
import { formatMoney } from "@/composables/formatUtils";
import { calculateTotalAmountFromInvoiceItemsWithVAT } from "@/composables/money/moneyUtils";

export default defineComponent({
  name: "AdminFinancesView",
  components: {
    InvoiceDisplayComponent,
    InvoiceItemDisplayComponent,
  },
  setup() {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const invoices = ref<Invoice[]>([]);
    const companies = ref<Company[]>([]);
    const pendingInvoiceItems = ref<InvoiceItem[]>([]);
    const unpaidInvoices = computed(() =>
      invoices.value.filter(
        (invoice) => invoice.paid_at === null && invoice.requested_at !== null
      )
    );
    const paidSalesInvoices = computed(() =>
      invoices.value.filter(
        (invoice) =>
          invoice.paid_at !== null && invoice.invoice_type === "expense"
      )
    );
    const shownPendingPayouts = ref<Set<number>>(new Set());
    const selectedInvoices = ref<Set<number>>(new Set());

    const pendingPayouts = computed(() => {
      const payouts: {
        company_id: number;
        totalAmount: number;
        items: InvoiceItem[];
      }[] = [];
      // Group invoice items by company_id
      const groupedItems: Map<number, InvoiceItem[]> =
        pendingInvoiceItems.value.reduce(
          (map: Map<number, InvoiceItem[]>, item: InvoiceItem) => {
            if (!map.has(item.company_id)) {
              map.set(item.company_id, [item]);
            } else {
              map.get(item.company_id)?.push(item);
            }
            return map;
          },
          new Map()
        );
      for (let [company_id, companyItems] of groupedItems) {
        const totalAmount =
          calculateTotalAmountFromInvoiceItemsWithVAT(companyItems);
        payouts.push({
          company_id: company_id,
          totalAmount: totalAmount,
          items: companyItems,
        });
      }
      return payouts.sort((a, b) => a.totalAmount - b.totalAmount);
    });

    const togglePendingCollapse = (companyId: number) => {
      if (shownPendingPayouts.value.has(companyId)) {
        shownPendingPayouts.value.delete(companyId);
      } else {
        shownPendingPayouts.value.add(companyId);
      }
    };

    const isPendingCollapsed = (companyId: number) => {
      return !shownPendingPayouts.value.has(companyId);
    };

    const markInvoicesAsPaid = async () => {
      try {
        const response = await goSolidApi.post("/api/admin/pay-invoices", {
          invoice_ids: Array.from(selectedInvoices.value),
        });
        if (response.status === 200) {
          await fetchAllInvoices();
          await fetchAllPendingInvoiceItems();
          selectedInvoices.value.clear();
        } else {
          throw new Error("Error paying invoices.");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const markInvoicesAsCanceled = async () => {
      try {
        const response = await goSolidApi.post("/api/admin/cancel-invoices", {
          invoice_ids: Array.from(selectedInvoices.value),
        });
        if (response.status === 200) {
          await fetchAllInvoices();
          await fetchAllPendingInvoiceItems();
          selectedInvoices.value.clear();
        } else {
          throw new Error("Error canceling invoices.");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const fetchAllInvoices = async () => {
      try {
        const response = await goSolidApi.get("/api/admin/invoices");
        if (response.status === 200 && response.data) {
          invoices.value = response.data.invoices;
        } else {
          throw new Error("Error fetching invoices.");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const fetchAllCompanies = async () => {
      try {
        console.log("afds");
        const response = await goSolidApi.get("/api/admin/companies");
        console.log("FETCH");
        if (response.status === 200 && response.data) {
          console.log(response.data);
          companies.value = response.data;
        } else {
          throw new Error("Error fetching invoices.");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const fetchAllPendingInvoiceItems = async () => {
      try {
        const response = await goSolidApi.get(
          "/api/admin/pending-invoice-items"
        );
        if (response.status === 200 && response.data) {
          pendingInvoiceItems.value = response.data.invoice_items;
        } else {
          throw new Error("Error fetching invoice items.");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    onMounted(async () => {
      await fetchAllInvoices();
      await fetchAllPendingInvoiceItems();
      await fetchAllCompanies();
    });

    return {
      unpaidInvoices,
      paidSalesInvoices,
      invoices,
      pendingInvoiceItems,
      pendingPayouts,
      formatMoney,
      togglePendingCollapse,
      isPendingCollapsed,
      selectedInvoices,
      markInvoicesAsPaid,
      markInvoicesAsCanceled,
      companies,
    };
  },
});
</script>

<style lang="scss" scoped>
#AdminFinances {
  flex-grow: 1;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
}
.container {
  max-width: 900px;
}
.btn-payment {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
</style>
