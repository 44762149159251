import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da5402d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-sm-11 p-2 p-sm-3 card" }
const _hoisted_2 = { class: "row mb-2 g-2 align-items-center" }
const _hoisted_3 = { class: "col-6 col-sm-5" }
const _hoisted_4 = {
  id: "form-title",
  class: "col-form-label"
}
const _hoisted_5 = {
  key: 0,
  class: "col-6 col-sm-5"
}
const _hoisted_6 = {
  id: "form-title",
  class: "col-form-label"
}
const _hoisted_7 = { class: "row g-2 align-items-center" }
const _hoisted_8 = { class: "col-6 col-sm-5" }
const _hoisted_9 = ["placeholder", "value", "disabled"]
const _hoisted_10 = {
  key: 0,
  class: "col-6 col-sm-5"
}
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "col-6 col-sm-2" }
const _hoisted_13 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_14 = { class: "row mb-2 g-2 align-items-center" }
const _hoisted_15 = { class: "col-6 col-sm-5" }
const _hoisted_16 = {
  id: "form-title",
  class: "col-form-label"
}
const _hoisted_17 = { class: "col-6 col-sm-5" }
const _hoisted_18 = {
  id: "form-title",
  class: "col-form-label"
}
const _hoisted_19 = { class: "row g-2 align-items-center" }
const _hoisted_20 = { class: "col-6 col-sm-5" }
const _hoisted_21 = ["placeholder", "value"]
const _hoisted_22 = { class: "col-6 col-sm-5" }
const _hoisted_23 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("signup.add_subsidy_component.subsidy_provider")), 1)
      ]),
      (_ctx.isNotElering)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("signup.add_subsidy_component.decision_date")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("input", {
          type: "text",
          class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.provider.$error }]),
          placeholder: _ctx.$t('signup.add_subsidy_component.placeholder_provider'),
          value: _ctx.subsidy.provider,
          disabled: !_ctx.isNotElering,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateField('provider', $event))),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.v$.provider.$touch && _ctx.v$.provider.$touch(...args)))
        }, null, 42, _hoisted_9),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.provider.$errors, (error) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "invalid-feedback",
            key: error
          }, _toDisplayString(error.$message), 1))
        }), 128))
      ]),
      (_ctx.isNotElering)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("input", {
              type: "date",
              class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.decision_date.$error }]),
              value: _ctx.subsidy.decision_date,
              onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateField('decision_date', $event))),
              onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.v$.decision_date.$touch && _ctx.v$.decision_date.$touch(...args)))
            }, null, 42, _hoisted_11),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.decision_date.$errors?.filter(
            (_element, index) => index < 1
          ), (error) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "invalid-feedback",
                key: error
              }, _toDisplayString(error.$message), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("button", {
          class: "btn btn-subtle-danger",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.removeSubsidy()))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "trash-alt" })
        ])
      ])
    ]),
    (_ctx.extraData && _ctx.isNotElering)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("signup.add_subsidy_component.subsidy_plan")), 1)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("signup.add_subsidy_component.amount")) + " (€)", 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("input", {
                type: "text",
                class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.plan.$error }]),
                placeholder: _ctx.$t('signup.add_subsidy_component.placeholder_plan'),
                value: _ctx.subsidy.plan,
                onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateField('plan', $event))),
                onBlur: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.v$.plan.$touch && _ctx.v$.plan.$touch(...args)))
              }, null, 42, _hoisted_21),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.plan.$errors, (error) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "invalid-feedback",
                  key: error
                }, _toDisplayString(error.$message), 1))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("input", {
                type: "number",
                class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.amount.$error }]),
                value: _ctx.subsidy.amount,
                onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateField('amount', $event))),
                onBlur: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.v$.amount.$touch && _ctx.v$.amount.$touch(...args)))
              }, null, 42, _hoisted_23),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.amount.$errors, (error) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "invalid-feedback",
                  key: error
                }, _toDisplayString(error.$message), 1))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}