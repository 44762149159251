<template>
  <div class="container py-5 pt-3 pt-xl-5">
    <div
      v-if="messageSent === MessageStatusEnum.sent"
      class="alert alert-success mt-3"
      role="alert"
    >
      {{ $t("signup.new_country.success_message") }}
    </div>
    <div
      v-if="messageSent === MessageStatusEnum.failed"
      class="alert alert-danger mt-3"
      role="alert"
    >
      {{ $t("signup.new_country.error_message") }}
    </div>
    <CountryPickerComponent
      :accountData="accountData"
      :handleUpdateAccountData="handleUpdateAccountData"
    ></CountryPickerComponent>

    <!-- Loading overlay, shown only when isLoading is true -->
    <LoadingOverlayComponent v-if="isLoading" />
    <!-- AccountDataDebugComponent for debugging -->
    <AccountDataDebugComponent
      :accountData="accountData"
      class="mb-4"
    ></AccountDataDebugComponent>

    <div class="mb-4">
      <h4>
        <p>{{ $t("signup.new_country.ask_for_message") }}</p>
      </h4>
      <p>{{ $t("signup.new_country.ask_for_message_2") }}</p>
      <textarea
        class="form-control mb-3"
        rows="6"
        v-model="userMessage"
        :placeholder="$t('signup.new_country.placeholder')"
      ></textarea>
      <button
        class="btn btn-primary"
        @click="sendMessage"
        :disabled="
          messageSent === MessageStatusEnum.sending ||
          messageSent === MessageStatusEnum.sent
        "
      >
        {{
          messageSent === MessageStatusEnum.unsent ||
          messageSent === MessageStatusEnum.failed
            ? $t("signup.new_country.send_message")
            : $t("signup.new_country.message_sent")
        }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import type { Account } from "@/types";
import CountryPickerComponent from "@/components/signup/CountryPickerComponent.vue";
import AccountDataDebugComponent from "@/components/common/AccountDataDebugComponent.vue";
import LoadingOverlayComponent from "@/components/common/LoadingOverlayComponent.vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useSelectedCountry } from "@/composables/useSelectedCountry";

enum MessageStatusEnum {
  "unsent",
  "sending",
  "sent",
  "failed",
}

export default defineComponent({
  name: "NewCountrySignupFlow",
  components: {
    AccountDataDebugComponent,
    LoadingOverlayComponent,
    CountryPickerComponent,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    handleUpdateAccountData: {
      type: Function as unknown as () => (updatedAccountData: Account) => void,
      required: true,
    },
  },
  setup(props) {
    const { selectedCountry } = useSelectedCountry(props.accountData);
    const isLoading = ref(true);
    const userMessage = ref("");
    const messageSent = ref(MessageStatusEnum.unsent);

    const sendMessage = async () => {
      try {
        messageSent.value = MessageStatusEnum.sending;
        let data = {
          message: userMessage.value,
          country: selectedCountry.value,
        };
        const response = await goSolidApi.post(
          "/api/utils/signup/deliver-note",
          data
        );
        if (response.status === 200) {
          messageSent.value = MessageStatusEnum.sent;
        } else {
          messageSent.value = MessageStatusEnum.failed;
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        messageSent.value = MessageStatusEnum.failed;
        console.error("Error fetching data:", error);
      }
    };

    onMounted(async () => {
      isLoading.value = false;
    });

    return {
      isLoading,
      userMessage,
      sendMessage,
      messageSent,
      MessageStatusEnum,
    };
  },
});
</script>
