<!-- client/src/components/signup/ee/GeneratorReviewComponent.vue -->

<template>
  <div class="generator-review card p-4 mb-3" novalidate>
    <h3 class="mb-1 mb-sm-4">
      {{ $t("signup.add_generator_component.title") }}
    </h3>

    <!-- Form fields with left-aligned labels and edit icons -->
    <div class="row mb-2 g-2" v-for="field in generatorFields" :key="field.id">
      <label :for="field.id" class="col-sm-3 mt-sm-3 col-form-label">
        {{ field.label }}
      </label>
      <div class="col-sm-8 col-10">
        <input
          :type="field.type"
          :id="field.id"
          class="form-control"
          :class="{ 'is-invalid': v$[field.model].$error }"
          :disabled="!field.editable"
          v-model="localGenerator[field.model]"
          @blur="v$[field.model].$touch"
        />
        <div
          class="invalid-feedback"
          v-for="error of v$[field.model].$errors.filter(
            (_element: any, index: number) => index < 1
          )"
          :key="error.$uid"
        >
          {{ error.$message }}
        </div>
      </div>
      <div class="col-sm-1 col-2">
        <button
          class="btn btn-outline-secondary"
          v-if="!field.editable"
          @click="enableEditing(field)"
        >
          <font-awesome-icon icon="pencil-alt" />
        </button>
      </div>
    </div>

    <!-- Technology Selection Section -->
    <h3 class="mb-1 mb-sm-4 mt-4">
      {{ $t("signup.add_generator_component.device_technology_title") }}
    </h3>
    <div class="row mb-2 g-2">
      <label for="device-technology" class="col-sm-12 pb-0 col-form-label">
        {{ $t("signup.add_generator_component.device_technology_description") }}
      </label>
      <div class="col-sm-11">
        <div class="form-check" role="button">
          <input
            class="form-check-input pe-auto"
            role="button"
            type="radio"
            name="device_technology_radio"
            id="device-technology-solar"
            value="solar"
            v-model="technologySelection"
            @change="v$.device_technology.$touch"
            :class="{ 'is-invalid': v$.device_technology.$error }"
          />
          <label
            class="form-check-label"
            for="device-technology-solar"
            role="button"
          >
            {{ $t("signup.add_generator_component.device_technology_solar") }}
          </label>
        </div>
        <div class="form-check" role="button">
          <input
            class="form-check-input"
            role="button"
            type="radio"
            name="device_technology_radio"
            id="device-technology-wind"
            value="wind"
            v-model="technologySelection"
            @change="v$.device_technology.$touch"
            :class="{ 'is-invalid': v$.device_technology.$error }"
          />
          <label
            class="form-check-label"
            for="device-technology-wind"
            role="button"
          >
            {{ $t("signup.add_generator_component.device_technology_wind") }}
          </label>
        </div>
        <div class="form-check" role="button">
          <input
            class="form-check-input"
            role="button"
            type="radio"
            name="device_technology_radio"
            id="device-technology-hydro"
            value="hydro"
            v-model="technologySelection"
            @change="v$.device_technology.$touch"
            :class="{ 'is-invalid': v$.device_technology.$error }"
          />
          <label
            class="form-check-label"
            for="device-technology-hydro"
            role="button"
          >
            {{ $t("signup.add_generator_component.device_technology_hydro") }}
          </label>
        </div>
        <div class="form-check" role="button">
          <input
            class="form-check-input"
            role="button"
            type="radio"
            name="device_technology_radio"
            id="device-technology-mixed"
            value="mixed"
            v-model="technologySelection"
            @change="v$.device_technology.$touch"
            :class="{ 'is-invalid': v$.device_technology.$error }"
          />
          <label
            class="form-check-label"
            for="device-technology-mixed"
            role="button"
          >
            {{ $t("signup.add_generator_component.device_technology_mixed") }}
          </label>
        </div>
        <div
          class="invalid-feedback"
          v-for="error of v$.device_technology.$errors"
          :key="error.$uid"
        >
          {{ error.$message }}
        </div>
      </div>
    </div>

    <h3 class="mb-1 mb-sm-4 mt-4">
      {{ $t("signup.add_generator_component.subsidy_title") }}
    </h3>
    <!-- Has Subsidy Field - Always Editable -->
    <div class="row mb-2 g-2">
      <label for="has-subsidy" class="col-sm-12 pb-0 col-form-label">
        {{ $t("signup.add_generator_component.subsidy_question") }}
      </label>
      <div class="col-sm-11">
        <!-- Radio buttons for subsidy selection -->
        <div class="form-check" role="button">
          <input
            class="form-check-input pe-auto"
            role="button"
            type="radio"
            name="subsidy_radio"
            id="has-subsidy-no"
            value="false"
            v-model="subsidySelection"
            @change="v$.has_subsidy.$touch"
            :class="{ 'is-invalid': v$.has_subsidy.$error }"
          />
          <label class="form-check-label" for="has-subsidy-no" role="button">
            {{ $t("common.no") }}
          </label>
        </div>
        <div class="form-check" role="button">
          <input
            class="form-check-input"
            role="button"
            type="radio"
            name="subsidy_radio"
            id="has-subsidy-elering"
            value="Elering AS"
            v-model="subsidySelection"
            @change="v$.has_subsidy.$touch"
            :class="{ 'is-invalid': v$.has_subsidy.$error }"
          />
          <label
            class="form-check-label"
            for="has-subsidy-elering"
            role="button"
          >
            {{ $t("signup.add_generator_component.elering_support") }}
          </label>
        </div>
        <div class="form-check" role="button">
          <input
            class="form-check-input"
            role="button"
            type="radio"
            name="subsidy_radio"
            id="has-subsidy-yes"
            value="true"
            v-model="subsidySelection"
            @change="v$.has_subsidy.$touch"
            :class="{ 'is-invalid': v$.has_subsidy.$error }"
          />
          <label class="form-check-label" for="has-subsidy-yes" role="button">
            {{ $t("common.yes") }}
          </label>
        </div>
        <div
          class="invalid-feedback"
          v-for="error of v$.has_subsidy.$errors"
          :key="error.$uid"
        >
          {{ error.$message }}
        </div>
      </div>
    </div>

    <!-- Subsidy Management Section -->
    <div
      v-if="subsidySelection === 'true' || subsidySelection === 'Elering AS'"
      class="row mb-2 gy-3 gx-0 mt-1"
    >
      <label class="col-form-label mb-0 pb-0 mt-0">{{
        $t("signup.add_generator_component.enter_subsidies")
      }}</label>
      <div v-for="(_subsidy, index) in localGenerator.subsidies" :key="index">
        <subsidy-card-component
          v-model:subsidy="localGenerator.subsidies[index]"
          :extraData="
            !!(localGenerator.device_power && localGenerator.device_power >= 50)
          "
          :removeSubsidy="() => removeSubsidy(index)"
        />
      </div>
      <button class="btn btn-subtle-primary col-sm-11" @click="addSubsidyClick">
        <font-awesome-icon icon="plus" />
        {{ $t("signup.add_generator_component.add_subsidy") }}
      </button>
    </div>

    <!-- BATTERY SECTION -->
    <div v-if="subsidySelection && hasNoEleringSubsidy">
      <h3 class="mb-1 mb-sm-4 mt-4">
        {{ $t("signup.add_generator_component.battery_section_title") }}
      </h3>
      <div class="row mb-2 g-2">
        <label for="has-battery" class="col-sm-12 pb-0 col-form-label">
          {{ $t("signup.add_generator_component.battery_question") }}
        </label>
        <div class="col-sm-11">
          <select
            class="form-select"
            role="button"
            id="has-battery"
            v-model="batterySelection"
          >
            <option value="" disabled>
              {{ $t("signup.add_generator_component.battery_question") }}
            </option>
            <option value="true">{{ $t("common.yes") }}</option>
            <option value="false">{{ $t("common.no") }}</option>
          </select>
        </div>
      </div>

      <div v-if="batterySelection === 'true'" class="row mb-2 g-3 mt-1">
        <label class="col-form-label mb-0 pb-0 mt-0">
          {{ $t("signup.add_generator_component.battery_details") }}
        </label>
        <div class="col-sm-11 p-2 p-sm-4 card">
          <div class="row mb-3 g-2 align-items-center">
            <div class="col-6 col-sm-5">
              <label class="col-form-label">{{
                $t("signup.add_generator_component.unit_power")
              }}</label>
            </div>
            <div class="col-6 col-sm-5">
              <label class="col-form-label">{{
                $t("signup.add_generator_component.capacity")
              }}</label>
            </div>
          </div>
          <div class="row mb-3 g-2 align-items-center">
            <div class="col-6 col-sm-5">
              <input
                type="number"
                class="form-control"
                v-model="localGenerator.battery!.kw"
                @blur="v$.battery.kw.$touch"
                :class="{ 'is-invalid': v$.battery.kw.$error }"
              />
              <div
                class="invalid-feedback"
                v-for="error of v$.battery.kw.$errors"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
            <div class="col-6 col-sm-5">
              <input
                type="number"
                class="form-control"
                v-model="localGenerator.battery!.kwh"
                @blur="v$.battery.kwh.$touch"
                :class="{ 'is-invalid': v$.battery.kwh.$error }"
              />
              <div
                class="invalid-feedback"
                v-for="error of v$.battery.kwh.$errors"
                :key="error.$uid"
              >
                {{ error.$message }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PPA SECTION -->
    <div
      v-if="
        localGenerator.device_power &&
        localGenerator.device_power >= 500 &&
        subsidySelection &&
        hasNoEleringSubsidy
      "
    >
      <h3 class="mb-1 mb-sm-4 mt-4">
        {{ $t("signup.add_generator_component.ppa_section_title") }}
      </h3>
      <div class="row mb-2 g-2">
        <label for="has-ppa" class="col-sm-12 pb-0 col-form-label">
          {{ $t("signup.add_generator_component.ppa_question") }}
        </label>
        <div class="col-sm-11">
          <select
            class="form-select"
            role="button"
            id="has-ppa"
            v-model="ppaSelection"
          >
            <option value="" disabled>
              {{ $t("signup.add_generator_component.ppa_question") }}
            </option>
            <option value="true">{{ $t("common.yes") }}</option>
            <option value="false">{{ $t("common.no") }}</option>
          </select>
        </div>
      </div>
      <div>
        <div v-if="ppaSelection === 'true'" class="row mb-2 g-2">
          <label for="has-ppa-sale" class="col-sm-12 pb-0 col-form-label">
            {{
              $t(
                "signup.add_generator_component.ppa_include_certificates_question"
              )
            }}
          </label>
          <div class="col-sm-11">
            <select
              class="form-select"
              role="button"
              id="has-ppa-sale"
              v-model="ppaSelection2"
            >
              <option value="" disabled>
                {{
                  $t(
                    "signup.add_generator_component.ppa_include_certificates_question"
                  )
                }}
              </option>
              <option value="true">{{ $t("common.yes") }}</option>
              <option value="false">{{ $t("common.no") }}</option>
            </select>
          </div>
        </div>
        <div v-if="ppaSelection2 === 'true'" class="row mb-2 g-3 mt-1">
          <label class="col-form-label mb-0 pb-0 mt-0">
            {{ $t("signup.add_generator_component.ppa_details") }}
          </label>
          <div class="col-sm-11 p-2 p-sm-4 card">
            <div class="row mb-3 g-2 align-items-center">
              <div class="col-6 col-sm-5">
                <label class="col-form-label">
                  {{ $t("signup.add_generator_component.ppa_start_date") }}
                </label>
              </div>
              <div class="col-6 col-sm-5">
                <label class="col-form-label">
                  {{ $t("signup.add_generator_component.ppa_mwh") }}
                </label>
              </div>
            </div>
            <div class="row mb-3 g-2 align-items-center">
              <div class="col-6 col-sm-5">
                <input
                  type="date"
                  class="form-control"
                  v-model="localGenerator.ppa!.start_date"
                  @blur="v$.ppa.start_date.$touch"
                  :class="{ 'is-invalid': v$.ppa.start_date.$error }"
                />
                <div
                  class="invalid-feedback"
                  v-for="error of v$.ppa.start_date.$errors.filter((_element: any, index: number) => index < 1)"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
              <div class="col-6 col-sm-5">
                <input
                  type="number"
                  class="form-control"
                  v-model="localGenerator.ppa!.mwh"
                  @blur="v$.ppa.mwh.$touch"
                  :class="{ 'is-invalid': v$.ppa.mwh.$error }"
                />
                <div
                  class="invalid-feedback"
                  v-for="error of v$.ppa.mwh.$errors"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>
            <div class="row mb-3 g-2 align-items-center">
              <div class="col-6 col-sm-5">
                <label class="col-form-label">
                  {{ $t("signup.add_generator_component.ppa_client_name") }}
                </label>
              </div>
              <div class="col-6 col-sm-5">
                <label class="col-form-label">
                  {{ $t("signup.add_generator_component.ppa_country") }}
                </label>
              </div>
            </div>
            <div class="row mb-3 g-2 align-items-center">
              <div class="col-6 col-sm-5">
                <input
                  type="text"
                  class="form-control"
                  v-model="localGenerator.ppa!.client_company_name"
                  @blur="v$.ppa.client_company_name.$touch"
                  :class="{ 'is-invalid': v$.ppa.client_company_name.$error }"
                />
                <div
                  class="invalid-feedback"
                  v-for="error of v$.ppa.client_company_name.$errors"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
              <div class="col-6 col-sm-5">
                <country-select
                  class="form-control"
                  v-model="localGenerator.ppa!.country"
                  :country="localGenerator.ppa!.country"
                  topCountry="EE"
                  @blur="v$.ppa.country.$touch"
                  :class="{ 'is-invalid': v$.ppa.country.$error }"
                />
                <div
                  class="invalid-feedback"
                  v-for="error of v$.ppa.country.$errors"
                  :key="error.$uid"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref, watch } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SubsidyCardComponent from "@/components/signup/ee/SubsidyCardComponent.vue";
import type { Account, Subsidy } from "@/types";
import { useI18n } from "vue-i18n";
import { useValidators } from "@/composables/useValidators";
import { useVuelidate } from "@vuelidate/core";
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";

export default defineComponent({
  name: "GeneratorReviewComponent",
  components: {
    FontAwesomeIcon,
    SubsidyCardComponent,
  },
  props: {
    generatorIndex: {
      type: Number,
      required: true,
    },
    accountData: {
      type: Object as () => Account,
      required: true,
    },
  },
  emits: ["handleUpdateGenerator"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      validatorIf,
      required,
      requiredIf,
      estonianDeviceEIC,
      notExampleEIC,
      someSubsidies,
      reasonableDate,
    } = useValidators(t);

    const { selectedGenerator } = useGeneratorFinder(props.accountData);
    const generator = selectedGenerator.value.generator;

    const localGenerator = reactive({
      ...generator,
    });
    const batterySelection = ref(
      localGenerator.battery !== null ? "true" : "false"
    );
    const ppaSelection = ref(localGenerator.ppa !== null ? "true" : "false");
    const ppaSelection2 = ref(localGenerator.ppa !== null ? "true" : "false");
    const technologySelection = ref(localGenerator.device_technology);
    const subsidySelection = ref(
      localGenerator.has_subsidy
        ? localGenerator.subsidies.length > 0 &&
          localGenerator.subsidies[0].provider === "Elering AS"
          ? "Elering AS"
          : "true"
        : "false"
    );

    const generatorFields = reactive([
      {
        label: t("generator.device_name"),
        id: "device-name",
        model: "device_name",
        type: "text",
        editable: false,
        validators: { required },
      },
      {
        label: t("generator.device_power"),
        id: "device-power",
        model: "device_power",
        type: "number",
        editable: false,
        validators: { required },
      },
      {
        label: t("generator.device_eic"),
        id: "device-eic",
        model: "device_eic",
        editable: false,
        validators: { required, estonianDeviceEIC, notExampleEIC },
      },
      {
        label: t("generator.device_address"),
        id: "device-address",
        model: "device_address",
        type: "text",
        editable: false,
        validators: { required },
      },
      {
        label: t("generator.grid_contract_start_date"),
        id: "grid-contract-start-date",
        model: "grid_contract_start_date",
        type: "date",
        editable: false,
        validators: { required, reasonableDate },
      },
    ]);

    const ispPPARequired = computed(() => {
      return (localGenerator.device_power &&
        localGenerator.device_power >= 500 &&
        ppaSelection.value === "true" &&
        ppaSelection2.value === "true") as boolean;
    });

    const isBatteryRequired = computed(() => {
      return (batterySelection.value === "true" &&
        hasNoEleringSubsidy.value) as boolean;
    });

    const rules = computed(() => {
      const rules: { [key: string]: any } = {};
      generatorFields.forEach((field) => {
        rules[field.model] = field.validators;
      });
      rules.device_technology = { required };
      rules.has_subsidy = [
        required,
        someSubsidies(computed(() => localGenerator.subsidies)),
      ];
      rules.battery = {
        kw: {
          requiredIf: requiredIf(isBatteryRequired),
        },
        kwh: {
          requiredIf: requiredIf(isBatteryRequired),
        },
      };
      rules.ppa = {
        start_date: {
          requiredIf: requiredIf(ispPPARequired),
          reasonableDate: validatorIf(ispPPARequired, reasonableDate),
        },
        mwh: {
          requiredIf: requiredIf(ispPPARequired),
        },
        client_company_name: {
          requiredIf: requiredIf(ispPPARequired),
        },
        country: {
          requiredIf: requiredIf(ispPPARequired),
        },
      };
      return rules;
    });

    const v$ = useVuelidate(rules, localGenerator);

    const enableEditing = (field: { ["editable"]: boolean }) => {
      field.editable = true;
    };

    const hasNoEleringSubsidy = computed(() => {
      return (
        localGenerator.subsidies.filter(
          (subsidy: Subsidy) => subsidy.provider === "Elering AS"
        ).length === 0
      );
    });

    const addSubsidyClick = () => {
      addSubsidy();
    };

    const addSubsidy = (provider = "") => {
      localGenerator.subsidies.push({
        provider: provider,
        decision_date: null,
        plan: null,
        amount: null,
      });
    };

    const removeSubsidy = (index: number) => {
      localGenerator.subsidies.splice(index, 1);
      if (localGenerator.subsidies.length === 0) {
        subsidySelection.value = "false";
      }
    };

    watch(technologySelection, (newValue) => {
      localGenerator.device_technology = newValue;
    });

    watch(subsidySelection, (newValue: string) => {
      localGenerator.has_subsidy =
        newValue === "true" || newValue === "Elering AS" ? true : false;
      if (newValue === "false") {
        localGenerator.subsidies = [];
      }
      if (newValue === "true") {
        localGenerator.subsidies = [];
        addSubsidy();
      }
      if (newValue === "Elering AS") {
        localGenerator.subsidies = [];
        addSubsidy("Elering AS");
      }
    });

    // BATTERY
    watch(batterySelection, (newValue: string) => {
      batterySelection.value = newValue;
      if (newValue === "false") {
        localGenerator.battery = null;
      }
      if (newValue === "true") {
        localGenerator.battery = {
          generator_id: localGenerator.id!,
          kw: null,
          kwh: null,
        };
      }
    });

    // PPA
    watch(ppaSelection, (newValue: string) => {
      ppaSelection.value = newValue;
      if (newValue === "false") {
        ppaSelection2.value = "false";
        localGenerator.ppa = null;
      }
    });

    watch(ppaSelection2, (newValue: string) => {
      ppaSelection2.value = newValue;
      if (newValue === "false") {
        localGenerator.ppa = null;
      }
      if (newValue === "true") {
        localGenerator.ppa = {
          id: undefined,
          generator_id: localGenerator.id!,
          start_date: null,
          mwh: null,
          client_company_name: null,
          country: null,
        };
      }
    });

    watch(
      localGenerator,
      (newValue) => {
        emit("handleUpdateGenerator", newValue);
      },
      { deep: true }
    );

    return {
      generatorFields,
      localGenerator,
      technologySelection,
      subsidySelection,
      batterySelection,
      ppaSelection,
      ppaSelection2,
      enableEditing,
      addSubsidyClick,
      removeSubsidy,
      hasNoEleringSubsidy,
      v$,
    };
  },
});
</script>

<style scoped>
.generator-review {
  .col-form-label {
    text-align: left;
    font-weight: bold;
    padding: 0;
  }
  .btn-outline-secondary {
    padding: 0.375rem 0.75rem;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 575.98px) {
  .generator-review {
    .col-form-label {
      margin-top: 1rem;
    }
  }
  #form-title {
    margin-top: 0.2rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  .btn-subtle-danger {
    width: 100%;
  }
}
</style>
