import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "card rounded-0 border-start-0 border-end-0" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["data-bs-target", "aria-controls"]
const _hoisted_6 = { class: "me-3 text-secondary-emphasis" }
const _hoisted_7 = { class: "card-title mb-1 fs-7 fw-bold" }
const _hoisted_8 = { class: "text-body-tertiary" }
const _hoisted_9 = ["id", "aria-labelledby"]
const _hoisted_10 = { class: "card-text" }
const _hoisted_11 = { class: "accordion border rounded p-2" }
const _hoisted_12 = { class: "accordion-item" }
const _hoisted_13 = { class: "accordion-body" }
const _hoisted_14 = { class: "registryDevice-segment" }
const _hoisted_15 = { class: "registryDevice-property" }
const _hoisted_16 = { class: "registryDevice-value" }
const _hoisted_17 = { class: "registryDevice-segment" }
const _hoisted_18 = { class: "registryDevice-property" }
const _hoisted_19 = { class: "registryDevice-value" }
const _hoisted_20 = { class: "registryDevice-segment" }
const _hoisted_21 = { class: "registryDevice-property" }
const _hoisted_22 = { class: "registryDevice-value" }
const _hoisted_23 = { class: "registryDevice-segment" }
const _hoisted_24 = { class: "registryDevice-property" }
const _hoisted_25 = { class: "registryDevice-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TechnologyIcon = _resolveComponent("TechnologyIcon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.registryDevices.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.registryDevices, (registryDevice) => {
          return (_openBlock(), _createElementBlock("div", {
            key: registryDevice.id
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createElementVNode("h2", {
                    class: "accordion-header",
                    id: 'heading' + registryDevice.id
                  }, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "collapsed d-flex flex-row align-items-center",
                      type: "accordion-button button",
                      "data-bs-toggle": "collapse",
                      "data-bs-target": '#collapse' + registryDevice.id,
                      "aria-expanded": "false",
                      "aria-controls": 'collapse' + registryDevice.id
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_TechnologyIcon, {
                          technology: registryDevice.technology
                        }, null, 8, ["technology"])
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(registryDevice.device_name), 1),
                        _createElementVNode("h6", _hoisted_8, [
                          _createVNode(_component_font_awesome_icon, { icon: "square-bolt" }),
                          _createTextVNode(" " + _toDisplayString(registryDevice.device_power) + " kW ", 1),
                          _createVNode(_component_font_awesome_icon, {
                            icon: "hashtag",
                            class: "ms-1"
                          }),
                          _createTextVNode(" " + _toDisplayString(registryDevice.device_eic), 1)
                        ])
                      ])
                    ], 8, _hoisted_5)
                  ], 8, _hoisted_4),
                  _createElementVNode("div", {
                    id: 'collapse' + registryDevice.id,
                    class: "accordion-collapse collapse",
                    "aria-labelledby": 'heading' + registryDevice.id
                  }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("registry_device.device_code")) + ": ", 1),
                              _createElementVNode("div", _hoisted_16, _toDisplayString(registryDevice.device_eic), 1)
                            ]),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("registry_device.device_power")) + ": ", 1),
                              _createElementVNode("div", _hoisted_19, _toDisplayString(registryDevice.device_power), 1)
                            ]),
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("registry_device.technology_code")) + ": ", 1),
                              _createElementVNode("div", _hoisted_22, _toDisplayString(registryDevice.technology_code), 1)
                            ]),
                            _createElementVNode("div", _hoisted_23, [
                              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("registry_device.supported_device")) + "? ", 1),
                              _createElementVNode("div", _hoisted_25, _toDisplayString(registryDevice.support
                              ? _ctx.$t("common.yes")
                              : _ctx.$t("common.no")), 1)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ], 8, _hoisted_9)
                ])
              ])
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}