<!-- views/admin/integrations/lt-eso/EsoInfoView.vue -->

<template>
  <div class="api-test-view container py-5 overflow-y-auto">
    <h1 class="mb-4">Permissions</h1>
    <!--
    <div>
      <button class="btn btn-primary" @click="testReq">test something</button>
    </div>
    -->
    <table v-if="companies.length" class="table mt-4">
      <thead>
        <tr>
          <th>Company Name</th>
          <th>Object Numbers</th>
          <th>Registry Code</th>
          <th>Ask Permissions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="company in companies" :key="company.registryCode">
          <td>{{ company.companyName }}</td>
          <td>{{ company.objectNumbers.join(", ") }}</td>
          <td>{{ company.registryCode }}</td>
          <td>
            <button
              class="btn btn-primary"
              @click="askPermissionsRequest(company.registryCode)"
            >
              Ask Permissions
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-primary" @click="makeApiRequest">
      Generation data
    </button>
    <h1 class="mb-4">Generation</h1>
    <table v-if="generationData.length" class="table mt-4">
      <thead>
        <tr>
          <th>Company Name</th>
          <th>Object Address</th>
          <th>Object Number</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in generationData" :key="data.objectNumber">
          <td>{{ data.companyName }}</td>
          <td>{{ data.objectAddress }}</td>
          <td>{{ data.objectNumber }}</td>
          <td>{{ data.amount }}</td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-primary" @click="syncMeterMetadataReq">
      Sync ESO meter metaData
    </button>
    <button class="btn btn-primary" @click="askGenerationDataRequest">
      Query generation data from ESO
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";

export default defineComponent({
  name: "EspInfoView",
  setup: function () {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const companies = ref([]);
    const generationData = ref([]);
    //"/api/admin/lt-eso/get-objects/random_crap"
    const makeApiRequest = async () => {
      try {
        const response = await goSolidApi.get(
          "/api/admin/lt-eso/list-companies"
        );
        if (response.status === 200 && response.data) {
          console.log("API Response:", response.data);
          companies.value = response.data;
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        globalErrorHandler(error.response?.data || error);
      }
    };

    const askGenerationDataRequest = async () => {
      try {
        const response = await goSolidApi.get(
          "/api/admin/lt-eso/ask-generation-data"
        );
        if (response.status === 200 && response.data) {
          console.log("API Response:", response.data);
          generationData.value = response.data;
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        globalErrorHandler(error.response?.data || error);
      }
    };
    const syncMeterMetadataRequest = async () => {
      try {
        const response = await goSolidApi.get(
          "/api/admin/lt-eso/sync-meter-metadata"
        );
        if (response.status === 200 && response.data) {
          console.log("API Response:", response.data);
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        globalErrorHandler(error.response?.data || error);
      }
    };
    const askGrexelProductionDevicesReq = async () => {
      try {
        const response = await goSolidApi.get(
          "/api/admin/integration/grexel/get-devices"
        );
        if (response.status === 200 && response.data) {
          console.log("API Response:", response.data);
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        globalErrorHandler(error.response?.data || error);
      }
    };

    const askPermissionsRequest = async (registryCode: string) => {
      try {
        const response = await goSolidApi.post(
          "/api/admin/lt-eso/ask-permissions/" + registryCode
        );
        await makeApiRequest();
      } catch (error: any) {
        globalErrorHandler(error.response?.data || error);
      }
    };

    const testReq = async (registryCode: string) => {
      try {
        const response = await goSolidApi.get("/api/admin/lt-eso/test");
        console.log(response);
      } catch (error: any) {
        globalErrorHandler(error.response?.data || error);
      }
    };

    return {
      makeApiRequest,
      companies,
      askPermissionsRequest,
      askGenerationDataRequest,
      generationData,
      askGrexelProductionDevicesReq,
      syncMeterMetadataReq: syncMeterMetadataRequest,
      testReq,
    };
  },
});
</script>

<style scoped>
.api-test-view {
  text-align: center;
}
</style>
