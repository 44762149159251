<!-- client/src/components/signup/ee/EESignupFlow.vue -->

<template>
  <div class="container py-5 pt-3 pt-xl-5">
    <CountryPickerComponent
      :accountData="accountData"
      :handleUpdateAccountData="handleUpdateAccountData"
    ></CountryPickerComponent>

    <!-- Loading overlay, shown only when isLoading is true -->
    <LoadingOverlayComponent v-if="isLoading" />
    <!-- AccountDataDebugComponent for debugging -->
    <AccountDataDebugComponent
      :accountData="accountData"
      class="mb-4"
    ></AccountDataDebugComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import type { Account } from "@/types";
import CountryPickerComponent from "@/components/signup/CountryPickerComponent.vue";
import AccountDataDebugComponent from "@/components/common/AccountDataDebugComponent.vue";
import LoadingOverlayComponent from "@/components/common/LoadingOverlayComponent.vue";

export default defineComponent({
  name: "EESignupFlow",
  components: {
    AccountDataDebugComponent,
    LoadingOverlayComponent,
    CountryPickerComponent,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    handleUpdateAccountData: {
      type: Function as unknown as () => (updatedAccountData: Account) => void,
      required: true,
    },
  },
  setup() {
    const isLoading = ref(true);

    onMounted(async () => {
      isLoading.value = false;
    });

    return {
      isLoading,
    };
  },
});
</script>
