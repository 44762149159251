<!-- client/src/components/common/ForwardSaleScheduleCard.vue -->
<template>
  <div
    class="col"
    :class="{
      'empty-state': !saleAmounts,
      'd-none': !saleAmounts,
      'd-lg-block': !saleAmounts,
    }"
  >
    <div class="forward-sale-card border rounded shadow">
      <div class="card-body p-2 px-3 p-sm-2 px-sm-3">
        <div class="d-flex align-items-center mb-2">
          <div class="fw-bold">
            {{
              $t("forwardSales.quarterSaleTitle", {
                year: saleYear,
                quarter: saleQuarter,
              })
            }}
          </div>
        </div>
        <hr class="my-1" />
        <p class="mb-1">{{ $t("forwardSales.deliveryAt") }}</p>
        <template v-if="saleAmounts">
          <table class="table table-sm mb-0">
            <tbody>
              <tr v-for="quarter in 4" :key="quarter">
                <td class="text-body-secondary border-0">
                  {{
                    $t("forwardSales.quarterLabel", {
                      year: saleYear + 1,
                      quarter: quarter,
                    })
                  }}
                </td>
                <td class="text-body-primary text-end border-0">
                  {{ saleAmounts[quarter - 1] }}
                </td>
              </tr>
              <tr class="border-top">
                <td class="text-body-secondary fw-bold border-0">
                  {{ $t("common.total") }}
                </td>
                <td class="text-body-primary fw-bold text-end border-0">
                  {{ saleAmounts.reduce((a, b) => a + b, 0) }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          <!-- Empty state placeholder that maintains same height as filled state -->
          <div class="empty-content">
            <div class="placeholder-line"></div>
            <div class="placeholder-table">
              <div v-for="i in 5" :key="i" class="placeholder-row"></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ForwardSaleScheduleCard",
  props: {
    saleYear: {
      type: Number,
      required: true,
    },
    saleQuarter: {
      type: Number,
      required: true,
    },
    saleAmounts: {
      type: Array as () => number[],
      required: false,
      default: null,
      validator: (value: number[] | null) => {
        return value === null || (Array.isArray(value) && value.length === 4);
      },
    },
  },
});
</script>

<style scoped>
.table {
  font-size: 0.875rem;
}

.table td {
  padding: 0.25rem 0.5rem;
}

.forward-sale-card {
  background-color: rgb(242, 245, 250);
}

.empty-state {
  background-color: rgb(250, 250, 252);
  opacity: 0.3;
}

.empty-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.placeholder-line {
  height: 1px;
  background-color: var(--bs-border-color);
  margin: 0.25rem 0;
}

.placeholder-table {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.placeholder-row {
  height: 1rem; /* Adjust to match your table row height */
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

.placeholder-row:last-child {
  margin-top: 0.5rem;
  border-top: 1px solid var(--bs-border-color);
  margin-bottom: 0.5rem;
}
</style>
