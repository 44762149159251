import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f053148"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "account-data-debug p-3 bg-light border rounded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isDebugEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("pre", null, _toDisplayString(_ctx.prettyAccountData), 1),
        _createElementVNode("button", {
          class: "btn btn-warning w-100 mt-2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleResetAccountData && _ctx.handleResetAccountData(...args)))
        }, " Force Reset Account Data ")
      ]))
    : _createCommentVNode("", true)
}