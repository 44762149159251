<!-- client/src/components/signup/CompanySelectDropdown.vue -->

<template>
  <div class="row mb-2 g-2">
    <label for="companySelect" class="col-sm-3 mt-3 mt-sm-2 col-form-label">
      {{ $t("signup.contact_review_component.select_company") }}
    </label>
    <div class="col-sm-8 col-10">
      <select
        v-if="showCompanyDropdown"
        id="companySelect"
        class="form-select"
        @change="onCompanySelect"
      >
        <option value="">
          {{ $t("signup.contact_review_component.add_new_company") }}
        </option>
        <option
          v-for="company in existingCompanies"
          :key="company.id"
          :value="company.id"
        >
          {{ company.name }} ({{ company.registry_code }})
        </option>
      </select>
      <div v-else-if="selectedCompany && isExistingCompanyWithDocuments">
        <div class="d-flex align-items-center">
          <div class="me-2">
            {{ selectedCompany.name }} ({{ selectedCompany.registry_code }})
          </div>
          <button
            class="btn btn-link btn-sm m-0 p-1"
            @click="resetCompanySelection"
            title="Reset company selection"
          >
            <font-awesome-icon icon="rotate-left" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { Company } from "@/types";

export default defineComponent({
  name: "CompanySelectDropdown",
  components: {
    FontAwesomeIcon,
  },
  props: {
    existingCompanies: {
      type: Array as () => Company[],
      default: () => [],
    },
    selectedCompany: {
      type: Object as () => Company,
      required: false,
    },
  },
  emits: ["selectExistingCompany", "resetCompanySelection"],
  setup(props, { emit }) {
    const showCompanyDropdown = computed(() => {
      return (
        props.existingCompanies.length > 0 &&
        (!props.selectedCompany ||
          (props.selectedCompany.documents &&
            props.selectedCompany.documents.length === 0))
      );
    });

    const isExistingCompanyWithDocuments = computed(() => {
      return (
        props.selectedCompany &&
        props.selectedCompany.id !== undefined &&
        props.selectedCompany.documents &&
        props.selectedCompany.documents.length > 0
      );
    });

    const onCompanySelect = (event: Event) => {
      const selectedCompanyId = (event.target as HTMLSelectElement).value;
      if (selectedCompanyId) {
        emit("selectExistingCompany", parseInt(selectedCompanyId, 10));
      }
    };

    const resetCompanySelection = () => {
      emit("resetCompanySelection");
    };

    return {
      showCompanyDropdown,
      isExistingCompanyWithDocuments,
      onCompanySelect,
      resetCompanySelection,
    };
  },
});
</script>
