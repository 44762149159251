import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3249be0f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card p-4 mb-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "mb-1 mb-sm-2" }
const _hoisted_6 = { class: "card p-3 mb-3" }
const _hoisted_7 = { class: "mb-1 mb-sm-2" }
const _hoisted_8 = { class: "row g-2 py-1" }
const _hoisted_9 = { class: "col-sm-3 col-form-label py-0" }
const _hoisted_10 = { class: "col-sm-8 col-10 py-0" }
const _hoisted_11 = { class: "card p-3 mb-3" }
const _hoisted_12 = { class: "mb-1 mb-sm-2" }
const _hoisted_13 = { class: "row g-2 py-1" }
const _hoisted_14 = { class: "col-sm-3 col-form-label py-0" }
const _hoisted_15 = { class: "col-sm-8 col-10 py-0" }
const _hoisted_16 = {
  key: 0,
  class: "card p-3 mb-3"
}
const _hoisted_17 = { class: "mb-1 mb-sm-2" }
const _hoisted_18 = { class: "row g-2 py-1" }
const _hoisted_19 = { class: "col-sm-3 col-form-label py-0" }
const _hoisted_20 = {
  class: "col-sm-8 col-10 py-0",
  style: {"white-space":"pre-line"}
}
const _hoisted_21 = { class: "mb-1 mb-sm-4 mt-4" }
const _hoisted_22 = { class: "mb-2 mb-md-0 d-flex flex-column justify-content-center justify-content-md-start" }
const _hoisted_23 = { class: "mb-0" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "text-center text-md-start" }
const _hoisted_26 = {
  key: 0,
  class: "badge badge-phoenix badge-phoenix-success fs-10 p-1 px-3 mt-2"
}
const _hoisted_27 = {
  key: 1,
  class: "badge badge-phoenix badge-phoenix-primary fs-10 p-1 px-3 mt-2"
}
const _hoisted_28 = { key: 0 }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = ["onClick"]
const _hoisted_31 = {
  key: 0,
  class: "avatar avatar-x1 me-2"
}
const _hoisted_32 = {
  key: 1,
  class: "form-check admin-checkbox"
}
const _hoisted_33 = ["onUpdate:modelValue", "disabled"]
const _hoisted_34 = { class: "text-body-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_InfoCardComponent = _resolveComponent("InfoCardComponent")!
  const _component_SlottedContainerComponent = _resolveComponent("SlottedContainerComponent")!
  const _component_LoadingOverlayComponent = _resolveComponent("LoadingOverlayComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hasSignedDocuments)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("signup.contract_review_component.documents_signed")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("signup.contract_review_component.documents_remaining", {
            count: _ctx.remainingDocuments,
          })), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("signup.contract_review_component.data_review_title")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("signup.contract_review_component.data_review_subtext")), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.$t("signup.contract_review_component.data_signer_title")), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.signerFields, (field) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: field.label
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("label", _hoisted_9, _toDisplayString(field.label), 1),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(field.value), 1)
                  ])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("h5", _hoisted_12, _toDisplayString(_ctx.$t("signup.contract_review_component.data_company_title")), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyFields, (field) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: field.label
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("label", _hoisted_14, _toDisplayString(field.label), 1),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(field.value), 1)
                  ])
                ]))
              }), 128))
            ]),
            (_ctx.hasGenerator)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("h5", _hoisted_17, _toDisplayString(_ctx.$t("signup.contract_review_component.data_generator_title")), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generatorFields, (field) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: field.label
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("label", _hoisted_19, _toDisplayString(field.label), 1),
                        _createElementVNode("div", _hoisted_20, _toDisplayString(field.value), 1)
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])),
      _createElementVNode("h3", _hoisted_21, _toDisplayString(_ctx.$t("signup.contract_review_component.documents_review_title")), 1),
      _createElementVNode("div", null, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("signup.contract_review_component.documents_review_subtext")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractsToShow, (container, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "mb-4"
          }, [
            _createVNode(_component_SlottedContainerComponent, {
              buttonText: _ctx.$t('common.read_more_button'),
              expandable: _ctx.containerStates.length > 1
            }, {
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("h4", _hoisted_23, [
                    _createTextVNode(_toDisplayString(_ctx.$t("document.document_container")) + " ", 1),
                    (_ctx.containerStates.length > 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(index + 1), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    (
                    _ctx.containerStates[index].status === 'completed' &&
                    _ctx.containerStates[index].dokobit_token
                  )
                      ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                          _createVNode(_component_FontAwesomeIcon, { icon: "check" }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("document.signed")), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_27, [
                          _createVNode(_component_FontAwesomeIcon, { icon: "file-signature" }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("document.awaiting_signature")), 1)
                        ]))
                  ])
                ]),
                (_ctx.containerStates.length > 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                      (
                  _ctx.containerStates[index].status === 'completed' &&
                  _ctx.containerStates[index].dokobit_token
                )
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "btn btn-success",
                            onClick: ($event: any) => (_ctx.downloadDocument(_ctx.containerStates[index].dokobit_token))
                          }, _toDisplayString(_ctx.$t("document.download")), 9, _hoisted_29))
                        : (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            class: "btn btn-primary",
                            onClick: ($event: any) => (_ctx.startSigning(index))
                          }, _toDisplayString(_ctx.$t("signup.navsteps.to_signature_page")), 9, _hoisted_30))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              content: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(container.filter((c) => c.include || _ctx.isAdmin), (contract) => {
                  return (_openBlock(), _createBlock(_component_InfoCardComponent, {
                    key: contract.id,
                    buttonText: _ctx.$t('common.read_more_button'),
                    clickArea: _ctx.isAdmin ? 'button' : 'title',
                    containerClass: 'm-0 rounded-0 border-end-0 border-start-0'
                  }, {
                    title: _withCtx(() => [
                      (!_ctx.isAdmin)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                            _createVNode(_component_font_awesome_icon, { icon: "sheet-plastic" })
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.isAdmin)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                            _withDirectives(_createElementVNode("input", {
                              class: "form-check-input",
                              type: "checkbox",
                              "onUpdate:modelValue": ($event: any) => ((contract['include']) = $event),
                              disabled: !contract['can_include']
                            }, null, 8, _hoisted_33), [
                              [_vModelCheckbox, contract['include']]
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: _normalizeClass(["flex-1", {
                    'fw-bold text-body-emphasis': contract.include,
                    'text-secondary-light': !contract.include,
                  }])
                      }, _toDisplayString(contract.contract_title), 3)
                    ]),
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_34, _toDisplayString(contract.contract_description), 1)
                    ]),
                    _: 2
                  }, 1032, ["buttonText", "clickArea"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["buttonText", "expandable"])
          ]))
        }), 128))
      ])
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingOverlayComponent, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}