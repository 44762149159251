import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-md-8" }
const _hoisted_4 = { class: "card-body p-6 p-md-5" }
const _hoisted_5 = { class: "card-title text-center mb-4" }
const _hoisted_6 = { class: "text-center mb-5" }
const _hoisted_7 = { class: "d-flex flex-column gap-4" }
const _hoisted_8 = { class: "option-card" }
const _hoisted_9 = { class: "text-muted small" }
const _hoisted_10 = { class: "option-card" }
const _hoisted_11 = { class: "text-muted small" }
const _hoisted_12 = { class: "text-center mt-4 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t("signup.lt.authorization.title")), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("signup.lt.authorization.description")), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                class: "btn btn-lg btn-outline-primary w-100 mb-2",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addAuthorizationAgreementFlow()))
              }, _toDisplayString(_ctx.$t("signup.lt.authorization.add_authorization")), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("signup.lt.authorization.add_authorization_description")), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                class: "btn btn-lg btn-outline-primary w-100 mb-2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createNewAccountFlow()))
              }, _toDisplayString(_ctx.$t("signup.lt.authorization.create_new_account")), 1),
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("signup.lt.authorization.create_new_account_description")), 1)
            ])
          ]),
          _createElementVNode("p", _hoisted_12, [
            _createTextVNode(_toDisplayString(_ctx.$t("signup.lt.authorization.not_sure")) + " ", 1),
            _createElementVNode("a", {
              class: "btn btn-link btn-sm p-0 fw-normal",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openHelpCrunch && _ctx.openHelpCrunch(...args)))
            }, _toDisplayString(_ctx.$t("signup.lt.authorization.contact_support")), 1)
          ])
        ])
      ])
    ])
  ]))
}