<!-- client/src/views/admin/ForwardsView.vue -->

<template>
  <div id="ForwardsView" class="container">
    <div class="row justify-content-between align-items-center mb-3 mt-4">
      <div class="col-auto">
        <h1>Forwards</h1>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary" @click="navigateToAddForward">
          Add New Forward
        </button>
      </div>
    </div>
    <div class="table-container table-responsive">
      <table id="email-table" class="table table-hover align-middle p-2">
        <thead>
          <tr>
            <th>Name</th>
            <th>Delivery Start</th>
            <th>Delivery End</th>
            <th>Production Start</th>
            <th>Production End</th>
            <th>Buyer Name</th>
            <th>Units</th>
            <th>Reserved</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="forward in forwards" :key="forward.id">
            <td>{{ forward.name }}</td>
            <td>{{ forward.delivery_start }}</td>
            <td>{{ forward.delivery_end }}</td>
            <td>{{ forward.production_start }}</td>
            <td>{{ forward.production_end }}</td>
            <td>{{ forward.buyer_name }}</td>
            <td>{{ forward.count }}</td>
            <td>{{ forward.reserved_sum }}</td>
            <td>{{ forward.unit_price }}</td>

            <td>
              <button
                class="btn btn-primary btn-sm"
                @click="editForward(forward.id)"
              >
                Edit
              </button>
              <button
                class="btn btn-primary btn-sm"
                @click="editForwardReservations(forward.id)"
              >
                Manage Reservations
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref } from "vue";
import type { Forward } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "AdminForwardsView",

  setup() {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const forwards = ref<Forward[]>([]);
    const router = useRouter();

    const navigateToAddForward = () => {
      router.push({ name: "AdminForwardsEdit", params: { forward_id: -1 } });
    };
    const editForward = (id: number | undefined) => {
      router.push({ name: "AdminForwardsEdit", params: { forward_id: id } });
    };
    const editForwardReservations = (id: number | undefined) => {
      router.push({
        name: "AdminForwardReservationsView",
        params: { forward_id: id },
      });
    };

    onMounted(async () => {
      try {
        const response = await goSolidApi.get("/api/admin/forward");
        if (response.status === 200 && response.data) {
          forwards.value = response.data;
          console.log(forwards.value);
        } else {
          throw new Error("Error fetching forwards.");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    });

    return {
      forwards,
      navigateToAddForward,
      editForward,
      editForwardReservations,
    };
  },
});
</script>

<style scoped>
#AdminEmails {
  flex-grow: 1;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
}

.table-container {
  border-radius: 1rem;
  border: 1px solid var(--phoenix-gray-200);
  overflow: hidden !important;
  .table > tbody > tr > td:first-child {
    padding-left: 1rem;
  }
}

#email-table {
  margin-bottom: 0;
  .table-active {
    background-color: var(--phoenix-warning-bg-subtle);
    td {
      background-color: #fff3cd;
      box-shadow: none;
    }
  }
}
</style>
