<!-- client/src/components/signup/ContractReviewDisplayComponent.vue -->

<template>
  <div class="card p-4 mb-3">
    <div v-if="hasSignedDocuments">
      <h3 class="mb-3">
        {{ $t("signup.contract_review_component.documents_signed") }}
      </h3>
      <p>
        {{
          $t("signup.contract_review_component.documents_remaining", {
            count: remainingDocuments,
          })
        }}
      </p>
    </div>
    <div v-else>
      <h3 class="mb-1 mb-sm-2">
        {{ $t("signup.contract_review_component.data_review_title") }}
      </h3>
      <p>
        {{ $t("signup.contract_review_component.data_review_subtext") }}
      </p>
      <!-- Signatory data card -->
      <div class="card p-3 mb-3">
        <h5 class="mb-1 mb-sm-2">
          {{ $t("signup.contract_review_component.data_signer_title") }}
        </h5>
        <div v-for="field in signerFields" :key="field.label">
          <div class="row g-2 py-1">
            <label class="col-sm-3 col-form-label py-0">{{
              field.label
            }}</label>
            <div class="col-sm-8 col-10 py-0">{{ field.value }}</div>
          </div>
        </div>
      </div>
      <!-- Signatory data card -->
      <div class="card p-3 mb-3">
        <h5 class="mb-1 mb-sm-2">
          {{ $t("signup.contract_review_component.data_company_title") }}
        </h5>
        <div v-for="field in companyFields" :key="field.label">
          <div class="row g-2 py-1">
            <label class="col-sm-3 col-form-label py-0">{{
              field.label
            }}</label>
            <div class="col-sm-8 col-10 py-0">{{ field.value }}</div>
          </div>
        </div>
      </div>
      <!-- Generator data card -->
      <div class="card p-3 mb-3" v-if="hasGenerator">
        <h5 class="mb-1 mb-sm-2">
          {{ $t("signup.contract_review_component.data_generator_title") }}
        </h5>
        <div v-for="field in generatorFields" :key="field.label">
          <div class="row g-2 py-1">
            <label class="col-sm-3 col-form-label py-0">{{
              field.label
            }}</label>
            <div class="col-sm-8 col-10 py-0" style="white-space: pre-line">
              {{ field.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3 class="mb-1 mb-sm-4 mt-4">
      {{ $t("signup.contract_review_component.documents_review_title") }}
    </h3>
    <div>
      <p>
        {{ $t("signup.contract_review_component.documents_review_subtext") }}
      </p>

      <div
        v-for="(container, index) in contractsToShow"
        :key="index"
        class="mb-4"
      >
        <SlottedContainerComponent
          :buttonText="$t('common.read_more_button')"
          :expandable="containerStates.length > 1"
        >
          <template #title>
            <div
              class="mb-2 mb-md-0 d-flex flex-column justify-content-center justify-content-md-start"
            >
              <h4 class="mb-0">
                {{ $t("document.document_container") }}
                <span v-if="containerStates.length > 1">{{ index + 1 }}</span>
              </h4>
              <!-- Signing completed badge -->
              <div class="text-center text-md-start">
                <span
                  v-if="
                    containerStates[index].status === 'completed' &&
                    containerStates[index].dokobit_token
                  "
                  class="badge badge-phoenix badge-phoenix-success fs-10 p-1 px-3 mt-2"
                >
                  <FontAwesomeIcon icon="check" />
                  {{ $t("document.signed") }}
                </span>
                <!-- Awaiting signing badge -->
                <span
                  v-else
                  class="badge badge-phoenix badge-phoenix-primary fs-10 p-1 px-3 mt-2"
                >
                  <FontAwesomeIcon icon="file-signature" />
                  {{ $t("document.awaiting_signature") }}
                </span>
              </div>
            </div>
            <!-- Signing completed button-->
            <div v-if="containerStates.length > 1">
              <button
                class="btn btn-success"
                v-if="
                  containerStates[index].status === 'completed' &&
                  containerStates[index].dokobit_token
                "
                @click="downloadDocument(containerStates[index].dokobit_token)"
              >
                {{ $t("document.download") }}
              </button>
              <!-- Start signing button -->
              <button
                v-else
                class="btn btn-primary"
                @click="startSigning(index)"
              >
                {{ $t("signup.navsteps.to_signature_page") }}
              </button>
            </div>
          </template>
          <template #content>
            <InfoCardComponent
              v-for="contract in container.filter((c) => c.include || isAdmin)"
              :key="contract.id"
              :buttonText="$t('common.read_more_button')"
              :clickArea="isAdmin ? 'button' : 'title'"
              :containerClass="'m-0 rounded-0 border-end-0 border-start-0'"
            >
              <template #title>
                <div class="avatar avatar-x1 me-2" v-if="!isAdmin">
                  <font-awesome-icon icon="sheet-plastic" />
                </div>
                <div class="form-check admin-checkbox" v-if="isAdmin">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="contract['include']"
                    :disabled="!contract['can_include']"
                  />
                </div>
                <div
                  class="flex-1"
                  :class="{
                    'fw-bold text-body-emphasis': contract.include,
                    'text-secondary-light': !contract.include,
                  }"
                >
                  {{ contract.contract_title }}
                </div>
              </template>
              <template #content>
                <div class="text-body-secondary">
                  {{ contract.contract_description }}
                </div>
              </template>
            </InfoCardComponent>
          </template>
        </SlottedContainerComponent>
      </div>
    </div>
  </div>
  <LoadingOverlayComponent v-if="isLoading" />
</template>

<script lang="ts">
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { Account, Document } from "@/types";
import {
  defineComponent,
  ref,
  computed,
  inject,
  onMounted,
  PropType,
} from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import InfoCardComponent from "@/components/common/InfoCardComponent.vue";
import SlottedContainerComponent from "@/components/common/SlottedContainerComponent.vue";
import LoadingOverlayComponent from "@/components/common/LoadingOverlayComponent.vue";
import { useDownloadDocument } from "@/composables/useDownloadDocument";

interface Contract {
  id: number;
  key: string;
  contract_title: string;
  contract_description: string;
  include: boolean;
  can_include: boolean;
}

interface ContainerState {
  status: string | null;
  download_url: string | null;
  signing_url: string | null;
  dokobit_token: string | null;
}

interface MatchedDocument extends Document {
  matchCount: number;
}

export default defineComponent({
  name: "ContractReviewDisplayComponent",
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    setLoading: {
      type: Function as PropType<(value: boolean) => void>,
      required: true,
    },
    contractsToShow: {
      type: Array as PropType<Contract[][]>,
      required: true,
    },
    signerFields: {
      type: Array as PropType<{ label: string; value: string | null }[]>,
      required: true,
    },
    companyFields: {
      type: Array as PropType<{ label: string; value: string | null }[]>,
      required: true,
    },
    generatorFields: {
      type: Array as PropType<
        { label: string; value: string | number | null | undefined }[]
      >,
      required: true,
    },
    hasGenerator: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    InfoCardComponent,
    SlottedContainerComponent,
    LoadingOverlayComponent,
  },
  emits: ["createContainerForStartSigningReady", "disableChangingInformation"],
  setup(props, { emit }) {
    const { downloadDocument } = useDownloadDocument();
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const isAdmin = !!props.accountData.admin_account_id;
    const { companyIndex } = useCompanyFinder(props.accountData);
    const selectedCompany = computed(
      () => props.accountData.companies[companyIndex.value]
    );
    const { nullableSelectedGenerator } = useGeneratorFinder(props.accountData);
    const containerStates = ref<ContainerState[]>(
      props.contractsToShow.map(() => ({
        status: null,
        download_url: null,
        signing_url: null,
        dokobit_token: null,
      }))
    );
    const isLoading = ref(true);

    const hasSignedDocuments = ref(false);
    const remainingDocuments = ref(0);

    const createNextContainerForSigning = async () => {
      startSigning(0);
    };

    const getCleanedRedirectUri = () => {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete("signing_token");
      currentUrl.searchParams.delete("signer_token");
      if (isAdmin) {
        currentUrl.searchParams.set("is_admin_doc", "true");
      }
      return currentUrl.toString();
    };

    const startSigning = async (container_index: number) => {
      await _createAllContainersForSigning();
      // Assumes passed index is valid
      const signing_url = containerStates.value[container_index].signing_url;
      if (signing_url) {
        window.location.href = signing_url;
      }
    };

    const _createAllContainersForSigning = async () => {
      isLoading.value = true;
      try {
        for (let i = 0; i < containerStates.value.length; i++) {
          const state = containerStates.value[i];
          if (!state.download_url && !state.signing_url) {
            await _createContainerForSigning(i);
          }
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      } finally {
        isLoading.value = false;
      }
    };

    const _createContainerForSigning = async (containerIndex: number) => {
      try {
        const contracts_in_container_keys = props.contractsToShow[
          containerIndex
        ]
          .filter((contract: Contract) => contract.include)
          .map((contract: Contract) => contract.key);
        let url = `/api/document-create-container-for-signing`;

        const response = await goSolidApi.post(url, {
          contracts_in_container: contracts_in_container_keys,
          generator_id: nullableSelectedGenerator?.value?.generator?.id,
          company_id: selectedCompany?.value?.id,
          source_redirect_uri: getCleanedRedirectUri(),
        });
        if (response.data.signing_url) {
          containerStates.value[containerIndex].signing_url =
            response.data.signing_url;
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const checkSignature = async () => {
      try {
        const queryString = getQueryString();

        const urlParams = new URLSearchParams(queryString);

        const { documents, redirect_url, accountData } =
          await fetchSignatureStatus(queryString);

        // if it was document set up by admin, we are redirected to dashboard to avoid getting stuck
        if (urlParams.get("is_admin_doc") === "true") {
          const companyId = selectedCompany.value?.id;
          window.location.href = `/dashboard?company_id=${companyId}`;
          return;
        }

        // Admins should be able to add new documents for signing
        if (isAdmin) {
          return;
        }
        const matchedContainers = _matchContainersToDocuments(
          props.contractsToShow,
          documents
        );
        _updateContainerStates(matchedContainers);
        _updateDocumentStats(documents);

        if (hasSignedDocuments.value) {
          emit("disableChangingInformation");
        }

        const allCompleted = _checkAllCompleted(matchedContainers);

        if (allCompleted) {
          _sendGtagEvent(accountData);
          window.location.href = redirect_url;
        }
      } catch (error: any) {
        globalErrorHandler(error);
      }
    };

    const getQueryString = () => {
      const params = new URLSearchParams(window.location.search);
      const uniqueParams: Record<string, string> = {};

      for (const [key, value] of params.entries()) {
        uniqueParams[key] = value;
      }

      return Object.entries(uniqueParams)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
    };

    const fetchSignatureStatus = async (queryString: string) => {
      const response = await goSolidApi.get(
        `/api/document-check-signature?${queryString}`
      );
      if (response.status !== 200) {
        throw new Error("Error checking signature");
      }
      return response.data;
    };

    const _matchContainersToDocuments = (
      contractsToShow: Contract[][],
      documents: Document[]
    ) => {
      return contractsToShow.map((container) => {
        const matchedDocument = documents.reduce(
          (best: MatchedDocument | null, doc: Document) => {
            const matchCount = doc.contract_keys.filter((key: string) =>
              container.some((contract) => contract.key === key)
            ).length;
            return matchCount > (best?.matchCount || 0)
              ? { ...doc, matchCount }
              : best;
          },
          null as MatchedDocument | null
        );
        return { container, matchedDocument };
      });
    };

    const _updateContainerStates = (
      matchedContainers: {
        container: Contract[];
        matchedDocument: MatchedDocument | null;
      }[]
    ) => {
      matchedContainers.forEach(({ matchedDocument }, index) => {
        if (matchedDocument) {
          containerStates.value[index] = {
            ...containerStates.value[index],
            status: matchedDocument.status,
            download_url: matchedDocument.download_url,
            signing_url: matchedDocument.signing_url,
            dokobit_token: matchedDocument.dokobit_token,
          };
        }
      });
    };

    const _updateDocumentStats = (documents: Document[]) => {
      const signedDocuments = documents.filter(
        (doc: Document) => doc.status === "completed"
      );
      hasSignedDocuments.value = signedDocuments.length > 0;
      remainingDocuments.value = documents.length - signedDocuments.length;
    };

    const _checkAllCompleted = (
      matchedContainers: {
        container: Contract[];
        matchedDocument: MatchedDocument | null;
      }[]
    ) => {
      return matchedContainers.every(
        ({ matchedDocument }) =>
          matchedDocument && matchedDocument.status === "completed"
      );
    };

    const _sendGtagEvent = (accountData: any) => {
      window.gtag("event", "manual_event_SIGNUP", {
        send_to: "G-MRWP1QB2GY",
        user_data: {
          id: accountData.id,
          email: accountData.email,
          phone_number: accountData.phone_number,
          first_name: accountData.name,
          value: accountData.value,
          currency: "EUR",
        },
      });
    };

    onMounted(async () => {
      isLoading.value = true;
      emit(
        "createContainerForStartSigningReady",
        createNextContainerForSigning
      );
      await checkSignature();
      isLoading.value = false;
    });

    return {
      isLoading,
      isAdmin,
      downloadDocument,
      containerStates,
      hasSignedDocuments,
      remainingDocuments,
      startSigning,
    };
  },
});
</script>

<style scoped lang="scss">
.contact-review {
  .col-form-label {
    text-align: left;
    font-weight: bold;
  }
  h4 {
    color: #007bff;
  }
  .btn-outline-secondary {
    padding: 0.375rem 0.75rem;
  }
}
.admin-checkbox {
  padding: 0.5rem;
  border: 3px dashed var(--phoenix-warning-border-subtle);
  display: flex;
  background-color: var(--phoenix-warning-bg-subtle);
  margin: 0;
  margin-right: 0.5rem;
  input {
    margin: 0;
    &:checked {
      background-color: var(--phoenix-warning-text-emphasis);
      border: 3px solid var(--phoenix-warning-border);
    }
  }
}
</style>
