import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container py-5 pt-3 pt-xl-5" }
const _hoisted_2 = { class: "d-flex justify-content-between w-100" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "text-body-tertiary lh-lg" }
const _hoisted_7 = {
  class: "link",
  role: "button",
  href: "https://www.soldera.org/#faq",
  target: "blank"
}
const _hoisted_8 = { class: "text-body-tertiary lh-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressNavComponent = _resolveComponent("ProgressNavComponent")!
  const _component_CountryPickerComponent = _resolveComponent("CountryPickerComponent")!
  const _component_ContactReviewConfigurationComponent = _resolveComponent("ContactReviewConfigurationComponent")!
  const _component_ContractReviewConfigurationComponent = _resolveComponent("ContractReviewConfigurationComponent")!
  const _component_LoadingOverlayComponent = _resolveComponent("LoadingOverlayComponent")!
  const _component_AccountDataDebugComponent = _resolveComponent("AccountDataDebugComponent")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ProgressNavComponent, {
        steps: _ctx.navSteps,
        setStep: _ctx.setStep
      }, null, 8, ["steps", "setStep"]),
      (_ctx.signupStep === 1)
        ? (_openBlock(), _createBlock(_component_CountryPickerComponent, {
            key: 0,
            accountData: _ctx.accountData,
            handleUpdateAccountData: _ctx.handleUpdateAccountData
          }, null, 8, ["accountData", "handleUpdateAccountData"]))
        : _createCommentVNode("", true),
      (_ctx.shownStep.component === 'contact-review' && _ctx.companyIndex !== -1)
        ? (_openBlock(), _createBlock(_component_ContactReviewConfigurationComponent, {
            key: 1,
            accountData: _ctx.accountData,
            companyIndex: _ctx.companyIndex,
            onHandleUpdateCompany: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleUpdateCompany', $event))),
            onUpdateAccountData: _ctx.handleUpdateAccountData,
            class: "mb-4"
          }, null, 8, ["accountData", "companyIndex", "onUpdateAccountData"]))
        : _createCommentVNode("", true),
      (_ctx.shownStep.component === 'contract-review' && _ctx.companyIndex !== -1)
        ? (_openBlock(), _createBlock(_component_ContractReviewConfigurationComponent, {
            key: 2,
            accountData: _ctx.accountData,
            setLoading: _ctx.setLoading,
            onCreateContainerForStartSigningReady: _ctx.storeCreateContainerForStartSigning,
            class: "mb-4"
          }, null, 8, ["accountData", "setLoading", "onCreateContainerForStartSigningReady"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "btn btn-secondary me-2",
          disabled: _ctx.signupStep === 1 || _ctx.isLoading,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setStep(_ctx.signupStep - 1)))
        }, _toDisplayString(_ctx.$t("signup.navsteps.back")), 9, _hoisted_3),
        (_ctx.shownStep.component !== 'contract-review')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary",
              disabled: _ctx.isLoading,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setStep(_ctx.signupStep + 1)))
            }, _toDisplayString(_ctx.$t("signup.navsteps.next")), 9, _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.shownStep.component === 'contract-review')
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-primary",
              disabled: _ctx.isLoading,
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.createContainerForStartSigningFunction && _ctx.createContainerForStartSigningFunction(...args)))
            }, _toDisplayString(_ctx.$t("signup.navsteps.to_signature_page")), 9, _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingOverlayComponent, { key: 3 }))
        : _createCommentVNode("", true),
      _createVNode(_component_AccountDataDebugComponent, {
        accountData: _ctx.accountData,
        class: "mb-4"
      }, null, 8, ["accountData"])
    ]),
    _createVNode(_component_ModalComponent, {
      submitButtonText: _ctx.$t('signup.modal.hello_button'),
      onSetForceToggle: _ctx.setToggleHelloModal
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("signup.modal.hello")), 1)
      ]),
      body: _withCtx(() => [
        _createElementVNode("p", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.$t("signup.modal.lv.section1")) + " ", 1),
          _createElementVNode("a", _hoisted_7, [
            _createVNode(_component_font_awesome_icon, { icon: "right-to-bracket" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("signup.modal.soldera_faq")), 1)
          ])
        ]),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("signup.modal.lv.section2")), 1)
      ]),
      _: 1
    }, 8, ["submitButtonText", "onSetForceToggle"])
  ], 64))
}