<!-- client/src/components/signup/lt/ContactReviewConfigurationComponent.vue -->

<template>
  <ContactReviewDisplayComponent
    :accountData="accountData"
    :companyIndex="companyIndex"
    :editableAccountFields="accountFields"
    :editableCompanyFields="companyFields"
    :shouldShowCompanyFields="shouldShowCompanyFields"
    :existingCompanies="existingCompanies"
    @updateAccountData="$emit('updateAccountData', $event)"
    @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
    @selectExistingCompany="$emit('selectExistingCompany', $event)"
    @resetCompanySelection="$emit('resetCompanySelection')"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import type { Account, ContactFieldType } from "@/types";
import { useValidators } from "@/composables/useValidators";
import { useI18n } from "vue-i18n";
import ContactReviewDisplayComponent from "@/components/signup/ContactReviewDisplayComponent.vue";

export default defineComponent({
  name: "ContactReviewConfigurationComponent",
  components: {
    ContactReviewDisplayComponent,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: false,
    },
  },
  emits: [
    "updateAccountData",
    "handleUpdateCompany",
    "selectExistingCompany",
    "resetCompanySelection",
  ],
  setup(props) {
    const { t } = useI18n();
    const { required, lithuanianCompanyNumber, personalName, phoneNumber } =
      useValidators(t);

    const accountFields: ContactFieldType[] = [
      {
        label: t("account.name"),
        id: "account-name",
        model: "name",
        type: "text",
        editable: true,
        validators: { required, personalName },
      },
      {
        label: t("account.phone_number"),
        id: "account-phone-number",
        model: "phone_number",
        type: "tel",
        editable: true,
        validators: { required, phoneNumber },
      },
    ];

    const companyFields: ContactFieldType[] = [
      {
        label: t("company.name"),
        id: "company-name",
        model: "name",
        type: "text",
        editable: true,
        validators: { required },
      },
      {
        label: t("company.registry_code"),
        id: "company-registry-code",
        model: "registry_code",
        type: "text",
        editable: true,
        validators: [required, lithuanianCompanyNumber],
      },
      {
        label: t("company.address"),
        id: "company-address",
        model: "address",
        type: "text",
        editable: true,
        validators: { required },
      },
    ];

    const shouldShowCompanyFields = computed(() => {
      // TODO: If we add private individuals, need to add some logic back in here
      return true;
    });

    // Only shows saved Lithuanian companies with documents in the dropdown
    const existingCompanies = computed(() => {
      return props.accountData.companies.filter(
        (company) =>
          company.id !== undefined &&
          company.country === "LT" &&
          company.documents.length > 0
      );
    });

    return {
      accountFields,
      companyFields,
      shouldShowCompanyFields,
      existingCompanies,
    };
  },
});
</script>

<style scoped></style>
