import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09852f0d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-2 invoice-item-display px-1 g-0" }
const _hoisted_2 = { class: "col-12 col-lg-4 px-1" }
const _hoisted_3 = { class: "mb-0 fw-bold cropped-until-hover" }
const _hoisted_4 = { class: "col-12 col-lg-8 row g-0 text-start text-lg-end m-0" }
const _hoisted_5 = { class: "col-6 col-lg-3 px-1 py-1 py-lg-0" }
const _hoisted_6 = { class: "mb-0 d-lg-none" }
const _hoisted_7 = { class: "mb-0" }
const _hoisted_8 = { class: "col-6 col-lg-3 px-1 py-1 py-lg-0" }
const _hoisted_9 = { class: "mb-0 d-lg-none" }
const _hoisted_10 = { class: "mb-0" }
const _hoisted_11 = { class: "col-6 col-lg-3 px-1 py-1 py-lg-0" }
const _hoisted_12 = { class: "mb-0 d-lg-none" }
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = { class: "col-6 col-lg-3 px-1 py-1 py-lg-0" }
const _hoisted_15 = { class: "mb-0 d-lg-none" }
const _hoisted_16 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.invoiceItem.description), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("invoiceItem.quantity")), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.invoiceItem.amount), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("invoiceItem.unit_price")), 1),
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.formatMoney(-_ctx.invoiceItem.unit_price)) + " €", 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("invoiceItem.total_excl_vat")), 1),
        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.formatMoney(-_ctx.invoiceItem.unit_price * _ctx.invoiceItem.amount)) + " € ", 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("invoiceItem.vat")), 1),
        _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.formatMoney(
              -_ctx.invoiceItem.unit_price *
                _ctx.invoiceItem.amount *
                (_ctx.invoiceItem.vat_fraction || 0)
            )) + " € ", 1)
      ])
    ])
  ]))
}