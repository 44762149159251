import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "avatar avatar-x1 me-2" }
const _hoisted_2 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_InfoCardComponent = _resolveComponent("InfoCardComponent")!

  return (_openBlock(), _createBlock(_component_InfoCardComponent, {
    buttonText: _ctx.buttonText,
    onSetForceToggle: _ctx.setForceToggle
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, {
          icon: _ctx.icon,
          class: "text-secondary-emphasis"
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.titleText), 1)
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["buttonText", "onSetForceToggle"]))
}