import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractReviewDisplayComponent = _resolveComponent("ContractReviewDisplayComponent")!

  return (_openBlock(), _createBlock(_component_ContractReviewDisplayComponent, {
    accountData: _ctx.accountData,
    contractsToShow: _ctx.contracts,
    setLoading: _ctx.setLoading,
    signerFields: _ctx.signerFields,
    companyFields: _ctx.companyFields,
    generatorFields: _ctx.generatorFields,
    contractState: _ctx.contractState,
    hasGenerator: _ctx.hasGenerator,
    onCreateContainerForStartSigningReady: _cache[0] || (_cache[0] = ($event: any) => (
      _ctx.$emit('createContainerForStartSigningReady', $event)
    ))
  }, null, 8, ["accountData", "contractsToShow", "setLoading", "signerFields", "companyFields", "generatorFields", "contractState", "hasGenerator"]))
}