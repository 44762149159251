import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container py-5 pt-3 pt-xl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountryPickerComponent = _resolveComponent("CountryPickerComponent")!
  const _component_LoadingOverlayComponent = _resolveComponent("LoadingOverlayComponent")!
  const _component_AccountDataDebugComponent = _resolveComponent("AccountDataDebugComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CountryPickerComponent, {
      accountData: _ctx.accountData,
      handleUpdateAccountData: _ctx.handleUpdateAccountData
    }, null, 8, ["accountData", "handleUpdateAccountData"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingOverlayComponent, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_AccountDataDebugComponent, {
      accountData: _ctx.accountData,
      class: "mb-4"
    }, null, 8, ["accountData"])
  ]))
}