<!-- src/components/charts/StackedBarChart.vue -->
<template>
  <div class="chart-wrapper">
    <canvas ref="chartRef" :height="height"></canvas>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, PropType } from "vue";
import { Chart, ChartConfiguration } from "chart.js/auto";

interface Dataset {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}

export default defineComponent({
  name: "StackedBarChart",
  props: {
    chartData: {
      type: Object as PropType<{
        labels: string[];
        datasets: Dataset[];
      }>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    yAxisTitle: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 190,
    },
  },
  setup(props) {
    const chartRef = ref<HTMLCanvasElement | null>(null);
    let chart: Chart<any> | null = null;

    const createChart = () => {
      if (chartRef.value) {
        const ctx = chartRef.value.getContext("2d");
        if (ctx) {
          const config: ChartConfiguration = {
            type: "bar",
            data: props.chartData,
            options: {
              plugins: {
                title: {
                  display: true,
                  text: props.title,
                  font: {
                    size: 16,
                    family: "Nunito Sans",
                  },
                  padding: {
                    top: 5,
                    bottom: 0,
                  },
                },
                subtitle: {
                  display: !!props.subtitle,
                  text: props.subtitle,
                  font: {
                    size: 14,
                    style: "italic",
                    family: "Nunito Sans",
                  },
                  padding: {
                    top: 0,
                    bottom: 0,
                  },
                },
              },
              scales: {
                x: { stacked: true },
                y: {
                  stacked: true,
                  beginAtZero: true,
                  title: { display: true, text: props.yAxisTitle },
                },
              },
              responsive: true,
              maintainAspectRatio: false,
            },
          };

          chart = new Chart(ctx, config);
        }
      }
    };

    const updateChart = () => {
      if (chart) {
        chart.data = props.chartData;
        chart.update();
      }
    };

    onMounted(() => {
      createChart();
    });

    watch(() => props.chartData, updateChart, { deep: true });

    return { chartRef };
  },
});
</script>

<style scoped>
.chart-wrapper {
  position: relative;
  margin-bottom: 15px;
}
</style>
