import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row mb-2 g-2" }
const _hoisted_2 = {
  for: "companySelect",
  class: "col-sm-3 mt-3 mt-sm-2 col-form-label"
}
const _hoisted_3 = { class: "col-sm-8 col-10" }
const _hoisted_4 = { value: "" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "d-flex align-items-center" }
const _hoisted_8 = { class: "me-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("signup.contact_review_component.select_company")), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showCompanyDropdown)
        ? (_openBlock(), _createElementBlock("select", {
            key: 0,
            id: "companySelect",
            class: "form-select",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCompanySelect && _ctx.onCompanySelect(...args)))
          }, [
            _createElementVNode("option", _hoisted_4, _toDisplayString(_ctx.$t("signup.contact_review_component.add_new_company")), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.existingCompanies, (company) => {
              return (_openBlock(), _createElementBlock("option", {
                key: company.id,
                value: company.id
              }, _toDisplayString(company.name) + " (" + _toDisplayString(company.registry_code) + ") ", 9, _hoisted_5))
            }), 128))
          ], 32))
        : (_ctx.selectedCompany && _ctx.isExistingCompanyWithDocuments)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.selectedCompany.name) + " (" + _toDisplayString(_ctx.selectedCompany.registry_code) + ") ", 1),
                _createElementVNode("button", {
                  class: "btn btn-link btn-sm m-0 p-1",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetCompanySelection && _ctx.resetCompanySelection(...args))),
                  title: "Reset company selection"
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "rotate-left" })
                ])
              ])
            ]))
          : _createCommentVNode("", true)
    ])
  ]))
}