// src/composables/useCompanyFinder.ts

import { computed } from "vue";
import { useRoute } from "vue-router";
import type { Account, Company } from "@/types";

export function useCompanyFinder(accountData: Account) {
  const route = useRoute();

  const companyIdQueryString = computed(() => {
    return parseInt(route.query.company_id as string);
  });

  const selectedCompany = computed(() => {
    if (
      accountData &&
      accountData.companies &&
      accountData.companies[companyIndex.value]
    ) {
      return accountData.companies[companyIndex.value];
    }
    throw new Error("Company not found");
  });

  const companyIndex = computed(() => {
    if (companyIdQueryString.value) {
      return accountData.companies.findIndex(
        (company) => company.id === companyIdQueryString.value
      );
    } else {
      return findCompanyIndexWithoutId.value;
    }
  });

  const selectedCompanyOrFirst = computed(() => {
    if (
      accountData &&
      accountData.companies &&
      accountData.companies[companyIndexOrFirst.value]
    ) {
      return accountData.companies[companyIndexOrFirst.value];
    }
    return null;
  });

  const companyIndexOrFirst = computed(() => {
    if (companyIdQueryString.value) {
      return accountData.companies.findIndex(
        (company) => company.id === companyIdQueryString.value
      );
    } else {
      return 0;
    }
  });

  const findCompanyIndexWithoutId = computed(() => {
    return accountData.companies.findIndex(
      (company) => company.id === undefined || company.id === null
    );
  });

  const createCompanyIfMissing = async () => {
    if (findCompanyIndexWithoutId.value === -1) {
      const new_company = {
        id: undefined,
        name: "",
        address: "",
        registry_code: "",
        has_registered: false,
        generators: [],
        documents: [],
        registry_devices: [],
        min_6_month_price: 0,
        vat: null,
        bank_account: null,
        payment_header: "",
        fee_model: "",
        registry_excluded_devices: [],
        is_test_company: false,
        country: route.query.country,
        owner: {
          id: accountData.id,
          email: accountData.email,
          name: accountData.name,
          registry_code: accountData.registry_code,
          phone_number: accountData.phone_number,
          has_registered: accountData.has_registered,
          session_id: accountData.session_id,
          kilowatts: accountData.kilowatts,
          go_price: accountData.go_price,
          companies: [],
          trackings: [],
          internal_role: accountData.internal_role,
        } as Account,
      } as Company;
      accountData.companies.push(new_company);
    }
  };

  const removeEmptyCompanyIfExists = () => {
    const emptyCompanyIndex = findCompanyIndexWithoutId.value;
    if (emptyCompanyIndex !== -1) {
      accountData.companies.splice(emptyCompanyIndex, 1);
    }
  };

  return {
    companyIdQueryString,
    companyIndex,
    companyIndexOrFirst,
    findCompanyIndexWithoutId,
    selectedCompany,
    selectedCompanyOrFirst,
    createCompanyIfMissing,
    removeEmptyCompanyIfExists,
  };
}
