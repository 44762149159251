import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6985a3f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["border p-3 mb-3 rounded shadow file-card", { 'cursor-pointer': _ctx.url }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("h5", _hoisted_1, [
      _createVNode(_component_font_awesome_icon, {
        icon: _ctx.cardType === 'link' ? 'link' : 'file-arrow-down',
        class: "me-1 text-primary-emphasis"
      }, null, 8, ["icon"]),
      _renderSlot(_ctx.$slots, "title", {}, undefined, true)
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "info", {}, undefined, true)
    ])
  ], 2))
}