<template>
  <div class="container text-center mt-5">
    <loading-overlay-component />
  </div>
</template>

<script lang="ts">
import { goSolidApi } from "@/composables/useGoSolidApi";
import LoadingOverlayComponent from "@/components/common/LoadingOverlayComponent.vue";
import { inject, defineComponent, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

// This page should be deleted, but it's here for legacy purposes in case anybody has unsigned documents in the dokobit
// and will be redirected here
// 16 August 2024, delete after 1st of january 2025
export default defineComponent({
  name: "CheckSignatureView",
  components: {
    LoadingOverlayComponent,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;

    const checkSignature = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const queryString = Array.from(params.entries())
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");
        const response = await goSolidApi.get(
          `/api/document-check-signature?${queryString}`
        );
        if (response.status === 200 && response.data.redirect_url) {
          if (!response.data.accountData.has_registered) {
            window.gtag("event", "manual_event_SIGNUP", {
              send_to: "G-MRWP1QB2GY",
              user_data: {
                id: response.data.accountData.id,
                email: response.data.accountData.email,
                phone_number: response.data.accountData.phone_number,
                first_name: response.data.accountData.name,
                value: response.data.accountData.value,
                currency: "EUR",
              },
            });
          }
          window.location.href = response.data.redirect_url;
        } else {
          throw new Error("Error checking signature");
        }
      } catch (error: any) {
        const newQuery = {
          ...route.query,
        };
        router.push({ name: "Dashboard", query: newQuery });
        globalErrorHandler(error);
      }
    };

    onMounted(async () => {
      checkSignature();
    });
    return {};
  },
});
</script>
