<!-- client/src/components/signup/ChooseOnboardingComponent.vue -->

<template>
  <div class="card">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card-body p-6 p-md-5">
          <h2 class="card-title text-center mb-4">
            {{ $t("signup.lt.authorization.title") }}
          </h2>
          <p class="text-center mb-5">
            {{ $t("signup.lt.authorization.description") }}
          </p>

          <div class="d-flex flex-column gap-4">
            <div class="option-card">
              <button
                class="btn btn-lg btn-outline-primary w-100 mb-2"
                @click="addAuthorizationAgreementFlow()"
              >
                {{ $t("signup.lt.authorization.add_authorization") }}
              </button>
              <p class="text-muted small">
                {{
                  $t("signup.lt.authorization.add_authorization_description")
                }}
              </p>
            </div>

            <div class="option-card">
              <button
                class="btn btn-lg btn-outline-primary w-100 mb-2"
                @click="createNewAccountFlow()"
              >
                {{ $t("signup.lt.authorization.create_new_account") }}
              </button>
              <p class="text-muted small">
                {{
                  $t("signup.lt.authorization.create_new_account_description")
                }}
              </p>
            </div>
          </div>

          <p class="text-center mt-4 small text-muted">
            {{ $t("signup.lt.authorization.not_sure") }}
            <a
              class="btn btn-link btn-sm p-0 fw-normal"
              @click="openHelpCrunch"
              >{{ $t("signup.lt.authorization.contact_support") }}</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useHelpCrunch } from "@/composables/useHelpCrunch";

export default defineComponent({
  props: {
    addAuthorizationAgreementFlow: {
      type: Function as PropType<() => void>,
      required: true,
    },
    createNewAccountFlow: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup() {
    const { openHelpCrunch } = useHelpCrunch();
    return { openHelpCrunch };
  },
});
</script>

<style scoped lang="scss"></style>
