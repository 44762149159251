<template>
  <div class="card p-1" v-if="isInitialized">
    <div class="card-body">
      <h4 class="mb-3 fs-6">{{ $t("dashboard.forward_overview.title") }}</h4>

      <div class="row g-3 mb-3">
        <div class="col-md-4">
          <div class="bg-light rounded p-3 h-100 d-flex flex-column">
            <p class="mb-1 fw-semibold text-muted">
              {{ $t("dashboard.forward_overview.upcoming_deliveries") }}
            </p>
            <h3 class="mb-0 fs-6 lh-sm mt-auto">
              {{ totalCommittedVolume }} {{ $t("common.mwh") }}
            </h3>
          </div>
        </div>
        <div class="col-md-4">
          <div class="bg-light rounded p-3 h-100 d-flex flex-column">
            <p class="mb-1 fw-semibold text-muted">
              {{ $t("dashboard.forward_overview.upcoming_sales_value") }}
            </p>
            <h3 class="mb-0 fs-6 lh-sm mt-auto">
              {{ formatMoney(totalCommitmentValue) }} {{ $t("common.eur") }}
            </h3>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rounded p-3 bg-primary-subtle h-100 d-flex flex-column">
            <p class="mb-1 fw-bold text-primary-emphasis">
              {{ $t("dashboard.forward_overview.service") }}
            </p>
            <div class="mt-auto">
              <button
                v-if="!forwardServiceInstructions"
                class="btn btn-primary btn-lg w-100"
                @click="showServiceInstructions = !showServiceInstructions"
              >
                {{ $t("common.read_more_button") }}
                <FontAwesomeIcon icon="arrow-right" class="ms-1" />
              </button>
              <button
                v-else
                class="btn btn-primary btn-lg w-100"
                @click="showServiceInstructions = !showServiceInstructions"
              >
                {{ $t("common.edit") }}
                <FontAwesomeIcon icon="pen" class="ms-1" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <SetForwardSalesComponent
        v-if="solarProjection && windProjection && showServiceInstructions"
        :key="forwardServiceInstructionsKey"
        :company="company"
        :forwardSales="forwardSales"
        :solarProjection="solarProjection"
        :windProjection="windProjection"
        :forwardServiceInstructions="forwardServiceInstructions"
        @update-service-instructions="handleUpdateServiceInstructions"
      />

      <div
        v-if="solarProjection && windProjection && !showServiceInstructions"
        class="row mb-2"
      >
        <ProductionProjectionBarChart
          :solarProjection="solarProjection"
          :windProjection="windProjection"
          class="col-md-6"
        />
        <ProductionSalesStatusBarChart
          :forwardSaleAmounts="forwardSaleAmountsFromInstructions"
          :solarProjection="solarProjection"
          :windProjection="windProjection"
          :forwardSoldAmounts="quarterlySales2025"
          class="col-md-6"
        />
      </div>

      <div
        class="border rounded-2 overflow-hidden"
        v-if="displayedSales.length > 0"
      >
        <div class="row bg-light py-2 mx-0 d-none d-md-flex align-items-end">
          <div class="col-md-2">
            <strong>{{
              $t("dashboard.forward_overview.delivery_window")
            }}</strong>
          </div>
          <div class="col-md-2 text-end">
            <strong>{{ $t("dashboard.forward_overview.committed") }}</strong>
          </div>
          <div class="col-md-2 text-end">
            <strong>{{ $t("dashboard.forward_overview.delivered") }}</strong>
          </div>
          <div class="col-md-2 text-end">
            <strong>{{ $t("dashboard.forward_overview.price") }}</strong>
          </div>
          <div class="col-md-2">
            <strong>{{
              $t("dashboard.forward_overview.production_period")
            }}</strong>
          </div>
          <div class="col-md-2">
            <strong>{{ $t("dashboard.forward_overview.technology") }}</strong>
          </div>
        </div>
        <div
          v-for="sale in displayedSales"
          :key="sale.id"
          class="row mx-0 py-2 align-items-center"
        >
          <div class="col-md-2 ps-md-2">
            <div
              :class="[
                'badge',
                'badge-phoenix',
                'w-100',
                'p-2',
                'fs-8',
                'fs-md-9',
                getBadgeClass(sale),
              ]"
            >
              <span class="d-md-none fw-normal text-capitalize">
                {{ $t("dashboard.forward_overview.delivery") }}
              </span>
              {{ formatDateTime(sale.deliveryStart) }}
              <br class="d-none d-md-block" />
              <span class="text-lowercase">{{
                $t("dashboard.forward_overview.to")
              }}</span>
              <br class="d-none d-md-block" />
              {{ formatDateTime(sale.deliveryEnd) }}
            </div>
          </div>
          <div class="col-md-2 text-md-end">
            <div class="d-md-none fw-bold mb-1 mt-2">
              {{ $t("dashboard.forward_overview.committed") }}
            </div>
            {{ sale.committedMWh }}
          </div>
          <div class="col-md-2 text-md-end">
            <div class="d-md-none fw-bold mb-1">
              {{ $t("dashboard.forward_overview.delivered") }}
            </div>
            {{ sale.deliveredMWh }}
          </div>
          <div class="col-md-2 text-md-end">
            <div class="d-md-none fw-bold mb-1">
              {{ $t("dashboard.forward_overview.price") }}
            </div>
            {{ sale.pricePerMWh }}
          </div>
          <div class="col-md-2">
            <div class="d-md-none fw-bold mb-1">
              {{ $t("dashboard.forward_overview.production_period") }}
            </div>
            {{
              formatProductionPeriod(sale.productionStart, sale.productionEnd)
            }}
          </div>
          <div class="col-md-2">
            <div class="d-md-none fw-bold mb-1">
              {{ $t("dashboard.forward_overview.technology") }}
            </div>
            {{ sale.technology }}
          </div>
        </div>
        <div class="text-center">
          <button
            v-if="hasMoreSales"
            id="show-more-button"
            class="btn btn-outline-secondary w-100 border-light"
            @click="togglePreviousSales"
          >
            {{
              showPreviousSales
                ? $t("dashboard.forward_overview.hide_completed_deals")
                : $t("dashboard.forward_overview.show_completed_deals")
            }}
          </button>
        </div>
      </div>
      <PlaceholderCardComponent
        v-else
        :title="$t('dashboard.forward_overview.no_forward_sales')"
        :subtitle="$t('dashboard.forward_overview.no_forward_sales_subtitle')"
      />
    </div>
    <LoadingOverlayComponent v-if="isLoading" />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  inject,
  PropType,
} from "vue";
import { formatMoney, formatDateTime } from "@/composables/formatUtils";
import { useI18n } from "vue-i18n";
import PlaceholderCardComponent from "@/components/common/PlaceholderCardComponent.vue";
import ProductionProjectionBarChart from "@/components/charts/ProductionProjectionBarChart.vue";
import ProductionSalesStatusBarChart from "@/components/charts/ProductionSalesStatusBarChart.vue";
import LoadingOverlayComponent from "../common/LoadingOverlayComponent.vue";
import {
  Company,
  PowerProjection,
  ForwardSale,
  ForwardServiceInstructions,
} from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";
import SetForwardSalesComponent from "./SetForwardSalesComponent.vue";
import { useForwardSaleUtils } from "@/composables/useForwardSaleUtils";

export default defineComponent({
  name: "ForwardOverviewComponent",
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
    is_admin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PlaceholderCardComponent,
    ProductionProjectionBarChart,
    ProductionSalesStatusBarChart,
    SetForwardSalesComponent,
    LoadingOverlayComponent,
  },
  setup(props) {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const globalSuccessHandler = inject("globalSuccessHandler") as (
      message: string
    ) => void;
    const { locale } = useI18n();
    const showPreviousSales = ref(false);
    const forwardSales = ref<ForwardSale[]>([]);
    const solarProjection = ref<PowerProjection | null>(null);
    const windProjection = ref<PowerProjection | null>(null);
    const forwardServiceInstructions = ref<ForwardServiceInstructions | null>(
      null
    );
    const forwardServiceInstructionsKey = ref(0);
    const showServiceInstructions = ref(false);
    const isInitialized = ref(false);
    const isLoading = ref(false);
    const { getQuarterlySalesForYear } = useForwardSaleUtils(forwardSales);

    const activeSales = computed(() =>
      forwardSales.value.filter((sale) => sale.committedMWh > sale.deliveredMWh)
    );

    const previousSales = computed(() =>
      forwardSales.value.filter(
        (sale) => sale.committedMWh === sale.deliveredMWh
      )
    );

    const displayedSales = computed(() =>
      showPreviousSales.value ? forwardSales.value : activeSales.value
    );

    const hasMoreSales = computed(() => previousSales.value.length > 0);

    const totalCommittedVolume = computed(() =>
      activeSales.value.reduce(
        (total, sale) => total + (sale.committedMWh - sale.deliveredMWh),
        0
      )
    );

    const totalCommitmentValue = computed(() =>
      activeSales.value.reduce(
        (total, sale) =>
          total + (sale.committedMWh - sale.deliveredMWh) * sale.pricePerMWh,
        0
      )
    );

    const quarterlySales2025 = getQuarterlySalesForYear(2025);

    const forwardSaleAmountsFromInstructions = computed(() => {
      if (forwardServiceInstructions.value) {
        return [
          forwardServiceInstructions.value.q1_count,
          forwardServiceInstructions.value.q2_count,
          forwardServiceInstructions.value.q3_count,
          forwardServiceInstructions.value.q4_count,
        ];
      }
      return [0, 0, 0, 0];
    });

    const togglePreviousSales = () => {
      showPreviousSales.value = !showPreviousSales.value;
    };

    const getBadgeClass = (sale: ForwardSale): string => {
      const currentDate = new Date();
      const deliveryStart = new Date(sale.deliveryStart);
      const deliveryEnd = new Date(sale.deliveryEnd);
      if (sale.committedMWh === sale.deliveredMWh) {
        // Successfully delivered
        return "badge-phoenix-success";
      } else if (currentDate >= deliveryStart && currentDate <= deliveryEnd) {
        // Currently in delivery window
        return "badge-phoenix-primary";
      } else if (currentDate > deliveryEnd) {
        // Delivery window has passed without full delivery
        return "badge-phoenix-warning";
      } else {
        // Delivery window is in the future
        return "badge-phoenix-secondary";
      }
    };

    const formatProductionPeriod = (start: string, end: string) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const startMonth = startDate.getMonth();
      const endMonth = endDate.getMonth();

      if (startYear !== endYear) {
        console.warn(
          "Production period spans multiple years, which was not expected."
        );
        return `${startYear}-${endYear}`;
      }

      const monthFormatter = new Intl.DateTimeFormat(locale.value, {
        month: "short",
      });

      if (startMonth === 0 && endMonth === 11) {
        return startYear.toString();
      } else if (startYear === endYear) {
        if (startMonth === endMonth) {
          return `${monthFormatter.format(startDate)} ${startYear}`;
        } else {
          return `${monthFormatter.format(startDate)} - ${monthFormatter.format(
            endDate
          )} ${startYear}`;
        }
      } else {
        // In our business case, this is incredibly unlikely
        return `${monthFormatter.format(
          startDate
        )} ${startYear} - ${monthFormatter.format(endDate)} ${endYear}`;
      }
    };

    const handleUpdateServiceInstructions = async (data: any) => {
      isLoading.value = true;
      try {
        const postServiceInstructions = await goSolidApi.post(
          `/api/company/${props.company.id}/forward-instructions`,
          data
        );
        forwardServiceInstructions.value =
          postServiceInstructions.data.forward_service_instructions;
        forwardServiceInstructionsKey.value += 1;
        if (postServiceInstructions.data.signing_url) {
          window.location.href = postServiceInstructions.data.signing_url;
        }
        globalSuccessHandler("Forward sales updated successfully");
        showServiceInstructions.value = false;
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchData = async () => {
      try {
        const reservation_req = await goSolidApi.get(
          `/api/forward-sales-by-company/${props.company.id}`
        );
        forwardSales.value = reservation_req.data;
        const projection_req = await goSolidApi.get(
          `/api/company/${props.company.id}/projection`
        );
        solarProjection.value = projection_req.data.projection.solar_projection;
        windProjection.value = projection_req.data.projection.wind_projection;

        const serviceInstructions_req = await goSolidApi.get(
          `/api/company/${props.company.id}/forward-instructions`
        );
        if (serviceInstructions_req.data.forward_service_instructions) {
          forwardServiceInstructions.value =
            serviceInstructions_req.data.forward_service_instructions;
          forwardServiceInstructionsKey.value += 1;
        }
        isInitialized.value = true;
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    onMounted(fetchData);

    return {
      showPreviousSales,
      displayedSales,
      hasMoreSales,
      totalCommittedVolume,
      totalCommitmentValue,
      forwardSales,
      togglePreviousSales,
      formatDateTime,
      formatProductionPeriod,
      formatMoney,
      getBadgeClass,
      solarProjection,
      windProjection,
      quarterlySales2025,
      forwardServiceInstructions,
      forwardServiceInstructionsKey,
      handleUpdateServiceInstructions,
      showServiceInstructions,
      forwardSaleAmountsFromInstructions,
      isInitialized,
      isLoading,
    };
  },
});
</script>

<style scoped>
#show-more-button {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
</style>
