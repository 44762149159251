import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "card p-3 bg-secondary-subtle text-center border-0" }
const _hoisted_2 = {
  key: 0,
  class: "card-title fs-7 fw-bold mb-2"
}
const _hoisted_3 = {
  key: 1,
  class: "card-text fs-8 mb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}