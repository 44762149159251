<!-- client/src/App.vue -->

<template>
  <div v-if="isDevelopment" class="alert alert-subtle-secondary text-center">
    {{ $t("app.development_environment") }}
  </div>
  <div
    v-if="managingAccount.email"
    class="alert alert-subtle-warning text-center"
  >
    {{ $t("app.managing_client_account", { email: managingAccount.email }) }}
    (<a href="/admin/accounts" class="alert-link">{{
      $t("app.return_to_admin")
    }}</a
    >)
  </div>
  <nav class="navbar navbar-top" data-navbar-appearance="darker">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand">
        <img
          src="/img/logo/logo-color.svg"
          alt="Soldera Logo"
          class="navbar-logo"
        />
      </router-link>
      <ul class="navbar-nav ms-auto">
        <li class="nav-item" v-if="showAuth">
          <button
            v-if="!isAccountAuthenticated"
            class="btn btn-primary"
            @click="login"
          >
            {{ $t("app.log_in") }}
          </button>
          <div v-else class="d-flex align-items-center">
            <button class="btn btn-secondary me-2" @click="navigateToSettings">
              <font-awesome-icon icon="cog" />
            </button>
            <button class="btn btn-secondary" @click="logout">
              {{ $t("app.log_out") }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <div
    v-if="errorMessage !== ''"
    class="mb-1 mx-1 mb-sm-4 mx-sm-4 toast show position-absolute bottom-0 bg-danger-subtle"
  >
    <div class="toast-header">
      <strong class="me-auto text-danger-emphasis">{{
        $t("app.error_occurred")
      }}</strong>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
        aria-label="Close"
        @click="clearError"
      ></button>
    </div>
    <div class="toast-body">
      {{ $t("app.error_message_code", { errorCode: errorCode }) }}
      <br />
      {{ $t("app.contact_support") }}
    </div>
  </div>
  <div
    v-if="successMessage !== ''"
    class="mb-1 mx-1 mb-sm-4 mx-sm-4 toast show position-absolute bottom-0 bg-success-subtle"
  >
    <div class="toast-header">
      <strong class="me-auto text-success-emphasis">{{ successTitle }}</strong>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
        aria-label="Close"
        @click="clearSuccess"
      ></button>
    </div>
    <div class="toast-body">
      {{ successMessage }}
    </div>
  </div>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, provide } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute, useRouter } from "vue-router";
import { Account } from "./types";
import { isWebview } from "./composables/isWebview";
import logger from "./logger";

export default defineComponent({
  name: "MainComponent",
  setup() {
    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
    const route = useRoute();
    const router = useRouter();
    const isAccountAuthenticated = ref(isAuthenticated.value);
    const managingAccount = ref<{ email: string | null }>({
      email: null,
    });
    const showAuth = !isWebview();

    onMounted(() => {
      isAccountAuthenticated.value = isAuthenticated.value;
    });

    watch(route, () => {
      isAccountAuthenticated.value = isAuthenticated.value;
    });

    const login = () => {
      loginWithRedirect({ appState: { targetUrl: window.location.href } });
    };

    const logoutHandler = () => {
      logout({ logoutParams: { returnTo: window.location.origin } });
    };

    const navigateToSettings = () => {
      router.push("/settings");
    };

    const errorMessage = ref("");
    const errorCode = ref("");

    const clearError = () => {
      errorMessage.value = "";
      errorCode.value = "";
    };

    const handleError = (error: { message: string; code: string }) => {
      logger.error("Error: ", error);
      errorMessage.value = error.message;
      errorCode.value = error.code;
    };

    const successMessage = ref("");
    const successTitle = ref("");

    const clearSuccess = () => {
      successMessage.value = "";
      successTitle.value = "";
    };

    const handleSuccess = (title: string, description: string) => {
      logger.info("Success: ", { title, description });
      successTitle.value = title;
      successMessage.value = description;
      // Here you might also want to implement logic to automatically clear the success message after a certain delay
    };

    const updateManagingAccount = (accountData: Account) => {
      if (accountData.admin_account_id) {
        managingAccount.value.email = accountData.email;
      } else {
        managingAccount.value.email = null;
      }
    };

    provide("globalErrorHandler", handleError);
    provide("globalAdminWarning", updateManagingAccount);
    provide("globalSuccessHandler", handleSuccess);

    return {
      isAccountAuthenticated,
      showAuth,
      login,
      managingAccount,
      errorMessage,
      errorCode,
      clearError,
      successMessage,
      successTitle,
      clearSuccess,
      logout: logoutHandler,
      isDevelopment: process.env.NODE_ENV === "development",
      navigateToSettings,
    };
  },
});
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.navbar {
  margin-bottom: 0px;
  flex-shrink: 0;
  padding: 1rem;
  border: 0;
  .navbar-logo {
    height: 32px; /* Adjust size as needed */
    margin-right: 10px;
  }
  .container-fluid {
    padding: 0;
  }
}

.toast {
  z-index: var(--phoenix-toast-zindex);
}

.close-btn {
  background: none;
  border: none;
  color: white;
  margin-left: 10px;
  cursor: pointer;
}

.alert {
  margin-bottom: 0;
  flex-shrink: 0;
  border-radius: 0;
  border-width: 5px;
  border-style: dashed;
  padding: 1rem;
}

/* Adjust font size for devices with screen width up to 375px and a standard device pixel ratio */
@media only screen and (max-width: 376px) and (-webkit-device-pixel-ratio: 2),
  only screen and (max-width: 376px) and (min-resolution: 192dpi) {
  body {
    font-size: 14px; /* Smaller font size for smaller screens like iPhone SE */
  }
}
</style>
