<!-- client/src/components/dashboard/SetForwardSalesComponent.vue -->

<template>
  <div class="forward-sales">
    <div class="card text-dark bg-light mb-3" v-if="flowState === 'intro'">
      <div class="card-body">
        <h4 class="fs-7 fw-bold mb-3">{{ $t("forwardSales.aboutProgram") }}</h4>
        <p class="mb-3">
          {{ $t("forwardSales.programDescription") }}
        </p>
        <div class="mb-3">
          <p
            v-if="
              enrollmentStatus === ForwardEnrollmentStatus.AVAILABLE ||
              enrollmentStatus === ForwardEnrollmentStatus.SIGNATURE_REQUIRED
            "
          >
            {{ $t("forwardSales.readyToLearnMore") }}
          </p>
          <div class="mb-3 fw-bold" v-else>
            {{ $t("forwardSales.serviceStatus") }}
            <span
              class="badge badge-phoenix badge-phoenix-success"
              v-if="enrollmentStatus === ForwardEnrollmentStatus.ENROLLED"
            >
              <font-awesome-icon icon="check-circle" />
              {{ $t("forwardSales.enrolled") }}
            </span>
            <span
              class="badge badge-phoenix badge-phoenix-info"
              v-else-if="
                enrollmentStatus === ForwardEnrollmentStatus.SIGNATURE_PENDING
              "
            >
              <font-awesome-icon icon="signature" />
              {{ $t("forwardSales.signaturePending") }}
            </span>
          </div>
          <SlottedFileCardComponent
            card-type="file"
            :url="infoCardDocumentUrls['forwardOutlook']"
          >
            <template #title>{{ $t("forwardSales.outlookTitle") }}</template>
            <template #info>
              <ul class="mt-2 mb-0 info-list">
                <li>{{ $t("forwardSales.outlookInfo1") }}</li>
                <li>{{ $t("forwardSales.outlookInfo2") }}</li>
              </ul>
            </template>
          </SlottedFileCardComponent>

          <SlottedFileCardComponent
            card-type="file"
            :url="infoCardDocumentUrls['forwardTradingStrategy']"
          >
            <template #title>{{ $t("forwardSales.strategyTitle") }}</template>
            <template #info>
              <ul class="mt-2 mb-0 info-list">
                <li>{{ $t("forwardSales.strategyInfo") }}</li>
              </ul>
            </template>
          </SlottedFileCardComponent>

          <SlottedFileCardComponent
            card-type="link"
            :url="infoCardDocumentUrls['forwardFramework']"
          >
            <template #title>{{ $t("forwardSales.frameworkTitle") }}</template>
            <template #info>
              <ul class="mt-2 mb-0 info-list">
                <li>{{ $t("forwardSales.frameworkInfo") }}</li>
              </ul>
            </template>
          </SlottedFileCardComponent>
        </div>

        <button
          v-if="
            enrollmentStatus === ForwardEnrollmentStatus.AVAILABLE ||
            enrollmentStatus === ForwardEnrollmentStatus.SIGNATURE_REQUIRED
          "
          class="btn btn-primary"
          @click="flowState = 'customization'"
        >
          {{ $t("forwardSales.customizeStrategy") }}
        </button>
        <button
          v-else-if="enrollmentStatus === ForwardEnrollmentStatus.ENROLLED"
          class="btn btn-primary"
          @click="flowState = 'customization'"
        >
          {{ $t("forwardSales.editStrategy") }}
        </button>
        <a
          v-else-if="
            enrollmentStatus === ForwardEnrollmentStatus.SIGNATURE_PENDING
          "
          class="btn btn-primary"
          :href="pendingDocumentUrl"
          target="_blank"
        >
          <font-awesome-icon icon="signature" />
          {{ $t("forwardSales.signDocument") }}
        </a>
        <button class="btn btn-link ms-2" @click="openHelpCrunch">
          {{ $t("common.contactSupport") }}
        </button>
      </div>
    </div>
    <div
      class="card text-dark bg-light mb-3"
      v-if="flowState === 'customization'"
    >
      <div class="row g-3 card-body">
        <form @submit.prevent="setForwardSales" class="mb-3">
          <button
            class="btn btn-link btn-sm px-0 pt-0 mb-1"
            @click="flowState = 'intro'"
          >
            <font-awesome-icon icon="arrow-left" />
            {{ $t("forwardSales.readMore") }}
          </button>
          <h5 class="fs-7 fw-bold mb-3">
            {{ $t("forwardSales.customizeStrategyTitle") }}
          </h5>
          <label for="percentageOptions" class="fs-8 fw-bold mb-3">
            {{ $t("forwardSales.selectPercentage") }}
          </label>
          <div
            class="btn-group mb-4 btn-group-sm border border-translucent rounded-4 overflow-hidden d-flex"
            role="group"
            aria-label="percentageOptions"
          >
            <button
              v-for="percentage in percentageOptions"
              :key="percentage"
              type="button"
              class="btn px-3 flex-grow-1"
              :class="[
                percentageForSale === percentage
                  ? 'btn-primary'
                  : 'btn-secondary border border-black',
              ]"
              @click="setPercentageForSale(percentage)"
            >
              {{
                percentage === "Custom" ? $t("common.custom") : `${percentage}%`
              }}
            </button>
          </div>

          <div class="mb-3" v-if="percentageForSale == 'Custom'">
            <h6 class="fs-8 fw-bold mb-3">
              {{ $t("forwardSales.adjustAmounts") }}
            </h6>
            <div
              class="row mb-2 align-items-center"
              v-for="(amount, index) in forwardSaleAmounts"
              :key="index"
            >
              <div class="col-md-3 d-flex align-items-center">
                <label
                  :for="'forwardSaleAmount' + index"
                  class="form-label fs-9 mb-0 ps-0"
                  >{{
                    $t("forwardSales.quarterProduction", { quarter: index + 1 })
                  }}</label
                >
              </div>
              <div class="col-md-9">
                <div class="input-group">
                  <input
                    type="number"
                    :id="'forwardSaleAmount' + index"
                    class="form-control"
                    v-model.number="forwardSaleAmounts[index]"
                    @input="onForwardSaleAmountChange"
                    @keydown.enter.prevent="focusNextInput"
                    min="0"
                    step="1"
                    :class="{
                      'is-invalid': isPercentageInvalid(
                        quarterPercentages[index]
                      ),
                    }"
                  />
                  <span class="input-group-text">{{ $t("common.mwh") }}</span>
                  <input
                    type="number"
                    class="form-control percentage-input"
                    v-model.number="quarterPercentages[index]"
                    @input="onQuarterPercentageChange(index)"
                    @keydown.enter.prevent="focusNextInput"
                    min="0"
                    step="1"
                    :class="{
                      'is-invalid': isPercentageInvalid(
                        quarterPercentages[index]
                      ),
                    }"
                  />
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
          </div>

          <h6 class="fs-8 fw-bold mb-3">
            {{ $t("forwardSales.selectPriceStrategy") }}
          </h6>

          <div class="form-check mb-3">
            <input
              class="form-check-input"
              id="onlySellAboveSpot"
              type="checkbox"
              v-model="onlySellAboveSpot"
            />
            <label class="form-label fs-9 mx-1" for="onlySellAboveSpot">
              {{ $t("forwardSales.contangoFocus") }}
            </label>
          </div>

          <button
            type="submit"
            class="btn btn-primary w-100"
            v-if="
              enrollmentStatus === ForwardEnrollmentStatus.SIGNATURE_REQUIRED
            "
          >
            <font-awesome-icon icon="signature" />
            {{ $t("forwardSales.confirmStrategyAndSign") }}
          </button>

          <button type="submit" class="btn btn-primary w-100" v-else>
            <font-awesome-icon icon="check" />
            {{ $t("forwardSales.confirmStrategy") }}
          </button>
        </form>
        <div class="text-lg-center">
          <hr class="mb-4 mt-0" />
          <h5 class="fs-6 fw-bold mb-3">
            {{ $t("forwardSales.estimatedSchedule") }}
          </h5>

          <h6 class="fs-7 mb-3 lh-lg">
            <span class="fw-bold">
              {{ $t("forwardSales.currentYearSales", { year: currentYear }) }}
            </span>
            <br />
            <span class="fw-normal">
              {{
                $t("forwardSales.nextYearProduction", { year: currentYear + 1 })
              }}
            </span>
          </h6>
          <ProductionSalesStatusBarChart
            :solar-projection="solarProjection"
            :wind-projection="windProjection"
            :forward-sale-amounts="forwardSaleAmounts"
            :forwardSoldAmounts="soldForwardCurrentYear"
            :height="200"
            :title="
              $t('forwardSales.forwardSalesStatusTitle', {
                year: currentYear + 1,
              })
            "
            :subtitle="''"
          ></ProductionSalesStatusBarChart>
          <p class="fw-bold">
            {{
              $t("forwardSales.upcomingTimelineTitle", { year: currentYear })
            }}
          </p>
          <ForwardSaleTimeline
            :saleYear="currentYear"
            :remainingQuarters="1"
            :saleAmounts="forwardSaleAmounts"
            :soldAmounts="soldForwardCurrentYear"
          />

          <hr class="my-4" />

          <h6 class="fs-7 mb-3 lh-lg">
            <span class="fw-bold">
              {{
                $t("forwardSales.currentYearSales", { year: currentYear + 1 })
              }}
            </span>
            <br />
            <span class="fw-normal">
              {{
                $t("forwardSales.nextYearProduction", { year: currentYear + 2 })
              }}
            </span>
          </h6>
          <ProductionSalesStatusBarChart
            :solar-projection="solarProjection"
            :wind-projection="windProjection"
            :forward-sale-amounts="forwardSaleAmounts"
            :forwardSoldAmounts="soldForwardNextYear"
            :title="
              $t('forwardSales.forwardSalesStatusTitle', {
                year: currentYear + 2,
              })
            "
            :subtitle="''"
          ></ProductionSalesStatusBarChart>
          <p class="fw-bold">
            {{
              $t("forwardSales.upcomingTimelineTitle", {
                year: currentYear + 1,
              })
            }}
          </p>
          <ForwardSaleTimeline
            :saleYear="currentYear + 1"
            :remainingQuarters="4"
            :saleAmounts="forwardSaleAmounts"
            :soldAmounts="soldForwardNextYear"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  PropType,
  watch,
  onMounted,
  toRef,
} from "vue";
import {
  Company,
  PowerProjection,
  ForwardSale,
  ForwardServiceInstructions,
  ForwardEnrollmentStatus,
} from "@/types";
import { useCompanyUtils } from "@/composables/useCompanyUtils";
import SlottedFileCardComponent from "@/components/common/SlottedFileCardComponent.vue";
import ForwardSaleTimeline from "@/components/common/ForwardSaleTimeline.vue";
import { useHelpCrunch } from "@/composables/useHelpCrunch";
import { useForwardSaleUtils } from "@/composables/useForwardSaleUtils";
import ProductionSalesStatusBarChart from "../charts/ProductionSalesStatusBarChart.vue";

export default defineComponent({
  name: "setForwardSalesComponent",
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
    forwardSales: {
      type: Array as PropType<ForwardSale[]>,
      required: true,
    },
    solarProjection: {
      type: Object as PropType<PowerProjection>,
      required: true,
    },
    windProjection: {
      type: Object as PropType<PowerProjection>,
      required: true,
    },
    forwardServiceInstructions: {
      type: Object as PropType<ForwardServiceInstructions | null>,
      required: false,
    },
  },
  emits: ["update-service-instructions"],
  components: {
    SlottedFileCardComponent,
    ForwardSaleTimeline,
    ProductionSalesStatusBarChart,
  },
  setup(props, { emit }) {
    const minForwardPrice = ref(0);
    const onlySellAboveSpot = ref(true);
    const flowState = ref("intro"); // Options are intro, customize
    const percentageForSale = ref<number | string>(0);
    const priceStrategy = ref<"automatic" | "minimumPrice">("automatic");
    const percentageOptions = [0, 30, 60, "Custom"];
    const quarterPercentages = ref([0, 0, 0, 0]);
    const { openHelpCrunch } = useHelpCrunch();
    const { getQuarterlySalesForYear } = useForwardSaleUtils(
      toRef(props.forwardSales)
    );
    const currentYear = new Date().getFullYear();

    const hasInvalidPercentages = computed(() => {
      return quarterPercentages.value.some((percentage) =>
        isPercentageInvalid(percentage)
      );
    });

    const isPercentageInvalid = (percentage: number) => percentage >= 80;

    const { getFirstUnsignedDocumentWithLegalKey } = useCompanyUtils(
      props.company
    );

    const enrollmentStatus = computed(() => {
      if (
        props.company.legal_keys &&
        props.company.legal_keys.includes("forward_service")
      ) {
        if (props.forwardServiceInstructions) {
          return ForwardEnrollmentStatus.ENROLLED;
        } else {
          return ForwardEnrollmentStatus.AVAILABLE;
        }
      } else {
        if (getFirstUnsignedDocumentWithLegalKey("forward_service")) {
          return ForwardEnrollmentStatus.SIGNATURE_PENDING;
        } else {
          return ForwardEnrollmentStatus.SIGNATURE_REQUIRED;
        }
      }
    });

    const pendingDocumentUrl = computed(() => {
      if (
        enrollmentStatus.value === ForwardEnrollmentStatus.SIGNATURE_PENDING
      ) {
        const document =
          getFirstUnsignedDocumentWithLegalKey("forward_service");
        if (
          document &&
          document.document_signers &&
          document.document_signers.length > 0
        ) {
          return document.document_signers[0].redirect_url;
        }
      }
      return "";
    });

    const infoCardDocumentUrls = computed(() => {
      if (props.company.country == "EE") {
        return {
          forwardOutlook:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/reports/soldera_forward_outlook_en.pdf",
          forwardTradingStrategy:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/instructions/ee_forward_advantage_v1.pdf",
          forwardFramework:
            "https://www.soldera.org/compliance/forward-muugi-leping",
        };
      } else if (props.company.country == "LT") {
        return {
          forwardOutlook:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/reports/soldera_forward_outlook_en.pdf",
          forwardTradingStrategy:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/instructions/lt_forward_advantage_v1.pdf",
          forwardFramework:
            "https://www.soldera.org/lt/compliance/isankstinio-pardavimo-sandoriu-sutartis",
        };
      } else if (props.company.country == "LV") {
        return {
          forwardOutlook:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/reports/soldera_forward_outlook_en.pdf",
          forwardTradingStrategy:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/instructions/lv_forward_advantage_v1.pdf",
          forwardFramework:
            "https://www.soldera.org/lv/compliance/nakotnes-pardosanas-darijumu-ligums",
        };
      } else {
        return {
          forwardOutlook:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/reports/soldera_forward_outlook_en.pdf",
          forwardTradingStrategy:
            "https://soldera-public-statics.s3.eu-west-1.amazonaws.com/instructions/en_forward_advantage_v1.pdf",
          forwardFramework:
            "https://www.soldera.org/en/compliance/forward-sales-agreement",
        };
      }
    });

    watch(priceStrategy, (newValue) => {
      if (newValue === "automatic") {
        minForwardPrice.value = 0;
      }
    });

    const setForwardSales = () => {
      /**
       * TODO: Uncomment after we fix projections
      if (hasInvalidPercentages.value) {
        return;
      }
        */
      emit("update-service-instructions", {
        minimum_price: minForwardPrice.value,
        skip_sale_if_not_contango: onlySellAboveSpot.value,
        q1_count: forwardSaleAmounts.value[0],
        q2_count: forwardSaleAmounts.value[1],
        q3_count: forwardSaleAmounts.value[2],
        q4_count: forwardSaleAmounts.value[3],
        instructions: {},
      });
    };

    const quarterlyEstimates = computed(() => {
      const estimates = [0, 0, 0, 0];
      for (let month = 1; month <= 12; month++) {
        const quarterIndex = Math.floor((month - 1) / 3);
        estimates[quarterIndex] +=
          (props.solarProjection[month] || 0) +
          (props.windProjection[month] || 0);
      }
      return estimates;
    });

    const forwardSaleAmounts = ref(quarterlyEstimates.value.map(() => 0));

    const setPercentageForSale = (value: number | string) => {
      percentageForSale.value = value;
      if (typeof value === "number") {
        forwardSaleAmounts.value = quarterlyEstimates.value.map((estimate) =>
          Math.round(estimate * (value / 100))
        );
      }
    };

    const soldForwardCurrentYear = getQuarterlySalesForYear(currentYear + 1);
    const soldForwardNextYear = getQuarterlySalesForYear(currentYear + 2);

    const onForwardSaleAmountChange = () => {
      const totalEstimate = quarterlyEstimates.value.reduce(
        (sum, value) => sum + value,
        0
      );
      const totalForwardSale = forwardSaleAmounts.value.reduce(
        (sum, value) => sum + value,
        0
      );
      const calculatedPercentage = (totalForwardSale / totalEstimate) * 100;

      if (percentageOptions.includes(calculatedPercentage)) {
        percentageForSale.value = calculatedPercentage;
      } else {
        percentageForSale.value = "Custom";
      }
    };

    const onQuarterPercentageChange = (index: number) => {
      const estimate = quarterlyEstimates.value[index];
      forwardSaleAmounts.value[index] = Math.round(
        (quarterPercentages.value[index] / 100) * estimate
      );
      onForwardSaleAmountChange();
    };

    const focusNextInput = (event: KeyboardEvent) => {
      const forwardSalesContainer = document.querySelector(".forward-sales");
      if (!forwardSalesContainer) return;

      const inputs = Array.from(
        forwardSalesContainer.querySelectorAll('input[type="number"]')
      );
      const currentIndex = inputs.indexOf(event.target as HTMLElement);

      // If current input is not found, do nothing
      if (currentIndex === -1) return;

      // Get next index, wrapping around to 0 if we're at the end
      const nextIndex = (currentIndex + 1) % inputs.length;

      // Focus the next input
      (inputs[nextIndex] as HTMLElement).focus();
    };

    watch(
      forwardSaleAmounts,
      (newAmounts) => {
        quarterPercentages.value = newAmounts.map((amount, index) => {
          const estimate = quarterlyEstimates.value[index];
          return estimate ? Math.round((amount / estimate) * 100) : 0;
        });
      },
      { deep: true }
    );

    onMounted(() => {
      if (props.forwardServiceInstructions) {
        minForwardPrice.value = props.forwardServiceInstructions.minimum_price;
        onlySellAboveSpot.value =
          props.forwardServiceInstructions.skip_sale_if_not_contango;
        percentageForSale.value = "Custom";
        if (minForwardPrice.value > 0) {
          priceStrategy.value = "minimumPrice";
        }
        forwardSaleAmounts.value = [
          props.forwardServiceInstructions.q1_count,
          props.forwardServiceInstructions.q2_count,
          props.forwardServiceInstructions.q3_count,
          props.forwardServiceInstructions.q4_count,
        ];
        flowState.value = "customization";
      }
    });

    return {
      setForwardSales,
      percentageForSale,
      onlySellAboveSpot,
      minForwardPrice,
      flowState,
      forwardSaleAmounts,
      setPercentageForSale,
      onForwardSaleAmountChange,
      priceStrategy,
      percentageOptions,
      enrollmentStatus,
      pendingDocumentUrl,
      infoCardDocumentUrls,
      ForwardEnrollmentStatus,
      quarterPercentages,
      onQuarterPercentageChange,
      openHelpCrunch,
      soldForwardCurrentYear,
      soldForwardNextYear,
      currentYear,
      focusNextInput,
      isPercentageInvalid,
    };
  },
});
</script>

<style scoped>
form {
  .form-label {
    text-transform: none;
  }
}
.info-list {
  padding-left: 20px;
}
.percentage-input {
  width: 90px;
  flex-grow: 0;
}
</style>
