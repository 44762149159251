<template>
  <div class="team-component">
    <h4 class="fs-7 fw-bold mb-3">
      {{ $t("dashboard.companyData.team.title") }}
    </h4>
    <ul class="list-group mb-3">
      <li
        v-for="account in accounts"
        :key="account.id"
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        {{ account.name }} {{ account.email }}
        <button @click="confirmDelete(account)" class="btn btn-danger btn-sm">
          {{ $t("dashboard.companyData.team.delete") }}
        </button>
      </li>
    </ul>
    <div class="mb-3">
      <input
        v-model="newEmail"
        type="email"
        class="form-control"
        :placeholder="'example@email.com...'"
      />
    </div>
    <button @click="inviteAccount" class="btn btn-primary">
      {{ $t("dashboard.companyData.team.invite") }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, PropType, inject } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useI18n } from "vue-i18n";
import { Account, Company } from "@/types";

export default defineComponent({
  name: "TeamComponent",
  props: {
    company_id: {
      type: Number,
      required: true,
    },
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const accounts = ref<Account[]>([]);
    const newEmail = ref("");
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;

    const fetchAccounts = async () => {
      try {
        const response = await goSolidApi.get(
          `/api/company/${props.company_id}/accounts`
        );
        accounts.value = response.data.accounts as Account[];
      } catch (error) {
        globalErrorHandler(error);
      }
    };

    const confirmDelete = async (account: Account) => {
      if (
        confirm(
          t("dashboard.companyData.team.confirmDelete", {
            account_name: account.name,
            company_name: props.company.name,
          })
        )
      ) {
        try {
          await goSolidApi.delete(
            `/api/company/${props.company_id}/account/${account.id}`
          );
          await fetchAccounts();
        } catch (error) {
          globalErrorHandler(error);
        }
      }
    };

    const inviteAccount = async () => {
      if (!newEmail.value || !/\S+@\S+\.\S+/.test(newEmail.value)) {
        alert(t("dashboard.companyData.team.invalidEmail"));
        return;
      }
      try {
        await goSolidApi.post(`/api/company/${props.company_id}/account`, {
          email: newEmail.value,
        });
        newEmail.value = "";
        await fetchAccounts();
      } catch (error) {
        globalErrorHandler(error);
      }
    };

    onMounted(fetchAccounts);

    return { accounts, newEmail, confirmDelete, inviteAccount };
  },
});
</script>
