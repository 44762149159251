<!-- client/src/components/common/LoadingOverlayComponent.vue -->

<template>
  <transition name="fade">
    <div class="loading-overlay" v-if="isVisible">
      <div class="loading-spinner">
        <img
          src="/img/logo/soldera-loading.svg"
          alt="Loading"
          class="spinner"
        />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "LoadingOverlayComponent",
  setup() {
    const isVisible = ref(false);
    onMounted(() => {
      setTimeout(() => {
        isVisible.value = true; // Trigger the overlay to show after component is mounted
      }, 10); // Timeout ensures that the DOM has updated before triggering the transition
    });
    return { isVisible };
  },
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.loading-overlay {
  position: fixed; /* Changed from absolute to fixed */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    var(--phoenix-body-bg-rgb),
    0.7
  ); /* Dim the background */
  backdrop-filter: blur(2px); /* Slight blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 90px;
  height: 90px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
