import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "team-component" }
const _hoisted_2 = { class: "fs-7 fw-bold mb-3" }
const _hoisted_3 = { class: "list-group mb-3" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("dashboard.companyData.team.title")), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accounts, (account) => {
        return (_openBlock(), _createElementBlock("li", {
          key: account.id,
          class: "list-group-item d-flex justify-content-between align-items-center"
        }, [
          _createTextVNode(_toDisplayString(account.name) + " " + _toDisplayString(account.email) + " ", 1),
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.confirmDelete(account)),
            class: "btn btn-danger btn-sm"
          }, _toDisplayString(_ctx.$t("dashboard.companyData.team.delete")), 9, _hoisted_4)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newEmail) = $event)),
        type: "email",
        class: "form-control",
        placeholder: 'example@email.com...'
      }, null, 8, _hoisted_6), [
        [_vModelText, _ctx.newEmail]
      ])
    ]),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.inviteAccount && _ctx.inviteAccount(...args))),
      class: "btn btn-primary"
    }, _toDisplayString(_ctx.$t("dashboard.companyData.team.invite")), 1)
  ]))
}