import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row row-cols-1 row-cols-lg-4 g-3 text-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForwardSaleScheduleCard = _resolveComponent("ForwardSaleScheduleCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (quarterIndex) => {
      return _createVNode(_component_ForwardSaleScheduleCard, {
        key: quarterIndex,
        "sale-year": _ctx.saleYear,
        "sale-quarter": quarterIndex,
        "sale-amounts": _ctx.getQuarterAmounts(quarterIndex)
      }, null, 8, ["sale-year", "sale-quarter", "sale-amounts"])
    }), 64))
  ]))
}