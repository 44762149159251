<template>
  <div class="eso-mapping-view container py-5 overflow-y-auto">
    <h1 class="mb-4">ESO Mapping View</h1>
    <h2 class="mb-4">Usage:</h2>
    <ul>
      <li>
        goto <a href="info">info</a>
        and make sure you have up to date permissions in ESO. Ask for new ones
        if needed (maybe I should automate this
      </li>
      <li>
        push this
        <button @click="syncESOMeterData">sync ESO data</button>
        to sync all ESO production and metering data
      </li>
      <li>log into grexel</li>
      <li>
        open the soldera-chrome-extension and push the "sync producers" button
      </li>
    </ul>
    <div class="mb-4">
      use this view to map ESO and Grexel metering device this view shows all
      grexel metering devices, we have access to AND have a valid
      company.registry_id in our database. The last is accomplished with "Fetch
      all accounts data" in the soldera extension
    </div>
    <div class="mb-4">
      <label for="companySelect" class="form-label">Select Company:</label>
      <select
        id="companySelect"
        class="form-select"
        v-model="selectedCompany"
        @change="changeCompany"
      >
        <option
          v-for="company in companies"
          :key="company.id"
          :value="company.id"
        >
          {{ company.name }}
        </option>
      </select>
    </div>
    <button class="btn btn-primary mb-3" @click="toggleView">
      {{ useUserEndpoints ? "Switch to Admin View" : "Switch to User View" }}
    </button>
    <!--
    <button class="btn btn-primary mb-3" @click="testButton">
      Test Button
    </button>
    -->
    <div v-if="polling">
      <h3>Polling data, please wait</h3>
    </div>
    <table v-if="meters.length" class="table table-bordered table-hover mt-4">
      <thead class="table-dark">
        <tr>
          <th>Company Name</th>
          <th>Address</th>
          <th>Grid Reference</th>
          <th>Capacity Electrical</th>
          <th>Technology Name</th>
          <th>ESO Meter Number</th>
          <th>Object ID</th>
          <th>Mapping Active</th>
          <th>ESO Last Month Production</th>
          <th>Grexel last month upload</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="meter in meters" :key="meter.grid_reference">
          <td>{{ meter.company_name }}</td>
          <td>
            {{ meter.address.street }}, {{ meter.address.city }},
            {{ meter.address.region }},
            {{ meter.address.country }}
          </td>
          <td>{{ meter.grid_reference }}</td>
          <td>{{ meter.capacity_electrical }}</td>
          <td>{{ meter.technology_name_keyword }}</td>
          <td>{{ meter.eso_meter_number }}</td>
          <td>{{ meter.eso_object_number }}</td>
          <td>{{ meter.active ? "Active" : "Inactive" }}</td>
          <td>{{ meter.eso_last_month_production }}</td>
          <td>{{ meter.grexel_last_month_production }}</td>
          <td>
            <button class="btn btn-secondary" @click="openEditModal(meter)">
              Edit Mapping
            </button>
            <button class="btn btn-secondary" @click="uploadReading(meter)">
              Send to Grexel
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="showEditModal"
      class="modal fade show d-flex align-items-center justify-content-center"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Mapping</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeEditModal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="esoMeterNumber" class="form-label"
                >ESO Meter Number:</label
              >
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Meter Number</th>
                    <th>Object Number</th>
                    <th>Address</th>
                    <th>Select</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="option in meterOptions" :key="option">
                    <td>{{ option.meter_number }}</td>
                    <td>{{ option.object_number }}</td>
                    <td>{{ option.object_address }}</td>
                    <td>
                      <input
                        type="radio"
                        name="esoMeterNumber"
                        :value="option.meter_number"
                        v-model="selectedMeter.eso_meter_number"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                v-model="selectedMeter.active"
                class="form-check-input"
                id="activeStatus"
              />
              <label class="form-check-label" for="activeStatus"
                >Active Status</label
              >
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="saveMapping">Save</button>
            <button class="btn btn-secondary" @click="closeEditModal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, inject } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { Account, Company } from "@/types";

interface Address {
  country: string;
  city: string;
  region: string;
  street: string;
}

interface MeterMetadata {
  company_name: string;
  eso_object_number: string;
  address: Address;
  grid_reference: string;
  capacity_electrical: number;
  technology_name_keyword: string;
  eso_meter_number: string;
  active: boolean;
  eso_last_month_production: number;
  grexel_last_month_production: string;
  status: string;
}

interface MeterOption {
  meter_number: string;
  object_number: string;
  object_address: string;
}

export default defineComponent({
  name: "EsoMappingView",
  setup() {
    onMounted(() => {
      fetchCompanies();
      fetchMappings();
    });
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const meters = ref<MeterMetadata[]>([]);
    const showEditModal = ref(false);
    const selectedMeter = ref<MeterMetadata | null>(null);
    const meterOptions = ref<MeterOption[]>([]);
    const useUserEndpoints = ref(false);
    const companies = ref<Company[]>([]);
    const selectedCompanyId = ref<number | null>(null);
    const polling = ref<boolean>(true);
    const fetchCompanies = async () => {
      try {
        if (!useUserEndpoints.value) {
          companies.value = [];
        } else {
          const response = await goSolidApi.get("/api/account");
          if (response.status === 200 && response.data) {
            let data: Account = response.data;
            companies.value = data.companies;
          } else {
            throw new Error("Unexpected response from API");
          }
        }
      } catch (error: any) {
        globalErrorHandler(error);
      }
    };

    const fetchMappings = async () => {
      try {
        polling.value = true;
        if (!selectedCompanyId.value && useUserEndpoints.value) {
          meters.value = [];
          return;
        }
        const endpoint = useUserEndpoints.value
          ? `/api/integration/grexel/get-eso-mappings/${selectedCompanyId.value}`
          : "/api/admin/integration/grexel/get-eso-mappings";
        const response = await goSolidApi.get(endpoint);
        if (response.status === 200 && response.data) {
          meters.value = response.data;
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        globalErrorHandler(error);
      } finally {
        polling.value = false;
      }
    };

    const syncESOMeterData = async () => {
      try {
        if (!selectedCompanyId.value && useUserEndpoints.value) {
          meters.value = [];
          return;
        }
        const endpoint = "/api/admin/lt-eso/sync-meter-data";
        await goSolidApi.get(endpoint);
      } catch (error: any) {
        globalErrorHandler(error);
      }
    };

    const toggleView = () => {
      useUserEndpoints.value = !useUserEndpoints.value;
      fetchCompanies();
      fetchMappings();
    };

    const changeCompany = () => {
      fetchMappings();
    };
    const openEditModal = async (meter: MeterMetadata) => {
      selectedMeter.value = { ...meter };
      try {
        const endpoint = useUserEndpoints.value
          ? `/api/integration/grexel/get-eso-mappings/${selectedCompanyId.value}/${meter.grid_reference}/options`
          : `/api/admin/integration/grexel/get-eso-mappings/${meter.grid_reference}/options`;
        const response = await goSolidApi.get(endpoint);
        if (response.status === 200 && response.data) {
          const noneOption: MeterOption = {
            meter_number: "",
            object_number: "",
            object_address: "",
          };
          meterOptions.value = [noneOption, ...response.data];
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        globalErrorHandler(error);
      }
      showEditModal.value = true;
    };

    const closeEditModal = () => {
      showEditModal.value = false;
      selectedMeter.value = null;
      meterOptions.value = [];
    };

    const saveMapping = async () => {
      if (selectedMeter.value) {
        try {
          let { grid_reference, eso_meter_number, active } =
            selectedMeter.value;
          if (eso_meter_number == "") {
            eso_meter_number = "None";
          }
          const endpoint = useUserEndpoints.value
            ? `/api/integration/grexel/get-eso-mappings/save/${selectedCompanyId.value}/${grid_reference}/${eso_meter_number}/${active}`
            : `/api/admin/integration/grexel/get-eso-mappings/save/${grid_reference}/${eso_meter_number}/${active}`;
          const response = await goSolidApi.post(endpoint);
          if (response.status === 200) {
            await fetchMappings();
            closeEditModal();
          } else {
            throw new Error("Unexpected response from API");
          }
        } catch (error: any) {
          globalErrorHandler(error);
        }
      }
    };

    const uploadReading = async (meter: MeterMetadata) => {
      try {
        const endpoint = `/api/admin/integration/grexel/upload-meter-reading/${meter.eso_meter_number}`;
        const response = await goSolidApi.post(endpoint);
        if (response.status === 200) {
          await fetchMappings();
          closeEditModal();
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        globalErrorHandler(error);
      }
    };
    const testButton = async (meter: MeterMetadata) => {
      try {
        const endpoint = `/api/admin/integration/grexel/test`;
        const response = await goSolidApi.post(endpoint);
        if (response.status === 200) {
          console.log("API Response:", response.data);
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        globalErrorHandler(error);
      }
    };

    return {
      meters,
      fetchCompanies,
      fetchMappings,
      openEditModal,
      closeEditModal,
      saveMapping,
      showEditModal,
      selectedMeter,
      meterOptions,
      useUserEndpoints,
      toggleView,
      companies,
      changeCompany,
      syncESOMeterData,
      uploadReading,
      testButton,
      selectedCompany: selectedCompanyId,
      polling,
    };
  },
});
</script>

<style scoped>
/* Removed custom CSS class for text alignment */
</style>
