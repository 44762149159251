<!-- client/src/components/signup/ee/SubsidyCardComponent.vue -->

<template>
  <div class="col-sm-11 p-2 p-sm-3 card">
    <div class="row mb-2 g-2 align-items-center">
      <div class="col-6 col-sm-5">
        <label id="form-title" class="col-form-label">{{
          $t("signup.add_subsidy_component.subsidy_provider")
        }}</label>
      </div>
      <div class="col-6 col-sm-5" v-if="isNotElering">
        <label id="form-title" class="col-form-label">{{
          $t("signup.add_subsidy_component.decision_date")
        }}</label>
      </div>
    </div>
    <div class="row g-2 align-items-center">
      <div class="col-6 col-sm-5">
        <input
          type="text"
          class="form-control"
          :placeholder="$t('signup.add_subsidy_component.placeholder_provider')"
          :value="subsidy.provider"
          :disabled="!isNotElering"
          @input="updateField('provider', $event)"
          @blur="v$.provider.$touch"
          :class="{ 'is-invalid': v$.provider.$error }"
        />
        <div
          class="invalid-feedback"
          v-for="error of v$.provider.$errors"
          :key="error"
        >
          {{ error.$message }}
        </div>
      </div>
      <div class="col-6 col-sm-5" v-if="isNotElering">
        <input
          type="date"
          class="form-control"
          :value="subsidy.decision_date"
          @input="updateField('decision_date', $event)"
          @blur="v$.decision_date.$touch"
          :class="{ 'is-invalid': v$.decision_date.$error }"
        />
        <div
          class="invalid-feedback"
          v-for="error of v$.decision_date.$errors?.filter(
            (_element: any, index: number) => index < 1
          )"
          :key="error"
        >
          {{ error.$message }}
        </div>
      </div>
      <div class="col-6 col-sm-2">
        <button class="btn btn-subtle-danger" @click="removeSubsidy()">
          <font-awesome-icon icon="trash-alt" />
        </button>
      </div>
    </div>
    <div class="mt-3" v-if="extraData && isNotElering">
      <div class="row mb-2 g-2 align-items-center">
        <div class="col-6 col-sm-5">
          <label id="form-title" class="col-form-label">{{
            $t("signup.add_subsidy_component.subsidy_plan")
          }}</label>
        </div>
        <div class="col-6 col-sm-5">
          <label id="form-title" class="col-form-label"
            >{{ $t("signup.add_subsidy_component.amount") }} (€)</label
          >
        </div>
      </div>
      <div class="row g-2 align-items-center">
        <div class="col-6 col-sm-5">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('signup.add_subsidy_component.placeholder_plan')"
            :value="subsidy.plan"
            @input="updateField('plan', $event)"
            @blur="v$.plan.$touch"
            :class="{ 'is-invalid': v$.plan.$error }"
          />
          <div
            class="invalid-feedback"
            v-for="error of v$.plan.$errors"
            :key="error"
          >
            {{ error.$message }}
          </div>
        </div>
        <div class="col-6 col-sm-5">
          <input
            type="number"
            class="form-control"
            :value="subsidy.amount"
            @input="updateField('amount', $event)"
            @blur="v$.amount.$touch"
            :class="{ 'is-invalid': v$.amount.$error }"
          />
          <div
            class="invalid-feedback"
            v-for="error of v$.amount.$errors"
            :key="error"
          >
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useValidators } from "@/composables/useValidators";
import useVuelidate from "@vuelidate/core";
import { Subsidy } from "@/types";
import { computed, defineComponent, toRefs } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "SubsidyCardComponent",
  props: {
    subsidy: {
      type: Object as () => Subsidy,
      required: true,
    },
    extraData: { type: Boolean, required: true },
    removeSubsidy: { type: Function, required: true },
  },
  emits: ["update:subsidy"],
  setup(props, { emit }) {
    const subsidy = toRefs(props).subsidy;
    const { t } = useI18n();
    const { validatorIf, required, requiredIf, reasonableDate } =
      useValidators(t);
    const isNotElering = computed(
      () => !(subsidy.value.provider === "Elering AS")
    );
    const rules = computed(() => ({
      provider: { required },
      decision_date: [
        requiredIf(isNotElering.value),
        validatorIf(isNotElering, reasonableDate),
      ],
      plan: [requiredIf(props.extraData && isNotElering.value)],
      amount: [requiredIf(props.extraData && isNotElering.value)],
    })) as any;
    const v$ = useVuelidate(rules, subsidy);
    const updateField = async (field: string, event: Event) => {
      const updatedValue = {
        ...props.subsidy,
        [field]: (event.target as HTMLInputElement).value,
      } as Subsidy;
      emit("update:subsidy", updatedValue);
    };

    return {
      v$,
      updateField,
      isNotElering,
    };
  },
});
</script>

<style scoped>
.col-form-label {
  text-align: left;
  font-weight: bold;
  padding: 0;
}
.btn-outline-secondary {
  padding: 0.375rem 0.75rem;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
</style>
