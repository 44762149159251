import { Company, Document } from "@/types";

export function useCompanyUtils(company: Company) {
  const getSignedDocumentsWithContractKey = (key: string) => {
    return company.documents.filter(
      (document) =>
        document.status === "signed" &&
        document.contract_keys &&
        document.contract_keys.includes(key)
    );
  };

  const getFirstUnsignedDocumentWithLegalKey = (key: string) => {
    return company.documents.find(
      (document) =>
        !document.status &&
        document.legal_keys &&
        document.legal_keys.includes(key)
    );
  };

  return {
    getSignedDocumentsWithContractKey,
    getFirstUnsignedDocumentWithLegalKey,
  };
}
