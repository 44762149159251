// src/composables/useGoSolidApi.ts

import axios from "axios";
import { authStore } from "@/store/authStore";

axios.defaults.withCredentials = true;
const goSolidApi = axios.create();

if (process.env.NODE_ENV === "production") {
  goSolidApi.defaults.baseURL = "https://api.soldera.org";
} else {
  goSolidApi.defaults.baseURL = "http://127.0.0.1:5000";
}

// Request interceptor to add the auth token
goSolidApi.interceptors.request.use(
  async (config) => {
    const token = await authStore.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { goSolidApi };
