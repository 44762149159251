<template>
  <div class="card p-3 bg-secondary-subtle text-center border-0">
    <h5 v-if="title" class="card-title fs-7 fw-bold mb-2">{{ title }}</h5>
    <p v-if="subtitle" class="card-text fs-8 mb-2">{{ subtitle }}</p>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlaceholderCardComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
});
</script>

<style scoped></style>
