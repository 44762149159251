<!-- client/src/components/common/ModalComponent.vue -->

<template>
  <Transition name="fade">
    <div
      v-if="showModal"
      class="modal fade show"
      style="display: block; padding-right: 15px"
    >
      <div class="modal-backdrop fade show" @click="toggleModal"></div>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="verticallyCenteredModalLabel">
              <slot name="title"></slot>
            </h3>
            <button
              class="btn p-1"
              type="button"
              @click="toggleModal"
              aria-label="Close"
            >
              <font-awesome-icon icon="times" />
            </button>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-primary"
              type="button"
              @click="submit ? submit() : toggleModal()"
            >
              {{ submitButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InfoCardComponent",
  props: {
    submitButtonText: {
      type: String,
      required: true,
    },
    submit: {
      type: Function,
      default: null,
    },
  },
  setup(_, { emit }) {
    const showModal = ref(false);

    const toggleModal = () => {
      showModal.value = !showModal.value;
    };

    const forceToggleModal = (value: boolean) => {
      showModal.value = value;
    };

    // Expose the method
    emit("setForceToggle", forceToggleModal);

    return {
      showModal,
      toggleModal,
    };
  },
});
</script>

<style scoped lang="scss">
.modal-content {
  border-radius: 20px;
  overflow: hidden;
  .modal-header {
    //background-color: var(--phoenix-primary-bg-subtle);
    border: 0;
    .modal-title {
      //color: var(--phoenix-primary-text-emphasis);
      font-size: 1.5rem;
    }
  }
  .modal-body {
    padding-bottom: 0;
    padding-top: 0;
  }
  .accordion-item {
    border: 0;
  }
  .modal-footer {
    border: 0;
  }
}

.modal {
  display: block;
}

.modal-dialog {
  z-index: var(--phoenix-modal-zindex);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease-in;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
