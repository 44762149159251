// client/src/types/index.ts

export interface Account {
  [key: string]: any; // eslint-disable-line
  id?: number;
  email: string | null;
  name: string | null;
  registry_code: string | null;
  phone_number: string | null;
  has_registered: boolean;
  session_id: string | null;
  kilowatts: number;
  go_price: number;
  companies: Company[];
  internal_role?: string | null;
  admin_account_id?: number | null;
  conversion_tracking?: ConversionTracking;
  conversion_tracking_id?: number | null;
  trackings: ConversionTracking[];
  comment?: string | null;
}

export interface ConversionTracking {
  id: number;
  referrer_id?: number;
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  utm_campaign?: string;
  created_at: string;
}

export interface Company {
  [key: string]: any; // eslint-disable-line
  id?: number;
  name: string;
  address: string | null;
  registry_code: string | null;
  has_registered: boolean;
  generators: Generator[];
  documents: Document[];
  owner: Account;
  registry_devices: RegistryDevice[];
  min_6_month_price: number;
  vat: string | null;
  bank_account: string | null;
  payment_header: string;
  fee_model: string | null;
  registry_excluded_devices: string[];
  country: string;
  is_test_company: boolean;
  nda_signed_link?: string;
  legal_keys?: string[] | null;
}

export interface Generator {
  [key: string]: any; // eslint-disable-line
  id?: number;
  company_id?: number;
  grid_contract: string | null;
  solar_panel_model: string | null;
  has_subsidy: boolean | null;
  has_registered: boolean;
  device_eic: string | null;
  device_address: string | null;
  device_power: number | null;
  device_name: string | null;
  device_country: string | null;
  device_technology: string | null;
  grid_contract_start_date: string | null;
  license_number: string | null;

  subsidies: Subsidy[];

  battery: Battery | null;
  ppa: Ppa | null;
}

export interface Subsidy {
  [key: string]: any; // eslint-disable-line
  id?: number;
  generator_id?: number;
  provider: string;
  decision_date: string | null;
  plan: string | null;
  amount: number | null;
}

export interface Document {
  id?: number;
  company_id?: number;
  generator_id?: number;
  dokobit_token: string;
  name: string | null;
  signing_url: string | null;
  download_url: string;
  status: string | null;
  created_at: string | null;
  updated_at: string | null;
  has_grid_contract: boolean;
  has_authorization_contract: boolean;
  has_soldera_contract: boolean;
  document_signers: DocumentSigner[];
  contract_keys: string[];
  file_metadata?: FileMetadataMap | null;
  legal_keys?: string[] | null;
}

export interface DocumentSigner {
  id: number;
  document_id: number;
  account_id: number;
  role: string;
  token: string;
  redirect_url: string;
}

export type OwnerFieldModel = keyof OwnerDataType;
export interface UpdatedCompanyData {
  id: number | undefined;
  name: string;
  registry_code: string;
  address: string;
  owner: OwnerDataType;
}
export interface OwnerDataType {
  id: string | undefined;
  name: string;
  registry_code: string;
  phone_number: string;
}
export interface ContactFieldType {
  label: string;
  id: string;
  model: keyof Account | keyof Company; // Use the specific type here
  type: string;
  editable: boolean;
  validators: any;
}

export interface Step {
  title: string;
  isAvailable: boolean;
}

export interface Battery {
  id?: number;
  generator_id: number;
  kw: number | null;
  kwh: number | null;
}

export interface Ppa {
  id?: number;
  generator_id: number;

  start_date: string | null;
  mwh: number | null;
  client_company_name: number | null;
  country: string | null;
}

export interface InvoiceItem {
  id: number;
  created_at: string;
  updated_at: string;

  company_id: number;
  description: string;
  amount: number;
  unit_price: number;
  vat_fraction: number | null;
  expense_requested_at: string | null;
  expense_paid_at: string | null;
  expense_canceled_at: string | null;

  expense_invoice_id: number | null;
  fee_invoice_id: number | null;
}

export interface Invoice {
  id: number;
  created_at: string;
  updated_at: string;
  invoice_type: string;
  company_id: number;
  vat_number: string | null;
  bank_account: string | null;
  payment_header: string;
  invoice_number: string;
  requested_at: string | null;
  paid_at: string | null;
  canceled_at: string | null;
  expense_items: InvoiceItem[];
  fee_items: InvoiceItem[];
}

export interface ScheduledEmail {
  id: number;
  job_id: string;
  created_at: string;
  updated_at: string;
  scheduled_at: string;
  sent_at: string | null;
  status: string;
  account_id: number;
  company_id: number | null;
  email_type: string;
  email_metadata: { key: string; value: string };
  error_message: string | null;
}

export interface RegistryDevice {
  id: number;
  company_id: number;
  device_eic: string;
  device_name: string;
  device_power: number;
  support: string;
  certificates: Certificate[];
  registry_status: string;
  technology_code: string;
  technology: string;
}

export interface Certificate {
  id: number;
  transfer_deadline: string;
  transfer_deadline_passed: boolean;
  production_device_name: string;
  production_support: boolean;
  fuel_code: string;
  go_type: string;
  count: number;
  sales_transaction_item_id: number | null;
  company_id: number;
  production_month: string;
}

export interface SalesTransaction {
  id: number;
  transaction_at: string;
  unit_price: number;
  amount: number;
  description: string;
  counterparty_name: string;
  counterparty_country: string;
  offers_taken: number;
  min_price: number;
  transaction_link: string;
}

export interface PowerProjection {
  [month: number]: number;
}

export interface Forward {
  id?: number;
  name: string;
  comments?: string;
  buyer_company_id: number;
  buyer_name: string;
  unit_price: number;
  count: number;
  delivery_start: string;
  delivery_end: string;
  production_start: string;
  production_end: string;
  reserved_sum?: number;
}

export interface ForwardCondition {
  id?: number;
  forward_id: number;
  type: string;
  arguments: string;
}

export interface ForwardReservation {
  id?: number;
  forward_id: number;
  count: number;
  status: string;
  created_at: string;
  accepted_at?: string;
  transferred_at?: string;
  rejected_at?: string;
  company_id: number;
  company_name: string;
}

export interface ForwardSale {
  id: number;
  deliveryStart: string;
  deliveryEnd: string;
  committedMWh: number;
  deliveredMWh: number;
  pricePerMWh: number;
  productionStart: string;
  productionEnd: string;
  technology: string;
}

export interface ForwardServiceInstructions {
  id?: number;
  created_at: string | null;
  ended_at: string | null;
  q1_count: number;
  q2_count: number;
  q3_count: number;
  q4_count: number;
  minimum_price: number;
  skip_sale_if_not_contango: boolean;
  instructions: any;
}

export enum ForwardEnrollmentStatus {
  SIGNATURE_REQUIRED = 1,
  AVAILABLE = 2,
  SIGNATURE_PENDING = 3,
  ENROLLED = 4,
}

export interface CertificateWithTech extends Certificate {
  technology_code: string;
}

export interface FileMetadataMap {
  [key: string]: FileMetadataItem;
}

export interface FileMetadataItem {
  contract_key: string;
  title: string;
  description: string;
  file_url: string;
  legal_key: string;
}
