<!-- src/views/ForwardCertificateReservationsView.vue -->

<template>
  <div class="forward-certificate-reservations">
    <h1>Forward Certificate Reservations</h1>
    <button class="btn btn-secondary mb-3" @click="goBack">Back</button>
    <div v-if="loading">Loading...</div>

    <div v-else-if="error">{{ error }}</div>

    <div v-else>
      <h2>Forward Details</h2>
      <p>Name: {{ forward.name }}</p>
      <p>Buyer: {{ forward.buyer_name }}</p>
      <p>Count: {{ forward.count }}</p>
      <p>Unit Price: {{ forward.unit_price }}</p>
      <p>
        Production Period: {{ forward.production_start }} to
        {{ forward.production_end }}
      </p>

      <h2>Reservation Details</h2>
      <p>ID: {{ reservation.id }}</p>
      <p>Count: {{ reservation.count }}</p>
      <p>Status: {{ reservation.status }}</p>

      <h2>Possible Certificates</h2>
      <div class="table-cert">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Count</th>
              <th>Production Month</th>
              <th>Fuel Code</th>
              <th>Technology Code</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cert in possibleCertificates" :key="cert.id">
              <td>{{ cert.id }}</td>
              <td>{{ cert.count }}</td>
              <td>{{ formatDate(cert.production_month) }}</td>
              <td>{{ cert.fuel_code }}</td>
              <td>{{ cert.technology_code }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { goSolidApi } from "@/composables/useGoSolidApi";
import type { Forward, CertificateWithTech, ForwardReservation } from "@/types";
export default {
  name: "ForwardCertificateReservationsView",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const reservationId = route.params.reservation_id;
    const _forwardId = route.params.forward_id;

    const forward = ref<Forward>({} as Forward);
    const reservation = ref<ForwardReservation>({} as ForwardReservation);
    const possibleCertificates = ref<CertificateWithTech[]>([]);
    const loading = ref(true);
    const error = ref<string | null>(null);

    const fetchData = async () => {
      try {
        const reservation_req = await goSolidApi.get(
          `/api/admin/forward-reservation/0/${reservationId}`
        );
        reservation.value = reservation_req.data;
        const forwardId = reservation_req.data.forward_id;
        const forward_req = await goSolidApi.get(
          `/api/admin/forward/${forwardId}`
        );
        forward.value = forward_req.data;
        const possibleCertificates_req = await goSolidApi.get(
          `/api/admin/forward-reservation/${reservationId}/available-certificates`
        );
        possibleCertificates.value = possibleCertificates_req.data;
        loading.value = false;
      } catch (err) {
        error.value = "An error occurred while fetching data";
        loading.value = false;
        console.error(err);
      }
    };

    const formatDate = (dateString: string) => {
      return new Date(dateString).toLocaleDateString();
    };

    const goBack = () => {
      router.go(-1);
    };

    onMounted(fetchData);

    return {
      forward,
      reservation,
      possibleCertificates,
      loading,
      error,
      formatDate,
      goBack,
    };
  },
};
</script>

<style scoped>
.forward-certificate-reservations {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  overflow-y: auto;
}

.table-cert {
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-y: scroll;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
</style>
