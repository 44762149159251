<!-- client/src/components/common/GeneratorCardComponent.vue -->
<template>
  <div class="card mb-2 generator-card">
    <div class="card-body">
      <div>
        <h2 class="accordion-header" :id="'heading' + generator.id">
          <a
            href="#"
            class="collapsed"
            type="accordion-button button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse' + generator.id"
            aria-expanded="false"
            :aria-controls="'collapse' + generator.id"
          >
            <div
              class="card-title"
              v-if="generatorStatusState === GeneratorStatus.Unregistered"
            >
              {{ $t("document.unfinished_document") }}
            </div>
            <div class="card-title" v-else>
              {{ generatorTitle }}
            </div>
          </a>
        </h2>
        <div class="card-subtitle mb-3">
          <span :class="generatorStatusClass">
            {{ $t(`generator.status.${generatorStatusState}`) }}
          </span>
        </div>
        <div class="mb-1">
          <a
            :href="`/signup?step=2&generator_id=${generator.id}&company_id=${generator.company_id}`"
            class="btn btn-lg btn-primary"
            v-if="generatorStatusState === GeneratorStatus.Unregistered"
          >
            <font-awesome-icon class="px-1" icon="play" />
            {{ $t("generator.continue_registration") }}
          </a>
          <a
            :href="generatorDocumentToSign"
            class="btn btn-lg btn-primary"
            v-if="
              generatorStatusState === GeneratorStatus.PendingSignature &&
              generatorDocumentToSign
            "
            target="_blank"
          >
            <font-awesome-icon class="px-1" icon="signature" />
            {{ $t("generator.sign_document") }}
          </a>
        </div>
        <div
          :id="'collapse' + generator.id"
          class="accordion-collapse collapse"
          :aria-labelledby="'heading' + generator.id"
        >
          <div v-if="hasNonEmptyProperties" class="card-text">
            <div class="accordion border rounded p-2">
              <div class="accordion-item">
                <div class="accordion-body">
                  <div
                    v-for="propval in filteredPropValues"
                    :key="propval.property"
                    class="generator-segment"
                  >
                    <div class="generator-property">
                      {{ $t(`generator.properties.${propval.property}`) }}:
                    </div>
                    <div class="generator-value">{{ propval.value }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Admin Section -->
          <div v-if="is_admin" class="mt-3">
            <button class="btn btn-link btn-sm p-0" @click="toggleAdminSection">
              {{
                showAdminSection
                  ? $t("common.hide_admin_options")
                  : $t("common.show_admin_options")
              }}
            </button>
            <div
              v-if="showAdminSection"
              class="mt-2 alert alert-subtle-warning text-center admin-extra-info"
            >
              <a
                :href="`/signup?step=2&generator_id=${generator.id}&company_id=${generator.company_id}`"
                class="btn btn-outline-warning btn-sm"
              >
                {{ $t("common.edit") }}
              </a>
              <p class="mb-0 mt-2">Generator ID: {{ generator.id }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import type { Generator, Document } from "../../types";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

enum GeneratorStatus {
  Unregistered = 1,
  PendingSignature = 2,
  Registered = 3,
}

export default defineComponent({
  name: "GeneratorCardComponent",
  props: {
    generator: {
      type: Object as PropType<Generator>,
      required: true,
    },
    account_id: {
      type: Number,
      required: true,
    },
    generatorDocuments: {
      type: Array as PropType<Document[]>,
      required: true,
    },
    is_admin: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const showDocuments = ref(false);
    const showAdminSection = ref(false);

    const toggleAdminSection = () => {
      showAdminSection.value = !showAdminSection.value;
    };

    const generatorTitle = computed(() => {
      if (props.generator.device_eic) {
        return t("registry_device.device_code", {
          code: props.generator.device_eic,
        });
      } else if (props.generator.license_number) {
        return t("dashboard.generator.license_number", {
          license_number: props.generator.license_number,
        });
      } else {
        return t("generator.unnamed_device");
      }
    });

    const prop_values = computed(() => [
      {
        property: "device_name",
        value: props.generator.device_name,
      },
      {
        property: "solar_panel_model",
        value: props.generator.solar_panel_model,
      },
      {
        property: "device_address",
        value: props.generator.device_address,
      },
      {
        property: "device_power",
        value: props.generator.device_power
          ? `${props.generator.device_power} kW`
          : null,
      },
      {
        property: "battery",
        value: props.generator.battery
          ? `${props.generator.battery.kw} kW, ${props.generator.battery.kwh} KWh`
          : null,
      },
      {
        property: "subsidies",
        value:
          props.generator.subsidies.length > 0
            ? props.generator.subsidies
                .map((subsidy) => {
                  let subsidyDetails = [
                    subsidy.provider,
                    subsidy.decision_date
                      ? t("common.date", { date: subsidy.decision_date })
                      : "",
                    subsidy.plan,
                    subsidy.amount ? `${subsidy.amount}€` : "",
                  ]
                    .filter((detail) => detail)
                    .join(", ");
                  return subsidyDetails;
                })
                .join("; ")
            : null,
      },
    ]);

    const filteredPropValues = computed(() =>
      prop_values.value.filter(
        (propval) => propval.value != null && propval.value !== ""
      )
    );
    const hasNonEmptyProperties = computed(
      () => filteredPropValues.value.length > 0
    );

    const generatorStatusState = computed((): GeneratorStatus => {
      if (
        props.generatorDocuments.some(
          (document: Document) => document.status !== "completed"
        )
      ) {
        return GeneratorStatus.PendingSignature;
      } else if (props.generator.has_registered) {
        return GeneratorStatus.Registered;
      } else {
        return GeneratorStatus.Unregistered;
      }
    });

    const generatorStatusClass = computed(() => {
      const statusClasses = {
        [GeneratorStatus.Unregistered]:
          "badge bg-danger-subtle text-danger-emphasis",
        [GeneratorStatus.PendingSignature]:
          "badge bg-danger-subtle text-warning-emphasis",
        [GeneratorStatus.Registered]:
          "badge bg-success-subtle text-success-emphasis",
      };
      return statusClasses[generatorStatusState.value];
    });

    const generatorDocumentToSign = computed(() => {
      const documentToSign = props.generatorDocuments.find(
        (document: Document) => document.status !== "completed"
      );
      if (documentToSign) {
        return documentToSign.document_signers.find(
          (signer) => signer.account_id === props.account_id
        )?.redirect_url;
      } else {
        return null;
      }
    });

    return {
      prop_values,
      generatorStatusState,
      generatorStatusClass,
      generatorDocumentToSign,
      GeneratorStatus,
      showDocuments,
      showAdminSection,
      toggleAdminSection,
      filteredPropValues,
      generatorTitle,
      hasNonEmptyProperties,
    };
  },
});
</script>

<style scoped>
.generator-card {
  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .generator-segment {
    .generator-property {
      font-weight: 600;
      color: var(--phoenix-secondary-text-emphasis);
    }
    .generator-value {
      margin-left: 1rem;
    }
    .badge {
      margin-left: 1rem;
    }
  }
  .accordion > .accordion-item:last-child {
    border-bottom: none;
  }
}
.admin-extra-info {
  padding: 0;
}
</style>
