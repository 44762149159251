// src/composables/useGeneratorFinder.ts

import { computed } from "vue";
import { useRoute } from "vue-router";
import type { Account, Generator } from "@/types";
import { useCompanyFinder } from "./useCompanyFinder";

export function useGeneratorFinder(accountData: Account) {
  const route = useRoute();
  const { companyIndex } = useCompanyFinder(accountData);

  const generatorID = computed(() => {
    return parseInt(route.query.generator_id as string);
  });

  const generatorIndex = computed(() => {
    if (companyIndex.value === -1 || !accountData.companies) {
      return -1;
    }
    return accountData.companies[companyIndex.value].generators.findIndex(
      (generator: Generator) => generator.id === generatorID.value
    );
  });

  const nullableSelectedGenerator = computed(() => {
    if (
      !accountData ||
      !accountData.companies ||
      Number.isNaN(generatorID.value)
    ) {
      return { generator: null };
    }
    return selectedGenerator.value;
  });

  const selectedGenerator = computed(() => {
    if (
      accountData &&
      accountData.companies &&
      accountData.companies[companyIndex.value]
    ) {
      return {
        generator:
          accountData.companies[companyIndex.value].generators[
            generatorIndex.value
          ],
      };
    }
    throw new Error("Generator not found");
  });

  return {
    selectedGenerator,
    nullableSelectedGenerator,
    generatorID,
    generatorIndex,
  };
}
