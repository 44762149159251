// src/composables/useValidators.ts
import * as validators from "@vuelidate/validators";
import { Composer } from "vue-i18n"; // import the Composer type
import { Ref, ComputedRef } from "vue";

// Function to validate Estonian Personal ID checksum
const isValidEstonianPersonalIDChecksum = (value: string) => {
  if (!value || value.length !== 11) return false;

  const weightsFirstRound = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
  const weightsSecondRound = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];

  let sum = 0;

  // Calculate the checksum using the first set of weights
  for (let i = 0; i < 10; i++) {
    sum += parseInt(value[i], 10) * weightsFirstRound[i];
  }

  let checkNumber = sum % 11;

  // If the modulo is 10, we use the second set of weights
  if (checkNumber === 10) {
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(value[i], 10) * weightsSecondRound[i];
    }
    checkNumber = sum % 11;

    // If the modulo is still 10, we set the check number to 0
    if (checkNumber === 10) {
      checkNumber = 0;
    }
  }

  // The check number must match the last digit of the ID
  return checkNumber === parseInt(value[10], 10);
};

// Validator for Estonian Personal ID (Isikukood)
const isValidEstonianPersonalID = (value: string) => {
  if (!value) return true; // consider empty values as valid. Use in combination with required
  const pattern = /^[1-6][0-9]{2}[0,1][0-9][0-3][0-9]{5}$/;
  return pattern.test(value) && isValidEstonianPersonalIDChecksum(value);
};

const isValidLatvianPersonalId = (idCode: string) => {
  // const pattern = /^\d{6}-\d{5}$/;
  const pattern = /^[0-3][0-9][01][0-9]{3}-[0-9]{5}$/;

  return pattern.test(idCode) && isValidLatvianPersonalIDChecksum(idCode);
};

const isValidLatvianPersonalIDChecksum = (idCode: string) => {
  const weights = [1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const digits = idCode.replace("-", "");
  let total = 0;
  for (let i = 0; i < 10; i++) {
    total += parseInt(digits[i]) * weights[i];
  }
  const checkDigit = ((1101 - total) % 11) % 10;
  return parseInt(digits[10]) === checkDigit;
};

// Validator for Estonian Company Registration Code (Registrikood)
const isValidEstonianCompanyCode = (value: string) => {
  if (!value) return true; // consider empty values as valid. Use in combination with required
  return /^[1-9][0-9]{7}$/.test(value);
};

// Validator for Latvian Company Registration Number (URN)
const isValidLatvianCompanyNumber = (value: string) => {
  if (!value) return true; // consider empty values as valid. Use in combination with required
  return /^\d{11}$/.test(value);
};

// Validator for Lithuanian Company Registration Number
const isValidLithuanianCompanyNumber = (value: string) => {
  if (!value) return true; // consider empty values as valid. Use in combination with required
  return /^\d{9}$/.test(value);
};

const isReasonableDate = (dateString: string) => {
  const date = new Date(dateString);
  const startDate = new Date("1990-01-01");
  const now = new Date();

  if (isNaN(date.getTime())) {
    // date.getTime() is NaN if the date is invalid
    return false;
  }

  return date >= startDate && date <= now;
};

export const isValidIban = (value: string) => {
  if (!value) return true; // consider empty values as valid. Use in combination with required
  return smellsLikeIban(value) && validateIbanChecksum(value);
};

function smellsLikeIban(str: string) {
  return /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/.test(
    str
  );
}

// FROM https://stackoverflow.com/questions/44656264/iban-regex-design
function validateIbanChecksum(iban: string) {
  const ibanStripped = iban
    .replace(/[^A-Z0-9]+/gi, "") //keep numbers and letters only
    .toUpperCase(); //calculation expects upper-case
  const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
  if (!m) return false;

  const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, function (ch) {
    //replace upper-case characters by numbers 10 to 35
    return (ch.charCodeAt(0) - 55).toString();
  });
  //The resulting number would be to long for javascript to handle without loosing precision.
  //So the trick is to chop the string up in smaller parts.

  const match = numbericed.match(/\d{1,7}/g);
  if (match === null) {
    return false;
  }
  const mod97 = match.reduce(function (total, curr) {
    return Number(total + curr) % 97;
  }, 0);

  return mod97 === 1;
}

const isValidEstonianVATNumber = (value: string) => {
  if (!value) return true; // consider empty values as valid. Use in combination with required
  return /^(EE)?[0-9]{9}$/.test(value);
};
const isValidLatvianVATNumber = (value: string) => {
  if (!value) return true; // consider empty values as valid. Use in combination with required
  return /^(LV)?[0-9]{11}$/.test(value);
};
const isValidLithuanianVATNumber = (value: string) => {
  if (!value) return true; // consider empty values as valid. Use in combination with required
  // Lithuanian VAT numbers can be 9 or 12 digits long lol
  return /^(LT)?[0-9]{9,12}$/.test(value);
};

const namePattern =
  /^[A-Za-z\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF]{2,}(?:[\s-]+[A-Za-z\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF]{2,})+$/;

const phonePattern =
  /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-/\s.]|\d)+$/;

const estonianDeviceEICPattern = /^38ZEE-\d{8}-[A-Z0-9]$/;

export function useValidators(t: Composer["t"]) {
  const validatorIf = (condition: ComputedRef<boolean>, validator: any) =>
    validators.helpers.withMessage(
      validator.$message,
      (value) => !condition.value || validator.$validator(value)
    );

  // Wrap the validators with the translation function
  const required = validators.helpers.withMessage(
    () => t("validations.required"),
    validators.required
  );

  const requiredIf = (condition: any) =>
    validators.helpers.withMessage(
      () => t("validations.required"),
      validators.requiredIf(condition)
    );

  const minLength = (length: number) =>
    validators.helpers.withMessage(
      () => t("validations.minLength", { min: length }),
      validators.minLength(length)
    );
  const maxLength = (length: number) =>
    validators.helpers.withMessage(
      () => t("validations.maxLength", { max: length }),
      validators.maxLength(length)
    );

  const alpha = validators.helpers.withMessage(
    () => t("validations.alpha"),
    validators.alpha
  );

  const estonianPersonalID = validators.helpers.withMessage(
    () => t("validations.estonianPersonalID"),
    isValidEstonianPersonalID
  );

  const latvianPersonalID = validators.helpers.withMessage(
    () => t("validations.latvianPersonalID"),
    isValidLatvianPersonalId
  );

  const estonianCompanyCode = validators.helpers.withMessage(
    () => t("validations.estonianCompanyCode"),
    isValidEstonianCompanyCode
  );

  const estonianDeviceEIC = validators.helpers.withMessage(
    () => t("validations.estonianDeviceEIC"),
    validators.helpers.regex(estonianDeviceEICPattern)
  );

  const estonianVATNumber = validators.helpers.withMessage(
    () => t("validations.estonianVATNumber"),
    isValidEstonianVATNumber
  );
  const notExampleEIC = validators.helpers.withMessage(
    () => t("validations.notExampleEIC"),
    validators.not(validators.helpers.regex(/^38ZEE-12345678-X$/))
  );

  const estonianIDorCompanyCode = validators.helpers.withMessage(
    () => t("validations.estonianIDorCompanyCode"),
    validators.or(estonianCompanyCode, estonianPersonalID)
  );

  const latvianIDorLatvianRegistryCode = validators.helpers.withMessage(
    () => t("validations.latvianIDorRegistryCode"),
    validators.or(isValidLatvianCompanyNumber, latvianPersonalID)
  );

  const latvianIDorLatvianVATNumber = validators.helpers.withMessage(
    () => t("validations.latvianIDorVATNumber"),
    validators.or(isValidLatvianVATNumber, latvianPersonalID)
  );

  const latvianVATNumber = validators.helpers.withMessage(
    () => t("validations.latvianVATNumber"),
    isValidLatvianVATNumber
  );
  const latviaCompanyNumber = validators.helpers.withMessage(
    () => t("validations.latvianCompanyNumber"),
    isValidLatvianCompanyNumber
  );

  const lithuanianCompanyNumber = validators.helpers.withMessage(
    () => t("validations.lithuanianCompanyNumber"),
    isValidLithuanianCompanyNumber
  );

  const lithuanianVATNumber = validators.helpers.withMessage(
    () => t("validations.lithuanianVATNumber"),
    isValidLithuanianVATNumber
  );

  const latvianYourPersonalIDifID = (your_id: any) =>
    validators.helpers.withMessage(
      () => t("validations.latvianYourPersonalIDifID"),
      validators.or(
        validators.not(latvianPersonalID),
        validators.sameAs(your_id)
      )
    );

  const estonianYourPersonalIDifID = (your_id: any) =>
    validators.helpers.withMessage(
      () => t("validations.estonianYourPersonalIDifID"),
      validators.or(
        validators.not(estonianPersonalID),
        validators.sameAs(your_id)
      )
    );

  const personalName = validators.helpers.withMessage(
    () => t("validations.personalName"),
    validators.helpers.regex(namePattern)
  );

  const phoneNumber = validators.helpers.withMessage(
    () => t("validations.phoneNumber"),
    validators.helpers.regex(phonePattern)
  );

  const someSubsidies = (subsidy_list: Ref<any[]>) =>
    validators.helpers.withMessage(
      () => t("validations.someSubsidies"),
      (has_subsidies: any) => !has_subsidies || subsidy_list.value.length > 0
    );

  const generatorExists = validators.helpers.withMessage(
    () => t("validations.generatorExists"),
    (value: boolean) => value
  );

  const fileRequired = validators.helpers.withMessage(
    () => t("validations.fileRequired"),
    validators.required
  );

  const reasonableDate = validators.helpers.withMessage(
    () => t("validations.reasonableDate"),
    isReasonableDate
  );

  const iban = validators.helpers.withMessage(
    () => t("validations.iban"),
    isValidIban
  );

  // Return the wrapped validators
  return {
    required,
    validatorIf,
    requiredIf,
    minLength,
    maxLength,
    alpha,
    estonianPersonalID,
    estonianCompanyCode,
    estonianDeviceEIC,
    estonianIDorCompanyCode,
    latvianIDorLatvianRegistryCode,
    estonianYourPersonalIDifID,
    isValidLatvianPersonalId,
    latvianYourPersonalIDifID,
    estonianVATNumber,
    lithuanianCompanyNumber,
    lithuanianVATNumber,
    latviaCompanyNumber,
    latvianVATNumber,
    latvianIDorLatvianVATNumber,
    notExampleEIC,
    personalName,
    phoneNumber,
    someSubsidies,
    generatorExists,
    fileRequired,
    reasonableDate,
    iban,
  };
}
