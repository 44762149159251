<template>
  <div
    v-if="isDebugEnabled"
    class="account-data-debug p-3 bg-light border rounded"
  >
    <pre>{{ prettyAccountData }}</pre>
    <!-- Force Reset Button -->
    <button class="btn btn-warning w-100 mt-2" @click="handleResetAccountData">
      Force Reset Account Data
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, computed } from "vue";
import type { Account } from "../../types";
import { useRoute } from "vue-router";
import { useSubmitAccountData } from "@/composables/useSubmitAccountData";

export default defineComponent({
  name: "AccountDataDebugComponent",
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { accountData } = toRefs(props);
    const route = useRoute();
    const prettyAccountData = computed(() =>
      JSON.stringify(accountData.value, null, 2)
    );
    const { submitAccountData } = useSubmitAccountData();
    const resetAccountData = () => {
      emit("resetAccountData");
    };
    const handleResetAccountData = async () => {
      // Retain id and email, reset other fields
      const updatedAccountData = {
        ...props.accountData,
        name: "",
        registry_code: "",
        phone_number: "",
        has_registered: false,
      };
      await submitAccountData(updatedAccountData, "/api/onboarding/signup");
    };
    const isDebugEnabled = computed(() => {
      return route.query.debug === "true";
    });

    return {
      prettyAccountData,
      resetAccountData,
      isDebugEnabled,
      handleResetAccountData,
    };
  },
});
</script>

<style lang="scss" scoped>
.account-data-debug {
  margin-top: 1rem;
  margin-bottom: 1rem;
  pre {
    font-family: monospace;
    white-space: pre-wrap;
    text-align: left; /* Align text to the left */
    tab-size: 4; /* Indentation size */
    max-height: auto; /* Dynamic height based on content */
    overflow-y: auto;
  }
  button {
    margin-top: 1rem;
  }
}
</style>
