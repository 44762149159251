// src/composables/useSubmitAccountData.ts
import { inject, ref } from "vue";
import { Account } from "../types";
import { goSolidApi } from "./useGoSolidApi";

export function useSubmitAccountData() {
  const isSubmitting = ref(false);
  const errorMessage = ref<string | null>(null);
  const globalErrorHandler = inject("globalErrorHandler") as (
    error: any
  ) => void;

  const submitAccountData = async (accountData: Account, submitUrl: string) => {
    try {
      isSubmitting.value = true;
      errorMessage.value = null;
      const response = await goSolidApi.post(submitUrl, {
        accountData: accountData,
      });
      //eslint-disable-next-line @typescript-eslint/no-explicit-any

      if (response.status === 200) {
        Object.assign(accountData, response.data.accountData);
      } else {
        throw new Error("Error submitting account data");
      }
    } catch (error: any) {
      errorMessage.value = error.message || "Unknown error occurred";
      globalErrorHandler(error.response.data);
    } finally {
      isSubmitting.value = false;
    }
  };

  return { isSubmitting, errorMessage, submitAccountData };
}
