import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4203cd6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "price-terms" }
const _hoisted_2 = { class: "fs-7 fw-bold mb-3" }
const _hoisted_3 = { ref: "chartRef" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("certificateHistory.overview")), 1),
    _createElementVNode("canvas", _hoisted_3, null, 512)
  ]))
}