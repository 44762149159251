<!-- client/src/views/admin/forwards/ForwardReservationsView.vue -->

<template>
  <div id="ForwardReservationsView" class="container">
    <button class="btn btn-secondary mb-3" @click="goBack">Back</button>
    <h2>Reservations for Forward: {{ forward.name }}</h2>
    <h4>
      Forward needs {{ forward.count }} units, currently reserved
      {{ reservations.reduce((acc, r) => acc + r.count, 0) }} units
    </h4>

    <!-- List of existing reservations -->
    <div class="table-container table-responsive mb-4">
      <table class="table table-hover align-middle">
        <thead>
          <tr>
            <th>ID</th>
            <th>Count</th>
            <th>Status</th>
            <th>Company ID</th>
            <th>Company Name</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="reservation in reservations" :key="reservation.id">
            <td>{{ reservation.id }}</td>
            <td>{{ reservation.count }}</td>
            <td>{{ reservation.status }}</td>
            <td>{{ reservation.company_id }}</td>
            <td>{{ reservation.company_name }}</td>
            <td>{{ new Date(reservation.created_at).toLocaleString() }}</td>
            <td>
              <button
                class="btn btn-primary btn-sm me-2"
                @click="editReservation(reservation)"
              >
                Edit
              </button>
              <button
                class="btn btn-danger btn-sm me-2"
                @click="deleteReservation(reservation.id)"
              >
                Delete
              </button>
              <button
                class="btn btn-info btn-sm"
                @click="openCertificateReservations(reservation.id)"
              >
                Reserve Certificates
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Form for adding/editing reservations -->
    <div class="card mb-4">
      <div class="card-body">
        <h3>
          {{ editingReservation ? "Edit Reservation" : "Add New Reservation" }}
        </h3>
        <form @submit.prevent="submitReservation">
          <div class="mb-3">
            <label for="count" class="form-label">Count</label>
            <input
              v-model="currentReservation.count"
              type="number"
              class="form-control"
              id="count"
              required
            />
          </div>
          <div class="mb-3">
            <label for="company_id" class="form-label">Company ID</label>
            <select
              v-model="currentReservation.company_id"
              class="selectpicker form-control"
              data-live-search="true"
              id="company_id"
              required
            >
              <option value="">Select a company</option>
              <option v-for="(id, name) in companies" :key="id" :value="id">
                {{ name }} ({{ id }})
              </option>
            </select>
          </div>
          <button type="submit" class="btn btn-primary">
            {{ editingReservation ? "Update" : "Add" }} Reservation
          </button>
          <button
            type="button"
            class="btn btn-secondary ms-2"
            @click="resetForm"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { goSolidApi } from "@/composables/useGoSolidApi";
import type { Forward, ForwardReservation } from "@/types";

export default defineComponent({
  name: "AdminForwardReservationsView",

  setup() {
    const route = useRoute();
    const router = useRouter();
    const forward = ref<Forward>({} as Forward);
    const reservations = ref<ForwardReservation[]>([]);
    const currentReservation = ref<Partial<ForwardReservation>>({});
    const editingReservation = ref(false);
    const forwardId = route.params.forward_id;
    const loadForwardAndReservations = async () => {
      try {
        const forwardResponse = await goSolidApi.get(
          `/api/admin/forward/${forwardId}`
        );
        forward.value = forwardResponse.data;

        const reservationsResponse = await goSolidApi.get(
          `/api/admin/forward-reservation/${forwardId}/`
        );
        reservations.value = reservationsResponse.data;
      } catch (error) {
        console.error("Error loading forward and reservations:", error);
      }
    };

    const companies = ref<{ [key: string]: number }>({});

    const loadCompanies = async () => {
      try {
        const forwardId = route.params.forward_id;
        const response = await goSolidApi.get(
          `/api/admin/forward/${forwardId}/qualifying-companies`
        );
        companies.value = response.data.reduce(
          (
            acc: { [key: string]: number },
            company: { name: string; id: number }
          ) => {
            acc[company.name] = company.id;
            return acc;
          },
          {}
        );
      } catch (error) {
        console.error("Error loading companies:", error);
      }
    };

    const submitReservation = async () => {
      try {
        if (editingReservation.value) {
          await goSolidApi.put(
            `/api/admin/forward-reservation/${forward.value.id}/${currentReservation.value.id}`,
            currentReservation.value
          );
        } else {
          await goSolidApi.post(
            `/api/admin/forward-reservation/${forward.value.id}/`,
            currentReservation.value
          );
        }
        await loadForwardAndReservations();
        resetForm();
      } catch (error) {
        console.error("Error submitting reservation:", error);
      }
    };

    const editReservation = (reservation: ForwardReservation) => {
      currentReservation.value = { ...reservation };
      editingReservation.value = true;
    };

    const deleteReservation = async (id: number | undefined) => {
      if (id === undefined) {
        console.error("trying to delete reservation with undefined id");
        return;
      }
      if (confirm("Are you sure you want to delete this reservation?")) {
        try {
          await goSolidApi.delete(
            `/api/admin/forward-reservation/${forward.value.id}/${id}`
          );
          await loadForwardAndReservations();
        } catch (error) {
          console.error("Error deleting reservation:", error);
        }
      }
    };

    const openCertificateReservations = (reservationId: number | undefined) => {
      if (reservationId === undefined) {
        console.error(
          "trying to open certificate reservations with undefined id"
        );
        return;
      }
      router.push({
        name: "ForwardCertificateReservationsView",
        params: { reservation_id: reservationId.toString() },
      });
    };

    const goBack = () => {
      router.back();
    };
    const resetForm = () => {
      currentReservation.value = {};
      editingReservation.value = false;
    };

    onMounted(() => {
      loadForwardAndReservations();
      loadCompanies();
    });

    return {
      forward,
      reservations,
      currentReservation,
      editingReservation,
      submitReservation,
      editReservation,
      deleteReservation,
      resetForm,
      companies,
      goBack,
      openCertificateReservations,
    };
  },
});
</script>
