import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5723c08c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal fade show",
  style: {"display":"block","padding-right":"15px"}
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  class: "modal-title",
  id: "verticallyCenteredModalLabel"
}
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.showModal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "modal-backdrop fade show",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleModal && _ctx.toggleModal(...args)))
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("h3", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                  ]),
                  _createElementVNode("button", {
                    class: "btn p-1",
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleModal && _ctx.toggleModal(...args))),
                    "aria-label": "Close"
                  }, [
                    _createVNode(_component_font_awesome_icon, { icon: "times" })
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("button", {
                    class: "btn btn-primary",
                    type: "button",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submit ? _ctx.submit() : _ctx.toggleModal()))
                  }, _toDisplayString(_ctx.submitButtonText), 1)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}