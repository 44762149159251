<template>
  <div class="container py-5 overflow-y-auto">
    <h1 class="mb-4">Forwards Instructions Summary</h1>
    <div class="mb-4">
      <!-- Add your content here -->
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="contangoCheckbox"
          v-model="marketInContango"
        />
        <label class="form-check-label" for="contangoCheckbox"
          >Market is currently in contango</label
        >
      </div>
    </div>
    <button class="btn btn-primary mb-3" @click="handleButtonClick">
      Reload Data
    </button>
    <table class="table table-bordered mt-4">
      <thead>
        <tr>
          <th>Name</th>
          <th>Q1 Count</th>
          <th>Q2 Count</th>
          <th>Q3 Count</th>
          <th>Q4 Count</th>
          <th>Skip Sale If Not Contango and 20% above spot</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Sum</td>
          <td>{{ totalQ1 }}</td>
          <td>{{ totalQ2 }}</td>
          <td>{{ totalQ3 }}</td>
          <td>{{ totalQ4 }}</td>
          <td>-</td>
        </tr>
        <tr v-for="item in data" :key="item.id">
          <td>{{ item.company_name }}</td>
          <td>{{ item.q1_count }}</td>
          <td>{{ item.q2_count }}</td>
          <td>{{ item.q3_count }}</td>
          <td>{{ item.q4_count }}</td>
          <td>{{ item.skip_sale_if_not_contango ? "Yes" : "No" }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";

export default defineComponent({
  name: "ForwardsInstructionsSummaryView",
  setup() {
    const data = ref<any[]>([]);
    const marketInContango = ref<boolean>(true);

    const fetchData = async () => {
      try {
        const response = await goSolidApi.get("/api/admin/forward-summary/");
        if (response.status === 200 && response.data) {
          data.value = response.data;
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(() => {
      fetchData();
    });

    const handleButtonClick = () => {
      fetchData();
    };

    const totalQ1 = computed(() =>
      data.value.reduce(
        (sum, item) =>
          marketInContango.value || !item.skip_sale_if_not_contango
            ? sum + item.q1_count
            : sum,
        0
      )
    );
    const totalQ2 = computed(() =>
      data.value.reduce(
        (sum, item) =>
          marketInContango.value || !item.skip_sale_if_not_contango
            ? sum + item.q2_count
            : sum,
        0
      )
    );
    const totalQ3 = computed(() =>
      data.value.reduce(
        (sum, item) =>
          marketInContango.value || !item.skip_sale_if_not_contango
            ? sum + item.q3_count
            : sum,
        0
      )
    );
    const totalQ4 = computed(() =>
      data.value.reduce(
        (sum, item) =>
          marketInContango.value || !item.skip_sale_if_not_contango
            ? sum + item.q4_count
            : sum,
        0
      )
    );

    return {
      handleButtonClick,
      data,
      totalQ1,
      totalQ2,
      totalQ3,
      totalQ4,
      marketInContango,
    };
  },
});
</script>

<style scoped>
/* Add custom styles here */
</style>
