import { inject } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";

export function useDownloadDocument() {
  const globalErrorHandler = inject("globalErrorHandler") as (
    error: any
  ) => void;

  const downloadDocument = async (token: string) => {
    try {
      const response = await goSolidApi.get(`/api/document-download/${token}`, {
        responseType: "blob",
      });
      if (response.status === 200) {
        const contentDisposition = response.headers["content-disposition"];

        let filename = "DownloadedDocument";
        if (contentDisposition) {
          const filenameMatch =
            contentDisposition.match(/filename="?(.+?)"?$/i);
          if (filenameMatch) {
            filename = filenameMatch[1].replace(/['"]/g, "").trim();
          }
        }

        const mimeType = response.headers["content-type"];
        const fileBlob = new Blob([response.data], { type: mimeType });
        const fileURL = window.URL.createObjectURL(fileBlob);

        const downloadLink = document.createElement("a");
        downloadLink.href = fileURL;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(fileURL);
      } else {
        throw new Error("Error downloading the document");
      }
    } catch (error: any) {
      globalErrorHandler(error.response.data);
    }
  };

  return { downloadDocument };
}
