import numeral from "numeral";

export function formatDate(dateString: string | null | undefined) {
  if (!dateString) {
    return ""; // Return a default text if dateString is null or undefined
  }

  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
}

export function formatDateTime(dateString: string | null | undefined | Date) {
  if (!dateString) {
    return ""; // Return a default text if dateString is null or undefined
  }
  // Parse the date string into a Date object if it is not already a Date object
  const date = dateString instanceof Date ? dateString : new Date(dateString);

  // Extract the day, month, and year from the Date object
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Format the date as DD/MM/YYYY
  return `${day}/${month}/${year}`;
}

export function formatMoney(value: number) {
  if (value === null || value === undefined) {
    return "n/a"; // Return a default text if value is null or undefined
  }
  return numeral(value).format("0,0.00");
}
