import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15ff27f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav nav-pills mb-2 mb-sm-3 mb-xl-5 nav-fill" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "nav-item",
        key: index
      }, [
        _createElementVNode("a", {
          class: _normalizeClass(["nav-link", {
          active: _ctx.signupStep === index + 1,
          disabled: !step.isAvailable || _ctx.signupStep === index + 1,
        }]),
          onClick: _withModifiers(($event: any) => (_ctx.setParentStep(index + 1)), ["prevent"]),
          role: "button"
        }, _toDisplayString(step.title), 11, _hoisted_2)
      ]))
    }), 128))
  ]))
}