import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vModelRadio as _vModelRadio, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c0a5f94"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "generator-review card p-4 mb-3",
  novalidate: ""
}
const _hoisted_2 = { class: "mb-1 mb-sm-4" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "col-sm-8 col-10" }
const _hoisted_5 = ["type", "id", "disabled", "onUpdate:modelValue", "onBlur"]
const _hoisted_6 = { class: "col-sm-1 col-2" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "mb-1 mb-sm-4 mt-4" }
const _hoisted_9 = { class: "row mb-2 g-2" }
const _hoisted_10 = {
  for: "device-technology",
  class: "col-sm-12 pb-0 col-form-label"
}
const _hoisted_11 = { class: "col-sm-11" }
const _hoisted_12 = {
  class: "form-check",
  role: "button"
}
const _hoisted_13 = {
  class: "form-check-label",
  for: "device-technology-solar",
  role: "button"
}
const _hoisted_14 = {
  class: "form-check",
  role: "button"
}
const _hoisted_15 = {
  class: "form-check-label",
  for: "device-technology-wind",
  role: "button"
}
const _hoisted_16 = {
  class: "form-check",
  role: "button"
}
const _hoisted_17 = {
  class: "form-check-label",
  for: "device-technology-hydro",
  role: "button"
}
const _hoisted_18 = {
  class: "form-check",
  role: "button"
}
const _hoisted_19 = {
  class: "form-check-label",
  for: "device-technology-mixed",
  role: "button"
}
const _hoisted_20 = { class: "mb-1 mb-sm-4 mt-4" }
const _hoisted_21 = { class: "row mb-2 g-2" }
const _hoisted_22 = {
  for: "has-subsidy",
  class: "col-sm-12 pb-0 col-form-label"
}
const _hoisted_23 = { class: "col-sm-11" }
const _hoisted_24 = {
  class: "form-check",
  role: "button"
}
const _hoisted_25 = {
  class: "form-check-label",
  for: "has-subsidy-no",
  role: "button"
}
const _hoisted_26 = {
  class: "form-check",
  role: "button"
}
const _hoisted_27 = {
  class: "form-check-label",
  for: "has-subsidy-elering",
  role: "button"
}
const _hoisted_28 = {
  class: "form-check",
  role: "button"
}
const _hoisted_29 = {
  class: "form-check-label",
  for: "has-subsidy-yes",
  role: "button"
}
const _hoisted_30 = {
  key: 0,
  class: "row mb-2 gy-3 gx-0 mt-1"
}
const _hoisted_31 = { class: "col-form-label mb-0 pb-0 mt-0" }
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { class: "mb-1 mb-sm-4 mt-4" }
const _hoisted_34 = { class: "row mb-2 g-2" }
const _hoisted_35 = {
  for: "has-battery",
  class: "col-sm-12 pb-0 col-form-label"
}
const _hoisted_36 = { class: "col-sm-11" }
const _hoisted_37 = {
  value: "",
  disabled: ""
}
const _hoisted_38 = { value: "true" }
const _hoisted_39 = { value: "false" }
const _hoisted_40 = {
  key: 0,
  class: "row mb-2 g-3 mt-1"
}
const _hoisted_41 = { class: "col-form-label mb-0 pb-0 mt-0" }
const _hoisted_42 = { class: "col-sm-11 p-2 p-sm-4 card" }
const _hoisted_43 = { class: "row mb-3 g-2 align-items-center" }
const _hoisted_44 = { class: "col-6 col-sm-5" }
const _hoisted_45 = { class: "col-form-label" }
const _hoisted_46 = { class: "col-6 col-sm-5" }
const _hoisted_47 = { class: "col-form-label" }
const _hoisted_48 = { class: "row mb-3 g-2 align-items-center" }
const _hoisted_49 = { class: "col-6 col-sm-5" }
const _hoisted_50 = { class: "col-6 col-sm-5" }
const _hoisted_51 = { key: 2 }
const _hoisted_52 = { class: "mb-1 mb-sm-4 mt-4" }
const _hoisted_53 = { class: "row mb-2 g-2" }
const _hoisted_54 = {
  for: "has-ppa",
  class: "col-sm-12 pb-0 col-form-label"
}
const _hoisted_55 = { class: "col-sm-11" }
const _hoisted_56 = {
  value: "",
  disabled: ""
}
const _hoisted_57 = { value: "true" }
const _hoisted_58 = { value: "false" }
const _hoisted_59 = {
  key: 0,
  class: "row mb-2 g-2"
}
const _hoisted_60 = {
  for: "has-ppa-sale",
  class: "col-sm-12 pb-0 col-form-label"
}
const _hoisted_61 = { class: "col-sm-11" }
const _hoisted_62 = {
  value: "",
  disabled: ""
}
const _hoisted_63 = { value: "true" }
const _hoisted_64 = { value: "false" }
const _hoisted_65 = {
  key: 1,
  class: "row mb-2 g-3 mt-1"
}
const _hoisted_66 = { class: "col-form-label mb-0 pb-0 mt-0" }
const _hoisted_67 = { class: "col-sm-11 p-2 p-sm-4 card" }
const _hoisted_68 = { class: "row mb-3 g-2 align-items-center" }
const _hoisted_69 = { class: "col-6 col-sm-5" }
const _hoisted_70 = { class: "col-form-label" }
const _hoisted_71 = { class: "col-6 col-sm-5" }
const _hoisted_72 = { class: "col-form-label" }
const _hoisted_73 = { class: "row mb-3 g-2 align-items-center" }
const _hoisted_74 = { class: "col-6 col-sm-5" }
const _hoisted_75 = { class: "col-6 col-sm-5" }
const _hoisted_76 = { class: "row mb-3 g-2 align-items-center" }
const _hoisted_77 = { class: "col-6 col-sm-5" }
const _hoisted_78 = { class: "col-form-label" }
const _hoisted_79 = { class: "col-6 col-sm-5" }
const _hoisted_80 = { class: "col-form-label" }
const _hoisted_81 = { class: "row mb-3 g-2 align-items-center" }
const _hoisted_82 = { class: "col-6 col-sm-5" }
const _hoisted_83 = { class: "col-6 col-sm-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_subsidy_card_component = _resolveComponent("subsidy-card-component")!
  const _component_country_select = _resolveComponent("country-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("signup.add_generator_component.title")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generatorFields, (field) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "row mb-2 g-2",
        key: field.id
      }, [
        _createElementVNode("label", {
          for: field.id,
          class: "col-sm-3 mt-sm-3 col-form-label"
        }, _toDisplayString(field.label), 9, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: field.type,
            id: field.id,
            class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$[field.model].$error }]),
            disabled: !field.editable,
            "onUpdate:modelValue": ($event: any) => ((_ctx.localGenerator[field.model]) = $event),
            onBlur: _ctx.v$[field.model].$touch
          }, null, 42, _hoisted_5), [
            [_vModelDynamic, _ctx.localGenerator[field.model]]
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$[field.model].$errors.filter(
            (_element, index) => index < 1
          ), (error) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "invalid-feedback",
              key: error.$uid
            }, _toDisplayString(error.$message), 1))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
          (!field.editable)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-outline-secondary",
                onClick: ($event: any) => (_ctx.enableEditing(field))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "pencil-alt" })
              ], 8, _hoisted_7))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128)),
    _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t("signup.add_generator_component.device_technology_title")), 1),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("signup.add_generator_component.device_technology_description")), 1),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["form-check-input pe-auto", { 'is-invalid': _ctx.v$.device_technology.$error }]),
            role: "button",
            type: "radio",
            name: "device_technology_radio",
            id: "device-technology-solar",
            value: "solar",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.technologySelection) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.v$.device_technology.$touch && _ctx.v$.device_technology.$touch(...args)))
          }, null, 34), [
            [_vModelRadio, _ctx.technologySelection]
          ]),
          _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("signup.add_generator_component.device_technology_solar")), 1)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["form-check-input", { 'is-invalid': _ctx.v$.device_technology.$error }]),
            role: "button",
            type: "radio",
            name: "device_technology_radio",
            id: "device-technology-wind",
            value: "wind",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.technologySelection) = $event)),
            onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.v$.device_technology.$touch && _ctx.v$.device_technology.$touch(...args)))
          }, null, 34), [
            [_vModelRadio, _ctx.technologySelection]
          ]),
          _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("signup.add_generator_component.device_technology_wind")), 1)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["form-check-input", { 'is-invalid': _ctx.v$.device_technology.$error }]),
            role: "button",
            type: "radio",
            name: "device_technology_radio",
            id: "device-technology-hydro",
            value: "hydro",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.technologySelection) = $event)),
            onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.v$.device_technology.$touch && _ctx.v$.device_technology.$touch(...args)))
          }, null, 34), [
            [_vModelRadio, _ctx.technologySelection]
          ]),
          _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("signup.add_generator_component.device_technology_hydro")), 1)
        ]),
        _createElementVNode("div", _hoisted_18, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["form-check-input", { 'is-invalid': _ctx.v$.device_technology.$error }]),
            role: "button",
            type: "radio",
            name: "device_technology_radio",
            id: "device-technology-mixed",
            value: "mixed",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.technologySelection) = $event)),
            onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.v$.device_technology.$touch && _ctx.v$.device_technology.$touch(...args)))
          }, null, 34), [
            [_vModelRadio, _ctx.technologySelection]
          ]),
          _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("signup.add_generator_component.device_technology_mixed")), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.device_technology.$errors, (error) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "invalid-feedback",
            key: error.$uid
          }, _toDisplayString(error.$message), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("h3", _hoisted_20, _toDisplayString(_ctx.$t("signup.add_generator_component.subsidy_title")), 1),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("signup.add_generator_component.subsidy_question")), 1),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["form-check-input pe-auto", { 'is-invalid': _ctx.v$.has_subsidy.$error }]),
            role: "button",
            type: "radio",
            name: "subsidy_radio",
            id: "has-subsidy-no",
            value: "false",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.subsidySelection) = $event)),
            onChange: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.v$.has_subsidy.$touch && _ctx.v$.has_subsidy.$touch(...args)))
          }, null, 34), [
            [_vModelRadio, _ctx.subsidySelection]
          ]),
          _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("common.no")), 1)
        ]),
        _createElementVNode("div", _hoisted_26, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["form-check-input", { 'is-invalid': _ctx.v$.has_subsidy.$error }]),
            role: "button",
            type: "radio",
            name: "subsidy_radio",
            id: "has-subsidy-elering",
            value: "Elering AS",
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.subsidySelection) = $event)),
            onChange: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.v$.has_subsidy.$touch && _ctx.v$.has_subsidy.$touch(...args)))
          }, null, 34), [
            [_vModelRadio, _ctx.subsidySelection]
          ]),
          _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("signup.add_generator_component.elering_support")), 1)
        ]),
        _createElementVNode("div", _hoisted_28, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["form-check-input", { 'is-invalid': _ctx.v$.has_subsidy.$error }]),
            role: "button",
            type: "radio",
            name: "subsidy_radio",
            id: "has-subsidy-yes",
            value: "true",
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.subsidySelection) = $event)),
            onChange: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.v$.has_subsidy.$touch && _ctx.v$.has_subsidy.$touch(...args)))
          }, null, 34), [
            [_vModelRadio, _ctx.subsidySelection]
          ]),
          _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t("common.yes")), 1)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.has_subsidy.$errors, (error) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "invalid-feedback",
            key: error.$uid
          }, _toDisplayString(error.$message), 1))
        }), 128))
      ])
    ]),
    (_ctx.subsidySelection === 'true' || _ctx.subsidySelection === 'Elering AS')
      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
          _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t("signup.add_generator_component.enter_subsidies")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localGenerator.subsidies, (_subsidy, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createVNode(_component_subsidy_card_component, {
                subsidy: _ctx.localGenerator.subsidies[index],
                "onUpdate:subsidy": ($event: any) => ((_ctx.localGenerator.subsidies[index]) = $event),
                extraData: 
            !!(_ctx.localGenerator.device_power && _ctx.localGenerator.device_power >= 50)
          ,
                removeSubsidy: () => _ctx.removeSubsidy(index)
              }, null, 8, ["subsidy", "onUpdate:subsidy", "extraData", "removeSubsidy"])
            ]))
          }), 128)),
          _createElementVNode("button", {
            class: "btn btn-subtle-primary col-sm-11",
            onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.addSubsidyClick && _ctx.addSubsidyClick(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "plus" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("signup.add_generator_component.add_subsidy")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.subsidySelection && _ctx.hasNoEleringSubsidy)
      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
          _createElementVNode("h3", _hoisted_33, _toDisplayString(_ctx.$t("signup.add_generator_component.battery_section_title")), 1),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t("signup.add_generator_component.battery_question")), 1),
            _createElementVNode("div", _hoisted_36, [
              _withDirectives(_createElementVNode("select", {
                class: "form-select",
                role: "button",
                id: "has-battery",
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.batterySelection) = $event))
              }, [
                _createElementVNode("option", _hoisted_37, _toDisplayString(_ctx.$t("signup.add_generator_component.battery_question")), 1),
                _createElementVNode("option", _hoisted_38, _toDisplayString(_ctx.$t("common.yes")), 1),
                _createElementVNode("option", _hoisted_39, _toDisplayString(_ctx.$t("common.no")), 1)
              ], 512), [
                [_vModelSelect, _ctx.batterySelection]
              ])
            ])
          ]),
          (_ctx.batterySelection === 'true')
            ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                _createElementVNode("label", _hoisted_41, _toDisplayString(_ctx.$t("signup.add_generator_component.battery_details")), 1),
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("div", _hoisted_44, [
                      _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t("signup.add_generator_component.unit_power")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("label", _hoisted_47, _toDisplayString(_ctx.$t("signup.add_generator_component.capacity")), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_48, [
                    _createElementVNode("div", _hoisted_49, [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.battery.kw.$error }]),
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.localGenerator.battery!.kw) = $event)),
                        onBlur: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.v$.battery.kw.$touch && _ctx.v$.battery.kw.$touch(...args)))
                      }, null, 34), [
                        [_vModelText, _ctx.localGenerator.battery!.kw]
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.battery.kw.$errors, (error) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "invalid-feedback",
                          key: error.$uid
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_50, [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.battery.kwh.$error }]),
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.localGenerator.battery!.kwh) = $event)),
                        onBlur: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.v$.battery.kwh.$touch && _ctx.v$.battery.kwh.$touch(...args)))
                      }, null, 34), [
                        [_vModelText, _ctx.localGenerator.battery!.kwh]
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.battery.kwh.$errors, (error) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "invalid-feedback",
                          key: error.$uid
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.localGenerator.device_power &&
        _ctx.localGenerator.device_power >= 500 &&
        _ctx.subsidySelection &&
        _ctx.hasNoEleringSubsidy
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
          _createElementVNode("h3", _hoisted_52, _toDisplayString(_ctx.$t("signup.add_generator_component.ppa_section_title")), 1),
          _createElementVNode("div", _hoisted_53, [
            _createElementVNode("label", _hoisted_54, _toDisplayString(_ctx.$t("signup.add_generator_component.ppa_question")), 1),
            _createElementVNode("div", _hoisted_55, [
              _withDirectives(_createElementVNode("select", {
                class: "form-select",
                role: "button",
                id: "has-ppa",
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.ppaSelection) = $event))
              }, [
                _createElementVNode("option", _hoisted_56, _toDisplayString(_ctx.$t("signup.add_generator_component.ppa_question")), 1),
                _createElementVNode("option", _hoisted_57, _toDisplayString(_ctx.$t("common.yes")), 1),
                _createElementVNode("option", _hoisted_58, _toDisplayString(_ctx.$t("common.no")), 1)
              ], 512), [
                [_vModelSelect, _ctx.ppaSelection]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            (_ctx.ppaSelection === 'true')
              ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                  _createElementVNode("label", _hoisted_60, _toDisplayString(_ctx.$t(
                "signup.add_generator_component.ppa_include_certificates_question"
              )), 1),
                  _createElementVNode("div", _hoisted_61, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-select",
                      role: "button",
                      id: "has-ppa-sale",
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.ppaSelection2) = $event))
                    }, [
                      _createElementVNode("option", _hoisted_62, _toDisplayString(_ctx.$t(
                    "signup.add_generator_component.ppa_include_certificates_question"
                  )), 1),
                      _createElementVNode("option", _hoisted_63, _toDisplayString(_ctx.$t("common.yes")), 1),
                      _createElementVNode("option", _hoisted_64, _toDisplayString(_ctx.$t("common.no")), 1)
                    ], 512), [
                      [_vModelSelect, _ctx.ppaSelection2]
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.ppaSelection2 === 'true')
              ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                  _createElementVNode("label", _hoisted_66, _toDisplayString(_ctx.$t("signup.add_generator_component.ppa_details")), 1),
                  _createElementVNode("div", _hoisted_67, [
                    _createElementVNode("div", _hoisted_68, [
                      _createElementVNode("div", _hoisted_69, [
                        _createElementVNode("label", _hoisted_70, _toDisplayString(_ctx.$t("signup.add_generator_component.ppa_start_date")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_71, [
                        _createElementVNode("label", _hoisted_72, _toDisplayString(_ctx.$t("signup.add_generator_component.ppa_mwh")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_73, [
                      _createElementVNode("div", _hoisted_74, [
                        _withDirectives(_createElementVNode("input", {
                          type: "date",
                          class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.ppa.start_date.$error }]),
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.localGenerator.ppa!.start_date) = $event)),
                          onBlur: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.v$.ppa.start_date.$touch && _ctx.v$.ppa.start_date.$touch(...args)))
                        }, null, 34), [
                          [_vModelText, _ctx.localGenerator.ppa!.start_date]
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.ppa.start_date.$errors.filter((_element, index) => index < 1), (error) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "invalid-feedback",
                            key: error.$uid
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_75, [
                        _withDirectives(_createElementVNode("input", {
                          type: "number",
                          class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.ppa.mwh.$error }]),
                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.localGenerator.ppa!.mwh) = $event)),
                          onBlur: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.v$.ppa.mwh.$touch && _ctx.v$.ppa.mwh.$touch(...args)))
                        }, null, 34), [
                          [_vModelText, _ctx.localGenerator.ppa!.mwh]
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.ppa.mwh.$errors, (error) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "invalid-feedback",
                            key: error.$uid
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_76, [
                      _createElementVNode("div", _hoisted_77, [
                        _createElementVNode("label", _hoisted_78, _toDisplayString(_ctx.$t("signup.add_generator_component.ppa_client_name")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_79, [
                        _createElementVNode("label", _hoisted_80, _toDisplayString(_ctx.$t("signup.add_generator_component.ppa_country")), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_81, [
                      _createElementVNode("div", _hoisted_82, [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.ppa.client_company_name.$error }]),
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.localGenerator.ppa!.client_company_name) = $event)),
                          onBlur: _cache[27] || (_cache[27] = 
//@ts-ignore
(...args) => (_ctx.v$.ppa.client_company_name.$touch && _ctx.v$.ppa.client_company_name.$touch(...args)))
                        }, null, 34), [
                          [_vModelText, _ctx.localGenerator.ppa!.client_company_name]
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.ppa.client_company_name.$errors, (error) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "invalid-feedback",
                            key: error.$uid
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_83, [
                        _createVNode(_component_country_select, {
                          class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.ppa.country.$error }]),
                          modelValue: _ctx.localGenerator.ppa!.country,
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.localGenerator.ppa!.country) = $event)),
                          country: _ctx.localGenerator.ppa!.country,
                          topCountry: "EE",
                          onBlur: _ctx.v$.ppa.country.$touch
                        }, null, 8, ["modelValue", "country", "onBlur", "class"]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.ppa.country.$errors, (error) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "invalid-feedback",
                            key: error.$uid
                          }, _toDisplayString(error.$message), 1))
                        }), 128))
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}