<template>
  <div class="generator-license-review card p-4 mb-3" novalidate>
    <h3 class="mb-1 mb-sm-4">
      {{ $t("signup.generator_license_review.title") }}
    </h3>
    <p>
      {{ $t("signup.generator_license_review.description") }}
    </p>
    <p v-html="$t('signup.generator_license_review.directions')"></p>

    <div class="row mb-2 g-2">
      <label for="license-number" class="col-sm-3 mt-sm-3 col-form-label">
        {{ $t("generator.license_number") }}
      </label>
      <div class="col-sm-8 col-10">
        <input
          type="text"
          id="license-number"
          class="form-control"
          :class="{ 'is-invalid': v$.license_number.$error }"
          v-model="localGenerator.license_number"
          @blur="v$.license_number.$touch"
        />

        <div
          class="invalid-feedback"
          v-for="error of v$.license_number.$errors.filter(
            (_, index) => index < 1
          )"
          :key="error.$uid"
        >
          {{ error.$message }}
        </div>
      </div>
    </div>
    <div v-if="licenseHints.length" class="row mb-2 g-2">
      <label class="col-sm-3 mt-sm-3 col-form-label"></label>
      <div class="col-sm-8 col-10">
        <ul class="list-group border">
          <li
            class="list-group-item list-group-item-light py-1 fw-semibold border-bottom"
          >
            {{ $t("signup.generator_license_review.licenseHints") }}
          </li>
          <a
            class="list-group-item list-group-item-action py-2 fs-9"
            :class="{
              hover: hint === localGenerator.license_number,
            }"
            v-for="hint in licenseHints"
            :key="hint"
            @click="setLicenseNumber(hint)"
          >
            <font-awesome-icon
              icon="copy"
              class="pe-1"
              v-if="hint !== localGenerator.license_number"
            />
            <font-awesome-icon icon="check" class="pe-1" v-else />
            {{ hint }}
          </a>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  watch,
  computed,
  onMounted,
} from "vue";
import { useI18n } from "vue-i18n";
import { useValidators } from "@/composables/useValidators";
import { useVuelidate } from "@vuelidate/core";
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";
import type { Account } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";

export default defineComponent({
  name: "GeneratorLicenseReviewComponent",
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ["handleUpdateGenerator"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { required } = useValidators(t);

    const { selectedGenerator } = useGeneratorFinder(props.accountData);
    const localGenerator = reactive({
      ...selectedGenerator.value.generator,
    });

    const licenseHints = ref<string[]>([]);

    const fetchLicenseHints = async () => {
      try {
        let company = props.accountData.companies[props.companyIndex];

        const response = await goSolidApi.get(
          `/api/lt/license-hints/${company.id}`
        );
        if (response.status === 200) {
          licenseHints.value = response.data;
        }
      } catch (error) {
        console.error("Error fetching license hints:", error);
      }
    };

    const setLicenseNumber = (licenseNumber: string) => {
      localGenerator.license_number = licenseNumber;
      v$.value.license_number.$touch();
    };

    onMounted(() => {
      fetchLicenseHints();
    });

    const rules = computed(() => ({
      license_number: { required },
    }));

    const v$ = useVuelidate(rules, localGenerator);

    watch(localGenerator, (newValue) => {
      emit("handleUpdateGenerator", newValue);
    });

    return {
      localGenerator,
      licenseHints,
      setLicenseNumber,
      v$,
    };
  },
});
</script>

<style scoped>
.generator-license-review {
  .col-form-label {
    text-align: left;
    font-weight: bold;
    padding: 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 575.98px) {
  .generator-license-review {
    .col-form-label {
      margin-top: 1rem;
    }
  }
  h3 {
    font-size: 1.2rem;
  }
}
</style>
