<!-- client/src/components/signup/ChooseCompanyComponent.vue -->

<template>
  <div class="contact-review card p-4 mb-3">
    <h3 class="mb-1 mb-sm-4">{{ $t("chooseCompany.select_company") }}</h3>
    <CompanySelectDropdown
      :existingCompanies="accountData.companies"
      :selectedCompany="company"
      @selectExistingCompany="$emit('selectExistingCompany', $event)"
      @resetCompanySelection="$emit('resetCompanySelection', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import type { Account, Company } from "@/types";
import CompanySelectDropdown from "./CompanySelectDropdown.vue";
import { useCompanyFinder } from "@/composables/useCompanyFinder";

export default defineComponent({
  name: "ChooseCompanyComponent",
  emits: ["selectExistingCompany", "resetCompanySelection"],
  components: {
    CompanySelectDropdown,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
    existingCompanies: {
      type: Array as () => Company[],
      default: () => [],
    },
  },
  setup(props) {
    const company = computed(() => {
      if (props.companyIndex === -1) {
        return undefined;
      } else {
        const { selectedCompany } = useCompanyFinder(props.accountData);
        return selectedCompany.value;
      }
    });

    return { company };
  },
});
</script>

<style scoped></style>
