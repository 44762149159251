<!-- client/src/components/common/InfoCardComponent.vue -->
<!-- <<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>-->
<!-- NOTE: See BasicInfoCardComponent.vue for simple use cases-->
<!-- <<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>-->

<template>
  <div class="card" :class="containerClass">
    <div class="card-body p-2 px-3 p-sm-2 px-sm-3 bg-body-highlight">
      <div
        class="d-flex align-items-center"
        @click="clickArea === 'title' && toggleCollapse()"
        role="button"
      >
        <slot name="title"></slot>
        <div
          class="btn btn-text p-2"
          @click="clickArea === 'button' && toggleCollapse()"
        >
          {{ buttonText }}
        </div>
      </div>
      <div class="text-body-secondary mt-1" v-show="showCollapse">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InfoCardComponent",
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    clickArea: {
      type: String,
      default: "title",
    },
    containerClass: {
      type: String,
      default: "mt-sm-2",
    },
  },
  setup(_, { emit }) {
    const showCollapse = ref(false);

    const toggleCollapse = () => {
      showCollapse.value = !showCollapse.value;
    };

    const forceToggleCollapse = (value: boolean) => {
      showCollapse.value = value;
    };

    // Expose the method
    emit("setForceToggle", forceToggleCollapse);

    return {
      showCollapse,
      toggleCollapse,
    };
  },
});
</script>
