export interface DocumentInfo {
  key: string;
  contract_title: string;
  contract_description: string;
}

export const predefinedDocuments: { [key: string]: DocumentInfo } = {
  ee_avaldus_partiolu_tunnistuste_konto_avamiseks: {
    key: "ee_avaldus_partiolu_tunnistuste_konto_avamiseks",
    contract_title:
      "signup.contract_review_component.ee.origin_certificate_account_application_title",
    contract_description:
      "signup.contract_review_component.ee.origin_certificate_account_application_description",
  },
  ee_paritolutunnistuste_kasutamise_tyuptingimused: {
    key: "ee_paritolutunnistuste_kasutamise_tyuptingimused",
    contract_title: "signup.contract_review_component.ee.type_conditions_title",
    contract_description:
      "signup.contract_review_component.ee.type_conditions_description",
  },
  ee_avaldus_partiolu_tunnistuste_konto_volituse_andmiseks: {
    key: "ee_avaldus_partiolu_tunnistuste_konto_volituse_andmiseks",
    contract_title:
      "signup.contract_review_component.ee.authorization_application_title",
    contract_description:
      "signup.contract_review_component.ee.authorization_application_description",
  },
  ee_kinnituskiri_investeeringutoetuse_saamise_mittesaamise_kohta: {
    key: "ee_kinnituskiri_investeeringutoetuse_saamise_mittesaamise_kohta",
    contract_title:
      "signup.contract_review_component.ee.investment_support_confirmation_title",
    contract_description:
      "signup.contract_review_component.ee.investment_support_confirmation_description",
  },
  ee_vorguleping: {
    key: "ee_vorguleping",
    contract_title:
      "signup.contract_review_component.ee.network_contract_title",
    contract_description:
      "signup.contract_review_component.ee.network_contract_description",
  },
  ee_seadme_registreerimise_vorm: {
    key: "ee_seadme_registreerimise_vorm",
    contract_title:
      "signup.contract_review_component.ee.device_registration_form_title",
    contract_description:
      "signup.contract_review_component.ee.device_registration_form_description",
  },
  ee_soldera_liikmeleping: {
    key: "ee_soldera_liikmeleping",
    contract_title:
      "signup.contract_review_component.ee.soldera_membership_agreement_title",
    contract_description:
      "signup.contract_review_component.ee.soldera_membership_agreement_description",
  },
  ee_eleringi_andmed: {
    key: "ee_eleringi_andmed",
    contract_title: "signup.contract_review_component.ee.elering_data_title",
    contract_description:
      "signup.contract_review_component.ee.elering_data_description",
  },
  ee_forward_service_appendix: {
    key: "ee_forward_service_appendix",
    contract_title: "forwardSales.forwardAppendixContractTitle",
    contract_description: "forwardSales.forwardAppendixContractDescription",
  },
  lv_authotization_application: {
    key: "lv_authotization_application",
    contract_title:
      "signup.contract_review_component.lv.lv_account_authorization_form_title",
    contract_description:
      "signup.contract_review_component.lv.lv_account_authorization_form_text",
  },
  lv_soldera_membership_agreement: {
    key: "lv_soldera_membership_agreement",
    contract_title:
      "signup.contract_review_component.lv.lv_soldera_membership_agreement_title",
    contract_description:
      "signup.contract_review_component.lv.lv_soldera_membership_agreement_text",
  },
  lv_forward_service_appendix: {
    key: "lv_forward_service_appendix",
    contract_title: "forwardSales.forwardAppendixContractTitle",
    contract_description: "forwardSales.forwardAppendixContractDescription",
  },
  lt_litgrid_terms_service: {
    key: "lt_litgrid_terms_service",
    contract_title:
      "signup.contract_review_component.lt.lt_litgrid_terms_service_title",
    contract_description:
      "signup.contract_review_component.lt.lt_litgrid_terms_service_description",
  },
  lt_request_for_go_contract: {
    key: "lt_request_for_go_contract",
    contract_title:
      "signup.contract_review_component.lt.lt_request_for_go_contract_title",
    contract_description:
      "signup.contract_review_component.lt.lt_request_for_go_contract_description",
  },
  lt_inspection_report: {
    key: "lt_inspection_report",
    contract_title: "signup.multiple_upload.lt_inspection_report",
    contract_description:
      "signup.contract_review_component.self_uploaded_file_text",
  },
  lt_property_limit_scheme: {
    key: "lt_property_limit_scheme",
    contract_title: "signup.multiple_upload.lt_property_limit_scheme",
    contract_description:
      "signup.contract_review_component.self_uploaded_file_text",
  },
  lt_authotization_application: {
    key: "lt_authotization_application",
    contract_title:
      "signup.contract_review_component.lt.lt_authotization_application_title",
    contract_description:
      "signup.contract_review_component.lt.lt_authotization_application_text",
  },
  lt_soldera_membership_agreement: {
    key: "lt_soldera_membership_agreement",
    contract_title:
      "signup.contract_review_component.lt.lt_soldera_membership_agreement_title",
    contract_description:
      "signup.contract_review_component.lt.lt_soldera_membership_agreement_text",
  },
  lt_power_of_attorney: {
    key: "lt_power_of_attorney",
    contract_title: "signup.multiple_upload.lt_power_of_attorney",
    contract_description:
      "signup.contract_review_component.self_uploaded_file_text",
  },
  lt_forward_service_appendix: {
    key: "lt_forward_service_appendix",
    contract_title: "forwardSales.forwardAppendixContractTitle",
    contract_description: "forwardSales.forwardAppendixContractDescription",
  },
};
