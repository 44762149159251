import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container py-5 pt-3 pt-xl-5" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-success mt-3",
  role: "alert"
}
const _hoisted_3 = {
  key: 1,
  class: "alert alert-danger mt-3",
  role: "alert"
}
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountryPickerComponent = _resolveComponent("CountryPickerComponent")!
  const _component_LoadingOverlayComponent = _resolveComponent("LoadingOverlayComponent")!
  const _component_AccountDataDebugComponent = _resolveComponent("AccountDataDebugComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.messageSent === _ctx.MessageStatusEnum.sent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("signup.new_country.success_message")), 1))
      : _createCommentVNode("", true),
    (_ctx.messageSent === _ctx.MessageStatusEnum.failed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("signup.new_country.error_message")), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_CountryPickerComponent, {
      accountData: _ctx.accountData,
      handleUpdateAccountData: _ctx.handleUpdateAccountData
    }, null, 8, ["accountData", "handleUpdateAccountData"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingOverlayComponent, { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode(_component_AccountDataDebugComponent, {
      accountData: _ctx.accountData,
      class: "mb-4"
    }, null, 8, ["accountData"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h4", null, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("signup.new_country.ask_for_message")), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("signup.new_country.ask_for_message_2")), 1),
      _withDirectives(_createElementVNode("textarea", {
        class: "form-control mb-3",
        rows: "6",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userMessage) = $event)),
        placeholder: _ctx.$t('signup.new_country.placeholder')
      }, null, 8, _hoisted_5), [
        [_vModelText, _ctx.userMessage]
      ]),
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args))),
        disabled: 
          _ctx.messageSent === _ctx.MessageStatusEnum.sending ||
          _ctx.messageSent === _ctx.MessageStatusEnum.sent
        
      }, _toDisplayString(_ctx.messageSent === _ctx.MessageStatusEnum.unsent ||
          _ctx.messageSent === _ctx.MessageStatusEnum.failed
            ? _ctx.$t("signup.new_country.send_message")
            : _ctx.$t("signup.new_country.message_sent")), 9, _hoisted_6)
    ])
  ]))
}