// src/composables/fetchAccount.ts

import { inject, onMounted, reactive } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import type { Account, Company } from "@/types";
import { useI18n } from "vue-i18n";
import { AxiosResponse } from "axios";
import { useRoute } from "vue-router";
import { useHelpCrunch } from "@/composables/useHelpCrunch";

export function fetchAccount(onSuccess?: (accountData: Account) => void) {
  const globalAdminWarning = inject("globalAdminWarning") as (
    error: any
  ) => void;
  const globalErrorHandler = inject("globalErrorHandler") as (
    error: any
  ) => void;
  // Define accountData with the User type
  const accountData = reactive<Account>({
    email: null,
    name: null,
    registry_code: null,
    phone_number: null,
    has_registered: false,
    session_id: null,
    kilowatts: 200.0,
    go_price: 4.0,
    companies: [],
    trackings: [],
  });
  const { locale, availableLocales } = useI18n();
  const route = useRoute();
  const { setHelpCrunchUser } = useHelpCrunch();

  onMounted(async () => {
    if (!accountData.email) {
      try {
        const query = route.query.language
          ? { language: route.query.language }
          : {};
        const response = await goSolidApi.get(`/api/account`, {
          params: query,
        });
        if (response.status === 200 && response.data) {
          if (response.data) {
            Object.assign(accountData, response.data);
            _updateLanguageBasedOnAccountLanguage(response);
            globalAdminWarning(response.data);
            try {
              const user = {
                email: response.data.email,
                name: response.data.name || response.data.email,
                user_id: response.data.id.toString(),
                phone: response.data.phone_number,
                locale: locale.value,
                company: response.data.companies
                  .map((company: Company) => company.name)
                  .join(", "),
              };
              setHelpCrunchUser(user);
            } catch (error: any) {
              console.error("Error updating HelpCrunch user:", error);
            } // We dont want support to blow up app
          }

          if (onSuccess) {
            onSuccess(response.data);
          }
        } else {
          throw new Error("Failed to fetch account data");
        }
      } catch (error: any) {
        globalErrorHandler(error.response ? error.response.data : error);
      }
    }
  });

  return { accountData };

  function _updateLanguageBasedOnAccountLanguage(
    response: AxiosResponse<any, any>
  ) {
    if (
      response.data.language &&
      availableLocales.includes(response.data.language)
    ) {
      locale.value = response.data.language;
    } else {
      locale.value = "en";
    }
  }
}
