<!-- client/src/views/DashboardView.vue -->

<template>
  <div class="text-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Palun oota...</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { fetchAccount } from "@/composables/account/fetchAccount";
import type { Account } from "../types";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const newQuery = {
      ...route.query,
    };

    const afterFetchAccount = (accountData: Account) => {
      if (accountData.companies.length > 0) {
        router.push({ path: "/dashboard", query: newQuery });
      } else {
        router.push({ path: "/signup", query: newQuery });
      }
    };

    fetchAccount(afterFetchAccount);

    return {};
  },
});
</script>
