<!-- client/src/components/signup/ee/EESignupFlow.vue -->

<template>
  <div class="container py-5 pt-3 pt-xl-5">
    <ProgressNavComponent
      :steps="navSteps"
      :setStep="setStep"
    ></ProgressNavComponent>

    <CountryPickerComponent
      v-if="signupStep === 1"
      :accountData="accountData"
      :handleUpdateAccountData="handleUpdateAccountData"
    ></CountryPickerComponent>

    <ContactReviewConfigurationComponent
      v-if="shownStep.component === 'contact-review' && companyIndex !== -1"
      :accountData="accountData"
      :companyIndex="companyIndex"
      @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
      @updateAccountData="handleUpdateAccountData"
      class="mb-4"
    ></ContactReviewConfigurationComponent>

    <ContractReviewConfigurationComponent
      v-if="shownStep.component === 'contract-review' && companyIndex !== -1"
      :accountData="accountData"
      :setLoading="setLoading"
      @createContainerForStartSigningReady="storeCreateContainerForStartSigning"
      class="mb-4"
    ></ContractReviewConfigurationComponent>

    <!-- Buttons -->
    <div class="d-flex justify-content-between w-100">
      <button
        class="btn btn-secondary me-2"
        :disabled="signupStep === 1 || isLoading"
        @click="setStep(signupStep - 1)"
      >
        {{ $t("signup.navsteps.back") }}
      </button>
      <button
        v-if="shownStep.component !== 'contract-review'"
        class="btn btn-primary"
        :disabled="isLoading"
        @click="setStep(signupStep + 1)"
      >
        {{ $t("signup.navsteps.next") }}
      </button>
      <button
        v-if="shownStep.component === 'contract-review'"
        class="btn btn-primary"
        :disabled="isLoading"
        @click="createContainerForStartSigningFunction"
      >
        {{ $t("signup.navsteps.to_signature_page") }}
      </button>
    </div>
    <LoadingOverlayComponent v-if="isLoading" />

    <AccountDataDebugComponent
      :accountData="accountData"
      class="mb-4"
    ></AccountDataDebugComponent>
  </div>
  <!-- Greeting modal -->
  <ModalComponent
    :submitButtonText="$t('signup.modal.hello_button')"
    @setForceToggle="setToggleHelloModal"
  >
    <template #title> {{ $t("signup.modal.hello") }} </template>
    <template #body>
      <p class="text-body-tertiary lh-lg">
        {{ $t("signup.modal.lv.section1") }}
        <a
          class="link"
          role="button"
          href="https://www.soldera.org/#faq"
          target="blank"
        >
          <font-awesome-icon icon="right-to-bracket" />
          {{ $t("signup.modal.soldera_faq") }}
        </a>
      </p>
      <p class="text-body-tertiary lh-lg">
        {{ $t("signup.modal.lv.section2") }}
      </p>
    </template>
  </ModalComponent>
</template>

<script lang="ts">
import { defineComponent, watch, ref, computed, onMounted } from "vue";
import ProgressNavComponent from "@/components/signup/ProgressNavComponent.vue";
import ModalComponent from "@/components/common/ModalComponent.vue";
import type { Account, Step } from "@/types";
import { accountUpdater } from "@/composables/account/accountUpdater";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import CountryPickerComponent from "@/components/signup/CountryPickerComponent.vue";
import ContactReviewConfigurationComponent from "@/components/signup/lv/ContactReviewConfigurationComponent.vue";
import ContractReviewConfigurationComponent from "@/components/signup/lv/ContractReviewConfigurationComponent.vue";
import AccountDataDebugComponent from "@/components/common/AccountDataDebugComponent.vue";
import LoadingOverlayComponent from "@/components/common/LoadingOverlayComponent.vue";

export default defineComponent({
  name: "LVSignupFlow",
  components: {
    ProgressNavComponent,
    ModalComponent,
    CountryPickerComponent,
    ContactReviewConfigurationComponent,
    ContractReviewConfigurationComponent,
    LoadingOverlayComponent,
    AccountDataDebugComponent,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    handleUpdateAccountData: {
      type: Function as unknown as () => (updatedAccountData: Account) => void,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
    generatorIndex: {
      type: Number,
      required: true,
    },
    companyIdQueryString: {
      type: Number,
      required: true,
    },
  },
  emits: [
    "handleUpdateCompany",
    "handleUpdateGenerator",
    "createCompanyIfMissing",
  ],
  setup(props, { emit }) {
    const signupStep = ref(1);
    const createContainerForStartSigningFunction = ref(() => undefined);
    const { t } = useI18n();
    const storeCreateContainerForStartSigning = (
      createContainerForStartSigning: () => undefined
    ) => {
      createContainerForStartSigningFunction.value =
        createContainerForStartSigning;
    };

    const isLoading = ref(true);
    const apiError = ref(false);
    const apiErrorMessage = ref("");
    const route = useRoute();
    const router = useRouter();
    const getAddAuthorizationAgreement = computed(() => {
      return route.query.addAuthorizationAgreement === "true";
    });
    const v$ = useVuelidate();

    const toggleHelloModal = ref();
    const setToggleHelloModal = (method: (value: boolean) => null) => {
      toggleHelloModal.value = method;
    };
    const toggleHelloIfNew = async () => {
      if (props.accountData.companies.length === 0) {
        toggleHelloModal.value(true);
      }
    };
    const setLoading = (value: boolean) => {
      isLoading.value = value;
    };

    const fetchUrl = () => {
      if (shownStep.value.component === "contact-review") {
        return "/api/lv/update-company";
      } else {
        return "TODO"; // TODO needed for generators in the future
      }
    };
    const onAccountUpdated = async (_: Account, company_id: number) => {
      isLoading.value = false;

      // This is here to only add the new company_id to the query parameters in case of authorization
      // agreement when company was created
      const newQuery = {
        ...route.query,
        company_id: company_id,
      };
      await router.push({ name: "SignUp", query: newQuery });
    };
    const { postAccountData } = accountUpdater(
      props.accountData,
      props.handleUpdateAccountData
    );

    const addAuthorizationAgreementFlow = () => {
      toggleHelloModal.value(false);
      emit("createCompanyIfMissing");
      const newQuery = {
        ...route.query,
        addAuthorizationAgreement: "true",
        company_id: undefined,
        generator_id: undefined,
        step: "2",
      };
      router.push({ name: "SignUp", query: newQuery });
    };

    const createNewAccountFlow = () => {
      toggleHelloModal.value(false);
      const newQuery = {
        ...route.query,
        addAuthorizationAgreement: "true",
        company_id: undefined,
        generator_id: undefined,
        step: "2",
      };
      emit("createCompanyIfMissing");
      router.push({ name: "SignUp", query: newQuery });
    };

    const checkValidity = () => {
      v$.value.$touch();
      return !v$.value.$error;
    };

    const setStep = async (step: number) => {
      isLoading.value = true;
      try {
        if (signupStep.value === 1) {
          if (!checkValidity()) {
            return;
          }

          await postAccountData(
            fetchUrl(),
            onAccountUpdated,
            props.companyIdQueryString
          );
        }

        signupStep.value = step;

        const newQuery = {
          ...route.query,
          step: step.toString(),
        };
        router.push({ name: "SignUp", query: newQuery });
      } finally {
        isLoading.value = false;
      }
    };

    const shownStep = computed(() => {
      if (signupStep.value === 1) {
        return { component: "contact-review" };
      } else if (signupStep.value === 2) {
        return { component: "contract-review" };
      }
      return { component: "contact-review" };
    });

    const navCheck = (step: number) =>
      computed(() => {
        if (step === 1) {
          return true;
        } else {
          return signupStep.value - step >= 0;
        }
      });

    const navSteps = computed(() => {
      return [
        {
          title: t(`signup.navsteps.lv.authorization.2`), // TODO When we add generator to lv, we should change this to 1
          isAvailable: navCheck(1).value,
        },
        {
          title: t(`signup.navsteps.lv.authorization.3`), // TODO When we add generator to lv, we should change this to 2
          isAvailable: navCheck(2).value,
        },
      ].filter(Boolean) as Step[]; // Remove null values
    });

    onMounted(async () => {
      toggleHelloIfNew();
      if (
        shownStep.value.component === "contact-review" &&
        props.companyIndex === -1
      ) {
        emit("createCompanyIfMissing");
      }
      isLoading.value = false;
    });

    watch(
      () => route.query.step,
      (newStep) => {
        if (typeof newStep === "string") {
          const stepNum = parseInt(newStep, 10);
          if (!isNaN(stepNum) && stepNum >= 0 && stepNum <= 4) {
            signupStep.value = stepNum;
          }
        }
      },
      { immediate: true }
    );

    return {
      navSteps,
      shownStep,
      signupStep,
      setStep,
      isLoading,
      setLoading,
      apiError,
      apiErrorMessage,
      setToggleHelloModal,
      addAuthorizationAgreementFlow,
      createNewAccountFlow,
      createContainerForStartSigningFunction,
      storeCreateContainerForStartSigning,
    };
  },
});
</script>
