<template>
  <div class="container py-5 overflow-y-auto">
    <h1 class="mb-4">ESO -> Grexel status</h1>
    <div class="mb-4">
      <ul>
        <li>
          <strong>Legend:</strong>
          <ul>
            <li>
              <span style="background-color: yellow">Pending Approval</span>
            </li>
            <li>
              <span style="background-color: green">Issued</span>
            </li>
            <li>
              <span style="background-color: lightgreen">Approved</span>
            </li>
            <li>
              <span style="background-color: red">Rejected</span>
            </li>
          </ul>
          <ul>
            <li>
              <strong>Notes:</strong>
            </li>

            <li>
              <span>Companies are sorted by name</span>
            </li>
            <li>
              <span>Companies with no data are not shown</span>
            </li>
          </ul>
        </li>
        <li>
          Warning: Grexel auth session must be active for this page to work
        </li>
      </ul>
    </div>
    <button class="btn btn-primary mb-3" @click="handleButtonClick">
      Reload Data
    </button>
    <table class="table table-bordered mt-4">
      <thead>
        <tr>
          <th>Company Name</th>
          <th>Company ID</th>
          <th v-for="month in months" :key="month">{{ month }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(company, index) in tableData" :key="index">
          <td>{{ company.company_name }}</td>
          <td>{{ company.company_id }}</td>
          <td v-for="month in months" :key="month">
            <span
              :style="{
                backgroundColor:
                  company.meter_readings[month]?.status === 'PENDING_APPROVAL'
                    ? 'yellow'
                    : company.meter_readings[month]?.status === 'ISSUED'
                    ? 'green'
                    : company.meter_readings[month]?.status === 'APPROVED'
                    ? 'lightgreen'
                    : 'red',
              }"
              :title="company.meter_readings[month]?.status"
            >
              {{ company.meter_readings[month]?.value ?? "N/A" }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { types } from "sass";
import List = types.List;

interface MeterReading {
  date: string;
  status: string;
  value: number;
}

interface CompanyData {
  company_id: number | null;
  company_name: string | null;
  meter_readings: Record<string, MeterReading>;
}

export default defineComponent({
  name: "EsoStatusView",
  setup() {
    const months = ref<string[]>([]);
    const tableData = ref<CompanyData[]>([]);

    const fetchData = async () => {
      try {
        const response = await goSolidApi.get(
          "/api/admin/integration/grexel/meter-reading-status/"
        );
        if (response.status === 200 && response.data) {
          months.value = response.data.months;
          let tmp: CompanyData[] = Object.values(response.data.data);
          tmp.sort((a: CompanyData, b: CompanyData) =>
            (a.company_name ?? "").localeCompare(b.company_name ?? "")
          );
          tableData.value = tmp;
        } else {
          throw new Error("Unexpected response from API");
        }
      } catch (error: any) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(() => {
      fetchData();
    });

    const handleButtonClick = () => {
      fetchData();
    };

    return {
      handleButtonClick,
      months,
      tableData,
    };
  },
});
</script>

<style scoped>
/* Add custom styles here */
</style>
