import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StackedBarChart = _resolveComponent("StackedBarChart")!

  return (_openBlock(), _createBlock(_component_StackedBarChart, {
    chartData: _ctx.chartData,
    title: _ctx.title,
    subtitle: _ctx.subtitle,
    yAxisTitle: _ctx.yAxisTitle,
    height: _ctx.height
  }, null, 8, ["chartData", "title", "subtitle", "yAxisTitle", "height"]))
}