<!-- client/src/views/admin/MapView.vue -->

<template>
  <div id="AdminEmails" class="container">
    <div class="row justify-content-between align-items-center mb-3 mt-4">
      <div class="col-auto">
        <h1>Admin Map View</h1>
        <button @click="onUpdateLocationClick">Update location</button>
      </div>
    </div>
    <div class="table-container table-responsive">
      <GoogleMap
        :api-key="apiKey"
        :center="{ lat: 58.38335037231445, lng: 26.740428924560547 }"
        :zoom="6"
        map-type-id="terrain"
        style="width: 100%; height: 1000px"
      >
        <MarkerCluster>
          <Marker
            v-for="(location, i) in locations"
            :key="i"
            :options="{ position: location }"
          >
            <InfoWindow>
              <div id="content">
                <div id="siteNotice"></div>
                <h1 id="firstHeading" class="firstHeading">
                  {{ location.company_name }} - {{ location.company_id }}
                </h1>
                <div id="bodyContent">
                  <p>Power: {{ location.device_power }}</p>
                  <p>Address: {{ location.device_address }}</p>
                </div>
              </div>
            </InfoWindow>
          </Marker>
        </MarkerCluster>
      </GoogleMap>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref, onMounted } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { GoogleMap, Marker, MarkerCluster, InfoWindow } from "vue3-google-map";

interface Location {
  lat: number;
  lng: number;
  company_name: string;
  company_id: string;
  device_power: string;
  device_address: string;
}

export default defineComponent({
  name: "AdminMapView",
  methods: {},
  components: {
    GoogleMap,
    Marker,
    MarkerCluster,
    InfoWindow,
  },
  setup() {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const apiKey =
      process.env.NODE_ENV === "production"
        ? "AIzaSyCT2pvxwd_5hrMmAIpL0AQzKrRtPd2qvcY"
        : "AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg";

    const locations = ref<[Location]>([
      {
        lat: 0,
        lng: 0,
        company_name: "",
        company_id: "",
        device_power: "",
        device_address: "",
      },
    ]);

    onMounted(async () => {
      fetchGenerators();
    });

    const onUpdateLocationClick = () => {
      fetchGenerators(true);
    };

    const fetchGenerators = async (update_location = false) => {
      try {
        const response = await goSolidApi.get(
          "/api/admin/map-data?update_location=" + update_location
        );
        if (response.status === 200 && response.data) {
          locations.value = response.data;
        } else {
          throw new Error("Error fetching generators.");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    return {
      onUpdateLocationClick,
      locations,
      apiKey,
    };
  },
});
</script>

<style scoped></style>
