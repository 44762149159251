<!-- src/components/charts/ProductionProjectionBarChart.vue -->
<template>
  <StackedBarChart
    :chartData="chartData"
    :title="title"
    :subtitle="subtitle"
    :yAxisTitle="yAxisTitle"
    :height="height"
  />
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import StackedBarChart from "@/components/charts/StackedBarChart.vue";
import { PowerProjection } from "@/types";
import { CHART_COLORS } from "@/constants";

export default defineComponent({
  name: "ProductionProjectionBarChart",
  components: {
    StackedBarChart,
  },
  props: {
    solarProjection: {
      type: Object as PropType<PowerProjection>,
      required: true,
    },
    windProjection: {
      type: Object as PropType<PowerProjection>,
      required: true,
    },
    title: {
      type: String,
      default: "Production forecast by source",
    },
    subtitle: {
      type: String,
      default: "2025 production year estimate",
    },
    yAxisTitle: {
      type: String,
      default: "Estimated Production (MWh)",
    },
    height: {
      type: Number,
      default: 190,
    },
  },
  setup(props) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const isProjectionEmpty = (projection: PowerProjection | null): boolean => {
      if (!projection) return true;
      return (
        Object.values(projection).reduce(
          (sum, value) => sum + (value || 0),
          0
        ) === 0
      );
    };

    const getProjectionData = (
      projection: PowerProjection | null
    ): number[] => {
      if (!projection) return new Array(12).fill(0);
      return monthNames.map((_, index) => projection[index + 1] || 0);
    };

    const chartData = computed(() => {
      const datasets = [];

      if (!isProjectionEmpty(props.windProjection)) {
        datasets.push({
          label: "Wind",
          data: getProjectionData(props.windProjection),
          backgroundColor: CHART_COLORS.wind.background,
          borderColor: CHART_COLORS.wind.border,
          borderWidth: 1,
        });
      }

      if (!isProjectionEmpty(props.solarProjection)) {
        datasets.push({
          label: "Solar",
          data: getProjectionData(props.solarProjection),
          backgroundColor: CHART_COLORS.solar.background,
          borderColor: CHART_COLORS.solar.border,
          borderWidth: 1,
        });
      }

      return {
        labels: monthNames,
        datasets,
      };
    });

    return {
      chartData,
    };
  },
});
</script>
