import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-4 pb-1"
}
const _hoisted_2 = {
  key: 1,
  class: "card p-4 mb-3"
}
const _hoisted_3 = { class: "mb-1 mb-sm-2" }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.pickerExpanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.$t("signup.choose_company_country")) + ": " + _toDisplayString(_ctx.$t(_ctx.localAccountData.country)) + " ", 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePicker && _ctx.togglePicker(...args))),
            class: "btn btn-link p-0"
          }, " (" + _toDisplayString(_ctx.$t("common.edit")) + ") ", 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.pickerExpanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("signup.choose_company_country")), 1),
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localAccountData.country) = $event)),
            onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleUpdateCountry && _ctx.handleUpdateCountry(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
              return (_openBlock(), _createElementBlock("option", {
                key: country.value,
                value: country.value
              }, _toDisplayString(country.label), 9, _hoisted_4))
            }), 128))
          ], 544), [
            [_vModelSelect, _ctx.localAccountData.country]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}