<template>
  <div>Authenticating...</div>
</template>

<script lang="ts">
import { onMounted } from "vue";

export default {
  name: "AuthCallbackView",
  setup() {
    onMounted(async () => {
      // Process the callback from Auth0
      console.log("Authenticating...");
    });
    return {};
  },
};
</script>
