<!-- src/components/charts/ProductionSalesStatusBarChart.vue -->
<template>
  <StackedBarChart
    :chartData="chartData"
    :title="title"
    :subtitle="subtitle"
    :yAxisTitle="yAxisTitle"
    :height="height"
  />
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import StackedBarChart from "@/components/charts/StackedBarChart.vue";
import { PowerProjection } from "@/types";
import { CHART_COLORS } from "@/constants";

export default defineComponent({
  name: "ProductionSalesStatusBarChart",
  components: {
    StackedBarChart,
  },
  props: {
    solarProjection: {
      type: Object as PropType<PowerProjection>,
      required: true,
    },
    windProjection: {
      type: Object as PropType<PowerProjection>,
      required: true,
    },
    forwardSaleAmounts: {
      type: Array as PropType<number[]>,
      required: true,
    },
    forwardSoldAmounts: {
      type: Array as PropType<number[]>,
      required: true,
    },
    title: {
      type: String,
      default: "Quarterly Forward Sales 2025",
    },
    subtitle: {
      type: String,
      default: "2025 production year estimate",
    },
    yAxisTitle: {
      type: String,
      default: "Estimated Production (MWh)",
    },
    height: {
      type: Number,
      default: 190,
    },
  },
  setup(props) {
    const quarterNames = ["Q1", "Q2", "Q3", "Q4"];

    const isProjectionEmpty = (projection: PowerProjection | null): boolean => {
      if (!projection) return true;
      return (
        Object.values(projection).reduce(
          (sum, value) => sum + (value || 0),
          0
        ) === 0
      );
    };

    const isDatasetEmpty = (data: number[]): boolean => {
      return data.every((value) => value === 0);
    };

    const chartData = computed(() => {
      const quarterlyProduction = quarterNames.map((_, quarterIndex) => {
        const startMonth = quarterIndex * 3 + 1;
        const endMonth = startMonth + 2;

        return Array.from({ length: 3 }, (_, i) => startMonth + i).reduce(
          (total, month) => {
            const solar = !isProjectionEmpty(props.solarProjection)
              ? props.solarProjection[month] || 0
              : 0;
            const wind = !isProjectionEmpty(props.windProjection)
              ? props.windProjection[month] || 0
              : 0;
            return total + solar + wind;
          },
          0
        );
      });

      const soldData = props.forwardSoldAmounts;
      const forSaleData = quarterlyProduction.map((total, index) => {
        // First, calculate how much of the committed sales exceeds the forSaleAmount
        const forSaleAmount = props.forwardSaleAmounts[index];
        const soldAmount = soldData[index];
        const availableForSale = Math.max(
          0,
          Math.min(forSaleAmount - soldAmount, forSaleAmount)
        );
        return availableForSale;
      });

      const notForSaleData = quarterlyProduction.map((total, index) => {
        const forSaleAmount = props.forwardSaleAmounts[index];
        const soldAmount = soldData[index];

        // If sold amount exceeds forSaleAmount, subtract the overflow from notForSale
        const overflow = Math.max(0, soldAmount - forSaleAmount);
        const baseNotForSale = Math.max(0, total - forSaleAmount);
        return Math.max(0, baseNotForSale - overflow);
      });

      const datasets = [];

      if (!isDatasetEmpty(soldData)) {
        datasets.push({
          label: "Committed",
          data: soldData,
          backgroundColor: CHART_COLORS.sold.background,
          borderColor: CHART_COLORS.sold.border,
          borderWidth: 1,
        });
      }

      if (!isDatasetEmpty(forSaleData)) {
        datasets.push({
          label: "Available for sale",
          data: forSaleData,
          backgroundColor: CHART_COLORS.forSale.background,
          borderColor: CHART_COLORS.forSale.border,
          borderWidth: 1,
        });
      }

      if (!isDatasetEmpty(notForSaleData)) {
        datasets.push({
          label: "Spot sales",
          data: notForSaleData,
          backgroundColor: CHART_COLORS.notForSale.background,
          borderColor: CHART_COLORS.notForSale.border,
          borderWidth: 1,
        });
      }

      return {
        labels: quarterNames,
        datasets,
      };
    });

    return {
      chartData,
    };
  },
});
</script>
