<template>
  <div class="multiple-upload-checklist p-4 card pb-0" v-if="accountData.id">
    <h3 class="mb-3">{{ $t("signup.multiple_upload.title") }}</h3>
    <p class="mb-4 mb-sm-5">
      {{ $t("signup.multiple_upload.description") }}
    </p>

    <div v-for="item in uploadChecklistItems" :key="item.index">
      <UploadChecklistItemComponent
        :uploadTitle="item.title"
        :supportedFormats="item.supportedFormats"
        :uploadUrl="item.uploadUrl"
        :accountId="accountData.id"
        :fileUrl="item.fileUrl"
        :queryParams="item.queryParams"
        :uploadErrorMessage="item.uploadErrorMessage"
        @uploadComplete="handleUploadComplete(item.index, $event)"
        @errorOccured="handleError"
      />

      <BasicInfoCardComponent
        :buttonText="$t('common.read_more_button')"
        :titleText="item.infoTitle"
        class="mb-6"
      >
        <p class="mt-2 mb-2" v-html="item.infoContent"></p>
      </BasicInfoCardComponent>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, computed } from "vue";
import type { Account } from "@/types";
import UploadChecklistItemComponent from "@/components/common/UploadChecklistItemComponent.vue";
import BasicInfoCardComponent from "@/components/common/BasicInfoCardComponent.vue";
import { useI18n } from "vue-i18n";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";

export default defineComponent({
  name: "MultipleUploadChecklistComponent",
  components: {
    UploadChecklistItemComponent,
    BasicInfoCardComponent,
  },
  props: {
    accountData: {
      type: Object as PropType<Account>,
      required: true,
    },
    setLoading: {
      type: Function as PropType<(value: boolean) => void>,
      required: true,
    },
    addAuthorizationAgreement: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["uploadComplete", "errorOccured"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { companyIndex } = useCompanyFinder(props.accountData);
    const selectedCompany = computed(
      () => props.accountData.companies[companyIndex.value]
    );
    const { nullableSelectedGenerator } = useGeneratorFinder(props.accountData);

    const getParams = (fileKey: string) => {
      // Returns a map with fileKey, generatorId (if present), companyId and accountId
      return computed(() => {
        const params = new Map<string, any>([
          ["file_key", fileKey],
          ["company_id", selectedCompany?.value?.id],
          ["account_id", props.accountData.id],
        ]);
        if (nullableSelectedGenerator.value.generator) {
          params.set(
            "generator_id",
            nullableSelectedGenerator.value.generator.id
          );
        }
        return params;
      });
    };

    const findFileUrl = (fileKey: string) => {
      // Returns the fileUrl for the given fileKey
      return computed(() => {
        if (nullableSelectedGenerator.value.generator) {
          if (
            nullableSelectedGenerator.value.generator.files &&
            nullableSelectedGenerator.value.generator.files[fileKey]
          ) {
            return nullableSelectedGenerator.value.generator.files[fileKey];
          }
        }
        return "";
      });
    };

    const newCompanyChecklistItems = [
      {
        index: 0,
        title: t("signup.multiple_upload.lt_inspection_report"),
        supportedFormats: t("common.any_file_format"),
        uploadUrl: "/api/lt/upload-generator-file",
        queryParams: getParams("lt_inspection_report"),
        infoTitle: t("signup.multiple_upload.lt_where_to_find_this_document"),
        infoContent: t(
          "signup.multiple_upload.lt_inspection_report_where_to_find"
        ),
        fileUrl: findFileUrl("lt_inspection_report"),
        uploadErrorMessage: t("common.invalid_file_error"),
      },
      {
        index: 1,
        title: t("signup.multiple_upload.lt_property_limit_scheme"),
        supportedFormats: t("common.any_file_format"),
        uploadUrl: "/api/lt/upload-generator-file",
        queryParams: getParams("lt_property_limit_scheme"),
        infoTitle: t("signup.multiple_upload.lt_where_to_find_this_document"),
        infoContent: t(
          "signup.multiple_upload.lt_property_limit_scheme_where_to_find"
        ),
        fileUrl: findFileUrl("lt_property_limit_scheme"),
        uploadErrorMessage: t("common.invalid_file_error"),
      },
    ];
    const authorizationChecklistItems = [
      {
        index: 2,
        title: t("signup.multiple_upload.lt_power_of_attorney"),
        supportedFormats: t("common.pdf_file_format"),
        uploadUrl: "/api/lt/upload-generator-file",
        queryParams: getParams("lt_power_of_attorney"),
        infoTitle: t("signup.multiple_upload.lt_where_to_find_this_document"),
        infoContent: t(
          "signup.multiple_upload.lt_power_of_attorney_where_to_find"
        ),
        fileUrl: findFileUrl("lt_power_of_attorney"),
        uploadErrorMessage: t(
          "signup.multiple_upload.lt_invalid_power_of_attorney"
        ),
      },
    ];

    const uploadChecklistItems = ref(
      props.addAuthorizationAgreement === true
        ? authorizationChecklistItems
        : newCompanyChecklistItems
    );

    const handleUploadComplete = (_: number, data: any) => {
      emit("uploadComplete", data);
    };

    const handleError = (error: any) => {
      emit("errorOccured", error);
    };

    return {
      uploadChecklistItems,
      handleUploadComplete,
      handleError,
    };
  },
});
</script>

<style scoped lang="scss"></style>
