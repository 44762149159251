<!-- client/src/components/common/ForwardSaleTimeline.vue -->
<template>
  <div class="row row-cols-1 row-cols-lg-4 g-3 text-start">
    <template v-for="quarterIndex in 4" :key="quarterIndex">
      <ForwardSaleScheduleCard
        :sale-year="saleYear"
        :sale-quarter="quarterIndex"
        :sale-amounts="getQuarterAmounts(quarterIndex)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import ForwardSaleScheduleCard from "@/components/common/ForwardSaleScheduleCard.vue";

export default defineComponent({
  name: "ForwardSaleTimeline",
  components: {
    ForwardSaleScheduleCard,
  },
  props: {
    saleYear: {
      type: Number,
      required: true,
    },
    remainingQuarters: {
      type: Number,
      required: true,
      validator: (value: number) => value > 0 && value <= 4,
    },
    saleAmounts: {
      type: Array as PropType<number[]>,
      required: true,
      validator: (value: number[]) => value.length === 4,
    },
    soldAmounts: {
      type: Array as () => number[],
      required: true,
    },
  },
  setup(props) {
    const activeQuarters = computed(() => {
      // Get array of active quarter numbers (e.g., [3, 4] for remaining = 2)
      return Array.from(
        { length: props.remainingQuarters },
        (_, i) => 4 - props.remainingQuarters + i + 1
      );
    });

    const quarterDistribution = computed(() => {
      // Subtract committed amounts from sale amounts
      const sellableAmounts = props.saleAmounts.map((amount, i) =>
        Math.max(0, amount - props.soldAmounts[i])
      );

      // Calculate base amounts per quarter (rounded down)
      const baseAmounts = sellableAmounts.map((amount) =>
        Math.floor(amount / props.remainingQuarters)
      );

      // Calculate remainders
      const remainders = sellableAmounts.map(
        (amount, i) => amount - baseAmounts[i] * props.remainingQuarters
      );

      const distribution = new Map<number, number[]>();

      activeQuarters.value.forEach((quarter, index) => {
        if (index === activeQuarters.value.length - 1) {
          // Last quarter gets the base amounts plus remainders
          distribution.set(
            quarter,
            baseAmounts.map((amount, i) => amount + remainders[i])
          );
        } else {
          // Other quarters get just the base amounts
          distribution.set(quarter, [...baseAmounts]);
        }
      });

      return distribution;
    });

    const getQuarterAmounts = (quarter: number) => {
      // If this quarter is in our active quarters, return its amounts
      // Otherwise return null for empty state
      return quarterDistribution.value.get(quarter) || undefined;
    };

    return {
      getQuarterAmounts,
    };
  },
});
</script>
