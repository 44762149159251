// src/store/authStore.ts
import { ref } from "vue";
import { Auth0VueClient } from "@auth0/auth0-vue";

export const authStore = {
  authClient: ref<Auth0VueClient>(),

  // Method to set the Auth0 client
  setClient(client: Auth0VueClient) {
    this.authClient.value = client;
  },

  async getToken() {
    if (!this.authClient.value) throw new Error("Auth0 client not initialized");
    try {
      return await this.authClient.value.getAccessTokenSilently();
    } catch (error: any) {
      console.error("Error fetching user data:", error);
      throw error; // or handle the error as needed
    }
  },
};
