<template>
  <div class="price-terms">
    <h4 class="fs-7 fw-bold mb-3">{{ $t("certificateHistory.overview") }}</h4>
    <canvas ref="chartRef"></canvas>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import Chart from "chart.js/auto";
import type { Certificate } from "../../types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "CertificateHistoryComponent",
  props: {
    certificates: {
      type: Array as () => Certificate[],
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const chartRef = ref<HTMLCanvasElement | null>(null);
    let chartInstance: Chart | null = null;

    const initializeChart = () => {
      if (chartRef.value) {
        const ctx = chartRef.value.getContext("2d");
        if (ctx) {
          const dataset = preprocessData();

          if (chartInstance) {
            chartInstance.destroy();
          }

          chartInstance = new Chart(chartRef.value as HTMLCanvasElement, {
            type: "bar",
            data: dataset,
            options: {
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (context: any) {
                      let label = context.dataset.label || "";
                      if (label) {
                        label += ": ";
                      }
                      if (context.parsed.y !== null) {
                        label += context.parsed.y;
                      }
                      return label;
                    },
                  },
                },
              },
              scales: {
                x: {
                  stacked: true,
                  title: {
                    display: true,
                    text: t("certificateHistory.productionPeriod"),
                  },
                },
                y: {
                  stacked: true,
                  title: {
                    display: true,
                    text: t("certificateHistory.certificateCount"),
                  },
                },
              },
            },
          });
        }
      }
    };

    const preprocessData = () => {
      const groupedData: {
        [monthYear: string]: {
          olderThan12Months: number;
          withTransactionId: number;
          regular: number;
        };
      } = {};

      props.certificates.forEach((certificate) => {
        const productionDate = new Date(certificate.production_month);
        const monthYear = productionDate.toLocaleString("default", {
          month: "short",
          year: "numeric",
        });

        if (!groupedData[monthYear]) {
          groupedData[monthYear] = {
            olderThan12Months: 0,
            withTransactionId: 0,
            regular: 0,
          };
        }

        if (certificate.transfer_deadline_passed) {
          groupedData[monthYear].olderThan12Months += certificate.count;
        } else if (certificate.sales_transaction_item_id) {
          groupedData[monthYear].withTransactionId += certificate.count;
        } else {
          groupedData[monthYear].regular += certificate.count;
        }
      });

      const sortedLabels = Object.keys(groupedData).sort((a, b) => {
        const dateA = new Date(a + " 1").getTime();
        const dateB = new Date(b + " 1").getTime();
        return dateA - dateB;
      });

      const dataOlderThan12Months = sortedLabels.map(
        (monthYear) => groupedData[monthYear].olderThan12Months
      );
      const dataWithTransactionId = sortedLabels.map(
        (monthYear) => groupedData[monthYear].withTransactionId
      );
      const dataRegular = sortedLabels.map(
        (monthYear) => groupedData[monthYear].regular
      );

      return {
        labels: sortedLabels,
        datasets: [
          {
            label: t("certificateHistory.expired"),
            data: dataOlderThan12Months,
            backgroundColor: "rgba(255, 224, 219, 0.8)",
            borderColor: "rgba(255, 224, 219, 1)",
            borderWidth: 2,
          },
          {
            label: t("certificateHistory.sold"),
            data: dataWithTransactionId,
            backgroundColor: "rgba(217, 251, 208, 0.8)",
            borderColor: "rgba(217, 251, 208, 1)",
            borderWidth: 2,
          },
          {
            label: t("certificateHistory.awaitingSale"),
            data: dataRegular,
            backgroundColor: "rgba(199, 235, 255, 0.8)",
            borderColor: "rgba(199, 235, 255, 1)",
            borderWidth: 2,
          },
        ],
      };
    };

    onMounted(() => {
      initializeChart();
    });

    return {
      chartRef,
    };
  },
});
</script>

<style scoped>
form {
  .form-label {
    text-transform: none;
  }
}
</style>
