import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body p-2 px-3 p-sm-2 px-sm-3 bg-body-highlight" }
const _hoisted_2 = { class: "text-body-secondary mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.containerClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "d-flex align-items-center",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickArea === 'title' && _ctx.toggleCollapse())),
        role: "button"
      }, [
        _renderSlot(_ctx.$slots, "title"),
        _createElementVNode("div", {
          class: "btn btn-text p-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickArea === 'button' && _ctx.toggleCollapse()))
        }, _toDisplayString(_ctx.buttonText), 1)
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "content")
      ], 512), [
        [_vShow, _ctx.showCollapse]
      ])
    ])
  ], 2))
}