import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "invoice-display-component d-flex align-items-center justify-content-between py-2 border-bottom" }
const _hoisted_2 = {
  key: 0,
  class: "invoice-number alert alert-subtle-warning text-center admin-extra-info px-2 py-1"
}
const _hoisted_3 = { class: "invoice-number" }
const _hoisted_4 = { class: "invoice-status" }
const _hoisted_5 = { class: "invoice-payout" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.is_admin)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.invoice.id), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "extra-columns"),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.invoice.invoice_number), 1),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.invoiceStatus), 1),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.totalPayout) + " €", 1),
    (_ctx.is_admin && _ctx.invoice.invoice_type === 'fee')
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "btn btn-outline-primary btn-sm alert alert-subtle-warning text-center admin-extra-info px-2 py-1",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.regenerateInvoice && _ctx.regenerateInvoice(...args))),
          disabled: _ctx.isLoading
        }, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                class: "px-1",
                icon: "spinner",
                spin: ""
              }))
            : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                class: "px-1",
                icon: "recycle"
              }))
        ], 8, _hoisted_6))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      class: "btn btn-outline-primary btn-sm",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.startDownload && _ctx.startDownload(...args))),
      disabled: _ctx.isLoading
    }, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            class: "px-1",
            icon: "spinner",
            spin: ""
          }))
        : (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 1,
            class: "px-1",
            icon: "download"
          }))
    ], 8, _hoisted_7)
  ]))
}