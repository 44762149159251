<!-- client/src/views/admin/AccountsView.vue -->

<template>
  <div id="AdminAccounts" class="container">
    <div class="row justify-content-between align-items-center mb-3 mt-4">
      <div class="col-auto">
        <h1>Accounts</h1>
      </div>
      <div class="col-auto">
        <button class="btn btn-secondary" @click="stopAccountManaging()">
          Reset Managing
        </button>
      </div>
    </div>
    <!-- It's currently broken and constantly crashes the servers. Too inefficient query -->
    <!-- <div class="col-auto mb-3">
      <input
        type="text"
        class="form-control"
        placeholder="Search..."
        v-model="searchQuery"
      />
    </div> -->
    <div class="table-container table-responsive scrollbar">
      <table id="account-table" class="table table-hover align-middle p-2">
        <thead>
          <tr>
            <th>ID</th>
            <th>Actions</th>
            <th>Account</th>
            <th>
              Sig/Reg Devices <br />
              Sig/Reg kW
            </th>
            <th>Certificates</th>
            <th>Company names</th>
            <th>Meta</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="account in orderedAccounts(accounts)"
            :key="account.id"
            :class="{
              'table-active': account.id === Number(managingAccount.id || null),
            }"
          >
            <td>{{ account.id }}</td>
            <td>
              <button
                class="btn btn-primary btn-sm"
                v-if="account.id"
                @click="onclick(account.id)"
              >
                Manage
              </button>
            </td>
            <td class="col-2">
              {{ account.email }}
              <span
                v-if="account.internal_role === 'super_admin'"
                class="badge rounded-pill bg-primary text-white"
                >Admin
              </span>
              <br />
              {{ account.name }} <br />
              {{ account.phone_number }}
            </td>
            <td :class="devicesBackgroundColor(account)">
              {{ calculateSignedDevices(account) }} /
              {{ calculateRegistryDevices(account) }} <br />
              {{ calculateKW(account) }}
            </td>
            <td>{{ calculateCertificates(account) }}</td>
            <td class="table-company-list">
              <div
                v-for="name in getCompanyNames(account)"
                :key="`${account.id}-${name.concat()}`"
              >
                {{ name }}
              </div>
            </td>
            <td>
              <div v-if="fetch_tracking(account)">
                <span class="badge badge-phoenix badge-phoenix-secondary"
                  >Source
                </span>
                <br />
                {{ fetch_tracking(account) }} <br />
              </div>
              <div v-if="account.comment">
                <span class="badge badge-phoenix badge-phoenix-info"
                  >Comment
                </span>
                <br />
                {{ account.comment }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import type { Account, Company, Generator } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { fetchAccount } from "@/composables/account/fetchAccount";
import { useManageAccount } from "@/composables/admin/useManageAccount";
import { throttle } from "lodash";

export default defineComponent({
  methods: {
    fetch_tracking(account: Account): string {
      if (account.conversion_tracking?.referrer_id) {
        return String(account.conversion_tracking?.referrer_id);
      } else if (account.conversion_tracking?.utm_source) {
        return (
          account.conversion_tracking?.utm_source +
          " " +
          account.conversion_tracking?.utm_campaign
        );
      } else {
        return "";
      }
    },
    calculateSignedDevices(account: Account): number {
      let signedDevices = 0;

      account.companies?.forEach((company: Company) => {
        company.generators?.forEach((generator: Generator) => {
          if (generator.has_registered) {
            signedDevices += 1;
          }
        });
      });

      return signedDevices;
    },
    calculateRegistryDevices(account: Account): number {
      let registryDevices = 0;

      account.companies?.forEach((company: Company) => {
        company.registry_devices?.forEach(() => {
          registryDevices += 1;
        });
      });

      return registryDevices;
    },
    calculateCertificates(account: Account): number {
      let certificates = 0;

      account.companies?.forEach((company: Company) => {
        company.registry_devices?.forEach((registryDevice) => {
          registryDevice.certificates?.forEach((certificate) => {
            certificates += certificate.count;
          });
        });
      });

      return certificates;
    },
    isNotTestAccount(account: Account): boolean {
      return account.internal_role !== "super_admin";
    },
    devicesBackgroundColor(account: Account): string {
      if (
        (this.calculateSignedDevices(account) > 0 ||
          this.calculateRegistryDevices(account) > 0) &&
        this.isNotTestAccount(account)
      ) {
        if (
          this.calculateRegistryDevices(account) <
          this.calculateSignedDevices(account)
        ) {
          return "bg-warning";
        } else {
          return "bg-success";
        }
      } else {
        return "";
      }
    },
    calculateKW(account: Account): string {
      let signedDevicesKw = 0;
      let registryDevicesKw = 0;
      account.companies?.forEach((company: Company) => {
        company.generators?.forEach((generator: Generator) => {
          if (!generator.device_power) return;
          if (generator.has_registered) {
            signedDevicesKw += generator.device_power;
          }
        });
        company.registry_devices?.forEach((registryDevice) => {
          registryDevicesKw += registryDevice.device_power;
        });
      });
      return `${signedDevicesKw.toFixed(0)} / ${registryDevicesKw.toFixed(0)}`;
    },
    orderedAccounts(accounts: Account[]): Account[] {
      if (!accounts) {
        return [];
      }
      return accounts?.sort((a: Account, b: Account) => {
        if (a.id && b.id) {
          return a.id - b.id;
        }
        return 0;
      });
    },
    getCompanyNames(account: Account): string[] {
      return account.companies.map(
        (company) =>
          company.name + " - " + company.id + " - " + company.registry_code
      );
    },
  },

  setup(_, { emit }) {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const accounts = ref<Account[]>([]);
    const router = useRouter();
    const { accountData } = fetchAccount();
    const { manageAccount, managingAccount, resetManagingAccount } =
      useManageAccount();
    const searchQuery = ref("");

    const fetchAccounts = async () => {
      try {
        const response = await goSolidApi.get("/api/admin/accounts", {
          params: { search: searchQuery.value },
        });
        if (response.status === 200 && response.data) {
          accounts.value = response.data;
        } else {
          throw new Error("Error fetching accounts");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    onMounted(fetchAccounts);

    const throttledFetchAccounts = throttle(fetchAccounts, 500);
    watch(searchQuery, throttledFetchAccounts);

    const onclick = (id: number) => {
      manageAccount(id, onClickSuccess);
    };

    const onClickSuccess = () => {
      emit("managing-account-updated");
      router.push({ name: "Root" });
    };

    const stopAccountManaging = () => {
      resetManagingAccount(onResetSuccess);
      router.push({ name: "Root" });
    };

    const onResetSuccess = () => {
      emit("managing-account-updated");
    };

    return {
      accounts,
      onclick,
      manageAccount,
      stopAccountManaging,
      managingAccount,
      searchQuery,
    };
  },
});
</script>

<style scoped>
#AdminAccounts {
  flex-grow: 1;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
}

.table-container {
  border-radius: 1rem;
  border: 1px solid var(--phoenix-gray-200);
  .table > tbody > tr > td:first-child {
    padding-left: 1rem;
  }
}

#account-table {
  margin-bottom: 0;
  .table-active {
    background-color: var(--phoenix-warning-bg-subtle);
    td {
      background-color: #fff3cd;
      box-shadow: none;
    }
  }
  th,
  td {
    white-space: nowrap; /* Prevent wrapping for all cells */
  }
  th:last-child,
  td:last-child {
    width: 100%; /* Allow last column to take the remaining space */
    white-space: normal; /* Allow wrapping for the last column */
  }

  .table-company-list {
    word-wrap: break-word;
    white-space: normal;
    min-width: 350px;
  }
}
</style>
