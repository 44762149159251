<!-- client/src/components/common/SlottedFileCardComponent.vue -->

<template>
  <div
    class="border p-3 mb-3 rounded shadow file-card"
    @click="handleClick"
    :class="{ 'cursor-pointer': url }"
  >
    <h5 class="fs-7">
      <font-awesome-icon
        :icon="cardType === 'link' ? 'link' : 'file-arrow-down'"
        class="me-1 text-primary-emphasis"
      />
      <slot name="title"></slot>
    </h5>
    <div>
      <slot name="info"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SlottedFileCardComponent",
  props: {
    cardType: {
      type: String,
      required: true,
      validator: (value: string) => ["link", "file"].includes(value),
    },
    url: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const handleClick = () => {
      if (props.url) {
        if (props.cardType === "link") {
          window.open(props.url, "_blank");
        } else {
          // For file type, trigger a download
          const link = document.createElement("a");
          link.href = props.url;
          link.download = "";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    };

    return {
      handleClick,
    };
  },
});
</script>

<style scoped>
.file-card {
  background-color: rgb(242, 245, 250);
}
.file-card:hover {
  cursor: pointer;
  background-color: var(--phoenix-primary-bg-subtle);
}
.cursor-pointer {
  cursor: pointer;
}
</style>
