<!-- client/src/components/signup/ee/ContractReviewConfigurationComponent.vue -->

<template>
  <ContractReviewDisplayComponent
    :accountData="accountData"
    :contractsToShow="contracts"
    :setLoading="setLoading"
    :signerFields="signerFields"
    :companyFields="companyFields"
    :generatorFields="generatorFields"
    :contractState="contractState"
    :hasGenerator="hasGenerator"
    @createContainerForStartSigningReady="
      $emit('createContainerForStartSigningReady', $event)
    "
  />
</template>

<script lang="ts">
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { Account, Subsidy, Document, Ppa, Battery } from "@/types";
import { useRoute } from "vue-router";
import { defineComponent, reactive, computed, PropType } from "vue";
import { formatDate } from "@/composables/formatUtils";
import ContractReviewDisplayComponent from "@/components/signup/ContractReviewDisplayComponent.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ContractReviewConfigurationComponent",
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    setLoading: {
      type: Function as PropType<(value: boolean) => void>,
      required: true,
    },
  },
  components: {
    ContractReviewDisplayComponent,
  },
  emits: ["createContainerForStartSigningReady"],
  setup(props) {
    const { t } = useI18n();
    const isAdmin = !!props.accountData.admin_account_id;
    const signerFields = reactive([
      { label: t("account.name"), value: props.accountData.name },
      {
        label: t("account.registry_code"),
        value: props.accountData.registry_code,
      },
      { label: t("account.email"), value: props.accountData.email },
      {
        label: t("account.phone_number"),
        value: props.accountData.phone_number,
      },
    ]);
    const { companyIndex } = useCompanyFinder(props.accountData);
    const selectedCompany = computed(
      () => props.accountData.companies[companyIndex.value]
    );
    const companyFields = reactive([
      { label: t("company.name"), value: selectedCompany.value.name },
      {
        label: t("company.registry_code"),
        value: selectedCompany.value.registry_code,
      },
      { label: t("company.address"), value: selectedCompany.value.address },
    ]);
    const { nullableSelectedGenerator } = useGeneratorFinder(props.accountData);
    const hasGenerator = computed(() => {
      return nullableSelectedGenerator.value.generator !== null;
    });
    const generatorFields = reactive([
      {
        label: t("generator.device_name"),
        value: nullableSelectedGenerator.value.generator?.device_name,
      },
      {
        label: t("generator.device_power"),
        value: nullableSelectedGenerator.value.generator?.device_power,
      },
      {
        label: t("generator.device_eic"),
        value: nullableSelectedGenerator.value.generator?.device_eic,
      },
      {
        label: t("generator.device_address"),
        value: nullableSelectedGenerator.value.generator?.device_address,
      },
      {
        label: t("generator.grid_contract_start_date"),
        value: formatDate(
          nullableSelectedGenerator.value.generator?.grid_contract_start_date
        ),
      },
      {
        label: t("generator.subsidies"),
        value: displaySubsidies(
          nullableSelectedGenerator.value.generator?.subsidies
        ),
      },
      {
        label: t("generator.battery"),
        value: displayBattery(
          nullableSelectedGenerator.value.generator?.battery
        ),
      },
      {
        label: t("generator.ppa"),
        value: displayPpa(nullableSelectedGenerator.value.generator?.ppa),
      },
    ]);

    // https://www.figma.com/file/QUmaTCWsTM7DKDoLVUfhsM/Elering-avalduse-flow?type=whiteboard&node-id=14-333&t=KGU6x2B0PzougOBg-0
    // States:
    // 1. Volitus
    // 2. Tootmistoetusega seade
    // 3. Väike tootmistoetuseta seade
    // 4. Suur tootmistoetuseta seade
    const route = useRoute();
    const addAuthorizationAgreement = computed(() => {
      return route.query.addAuthorizationAgreement === "true";
    });
    const hasEleringSubsidy = computed(() => {
      if (nullableSelectedGenerator.value.generator?.subsidies) {
        return nullableSelectedGenerator.value.generator?.subsidies.some(
          (subsidy: Subsidy) => subsidy.provider === "Elering AS"
        );
      } else {
        return false;
      }
    });
    const contractState = computed(() => {
      if (addAuthorizationAgreement.value) {
        return 1;
      }
      if (nullableSelectedGenerator.value.generator) {
        if (hasEleringSubsidy.value) {
          return 2;
        }
        if (
          nullableSelectedGenerator.value.generator?.device_power &&
          nullableSelectedGenerator.value.generator?.device_power < 50
        ) {
          return 3;
        }
        if (
          nullableSelectedGenerator.value.generator?.device_power &&
          nullableSelectedGenerator.value.generator?.device_power >= 50
        ) {
          return 4;
        }
      }
      return 0;
    });

    function displaySubsidies(subsidies: Subsidy[] | undefined | null) {
      let text = t("generator.no_subsidies"); // Assuming a key for "No subsidies"

      if (subsidies && subsidies.length > 0) {
        text = subsidies
          .map((subsidy) => {
            return [
              t("generator.subsidy_provider", { provider: subsidy.provider }),
              subsidy.plan
                ? t("generator.subsidy_plan", { plan: subsidy.plan })
                : "",
              subsidy.decision_date ? formatDate(subsidy.decision_date) : "",
              subsidy.amount ? `${subsidy.amount}€` : "",
            ]
              .filter(Boolean)
              .join("\n");
          })
          .join("\n\n");
      }
      return text;
    }

    function displayPpa(ppa: Ppa | undefined | null) {
      if (!ppa) {
        return t("generator.no_ppa"); // Assuming a key for "No PPA"
      }
      return [
        ppa.start_date
          ? t("generator.ppa_start_date", { date: formatDate(ppa.start_date) })
          : "",
        ppa.mwh ? t("generator.ppa_mwh", { mwh: ppa.mwh }) : "",
        ppa.client_company_name
          ? t("generator.ppa_client_name", { name: ppa.client_company_name })
          : "",
        ppa.country ? t("generator.ppa_country", { country: ppa.country }) : "",
      ]
        .filter(Boolean)
        .join("\n");
    }

    function displayBattery(battery: Battery | undefined | null) {
      if (!battery) {
        return t("generator.no_storage_device"); // Assuming a key for "No storage device"
      }
      return [
        battery.kw
          ? t("generator.unit_power", { power: battery.kw }) + " kW"
          : "",
        battery.kwh
          ? t("generator.capacity", { capacity: battery.kwh }) + " kWh"
          : "",
      ]
        .filter(Boolean)
        .join("\n");
    }

    const hasSignedOptionalContract = (selectedContract: string) => {
      return selectedCompany.value?.documents.some(
        (document: Document) =>
          document.status === "completed" && // The contract needs to be signed
          document.contract_keys.includes(selectedContract) // and if it has our contract key, we want to return true
      );
    };

    const shouldSignOptionalContractIfOptional = (
      optionalContractKey: string | null
    ): boolean => {
      return (
        !optionalContractKey || !hasSignedOptionalContract(optionalContractKey)
      );
    };

    const includeDocument = (
      states: number[],
      optionalContractKey: string | null = null
    ) =>
      computed(() => {
        return (
          states.includes(contractState.value) &&
          shouldSignOptionalContractIfOptional(optionalContractKey)
        );
      });

    // https://www.figma.com/file/QUmaTCWsTM7DKDoLVUfhsM/Untitled?type=whiteboard&node-id=0-1&t=Z6ns9YzSm8ylm6X3-0
    const contracts = reactive([
      [
        {
          id: 1,
          key: "ee_avaldus_partiolu_tunnistuste_konto_avamiseks",
          contract_title: t(
            "signup.contract_review_component.ee.origin_certificate_account_application_title"
          ),
          contract_description: t(
            "signup.contract_review_component.ee.origin_certificate_account_application_description"
          ),
          include: includeDocument(
            [2, 3, 4],
            "ee_avaldus_partiolu_tunnistuste_konto_avamiseks"
          ).value,
          can_include: true,
        },
        {
          id: 2,
          key: "ee_paritolutunnistuste_kasutamise_tyuptingimused",
          contract_title: t(
            "signup.contract_review_component.ee.type_conditions_title"
          ),
          contract_description: t(
            "signup.contract_review_component.ee.type_conditions_description"
          ),
          include: includeDocument(
            [2, 3, 4],
            "ee_paritolutunnistuste_kasutamise_tyuptingimused"
          ).value,
          can_include: true,
        },
        {
          id: 3,
          key: "ee_avaldus_partiolu_tunnistuste_konto_volituse_andmiseks",
          contract_title: t(
            "signup.contract_review_component.ee.authorization_application_title"
          ),
          contract_description: t(
            "signup.contract_review_component.ee.authorization_application_description"
          ),
          include: includeDocument([1]).value,
          can_include: true,
        },
        {
          id: 4,
          key: "ee_kinnituskiri_investeeringutoetuse_saamise_mittesaamise_kohta",
          contract_title: t(
            "signup.contract_review_component.ee.investment_support_confirmation_title"
          ),
          contract_description: t(
            "signup.contract_review_component.ee.investment_support_confirmation_description"
          ),
          include: includeDocument([2, 3, 4]).value,
          can_include: hasGenerator.value,
        },
        {
          id: 5,
          key: "ee_vorguleping",
          contract_title: t(
            "signup.contract_review_component.ee.network_contract_title"
          ),
          contract_description: t(
            "signup.contract_review_component.ee.network_contract_description"
          ),
          include: includeDocument([3, 4]).value,
          can_include: hasGenerator.value,
        },
        {
          id: 6,
          key: "ee_seadme_registreerimise_vorm",
          contract_title: t(
            "signup.contract_review_component.ee.device_registration_form_title"
          ),
          contract_description: t(
            "signup.contract_review_component.ee.device_registration_form_description"
          ),
          include: includeDocument([4]).value,
          can_include: hasGenerator.value,
        },
        {
          id: 7,
          key: "ee_soldera_liikmeleping",
          contract_title: t(
            "signup.contract_review_component.ee.soldera_membership_agreement_title"
          ),
          contract_description: t(
            "signup.contract_review_component.ee.soldera_membership_agreement_description"
          ),
          include: includeDocument([1, 2, 3, 4], "ee_soldera_liikmeleping")
            .value,
          can_include: true,
        },
        {
          id: 8,
          key: "ee_eleringi_andmed",
          contract_title: t(
            "signup.contract_review_component.ee.elering_data_title"
          ),
          contract_description: t(
            "signup.contract_review_component.ee.elering_data_description"
          ),
          include: includeDocument([2, 3, 4]).value,
          can_include: hasGenerator.value,
        },
      ],
    ]);

    return {
      contracts,
      signerFields,
      companyFields,
      generatorFields,
      contractState,
      hasGenerator,
      isAdmin,
    };
  },
});
</script>

<style scoped lang="scss"></style>
