import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contact-review card p-4 mb-3" }
const _hoisted_2 = { class: "mb-1 mb-sm-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanySelectDropdown = _resolveComponent("CompanySelectDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("chooseCompany.select_company")), 1),
    _createVNode(_component_CompanySelectDropdown, {
      existingCompanies: _ctx.accountData.companies,
      selectedCompany: _ctx.company,
      onSelectExistingCompany: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selectExistingCompany', $event))),
      onResetCompanySelection: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('resetCompanySelection', $event)))
    }, null, 8, ["existingCompanies", "selectedCompany"])
  ]))
}